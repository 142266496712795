import { Draggable } from "@hello-pangea/dnd";
import IconOLD from "../../../components/UI/IconOLD";
import useUserFeatures from "../../../hooks/functionality/useUserFeatures";
import { OrderDraft } from "../../../shared/types/api";
import "./style.scss";
import Badge from "../../../components/UI/Badge";
import { faTag, faTags } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

type Props = {
    orderDraft: OrderDraft;
    index: number;
    onClick?: () => void;
};

function OrderDraftCard(props: Props) {
    const features = useUserFeatures();
    const { t } = useTranslation();

    return (
        <Draggable
            key={props.orderDraft.id}
            draggableId={props.orderDraft.id.toString()}
            index={props.index}
            isDragDisabled={!features?.manage_tours_fleet_planner}
        >
            {(provided) => (
                <div
                    className="order-draft-card"
                    ref={provided.innerRef}
                    onClick={props.onClick}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <section className="text-sm">
                        {props.orderDraft.to_location}
                        {props.orderDraft.file_name && <IconOLD type="image" />}
                    </section>
                    {props.orderDraft.tags ? (
                        props.orderDraft.tags.length > 1 ? (
                            <Badge
                                title={`+${props.orderDraft.tags.length} ${t(
                                    "bigVolume.cardTags"
                                )}`}
                                variant="neutral"
                                icon={faTags}
                                htmlTitle={props.orderDraft.tags.join(", ")}
                            />
                        ) : props.orderDraft.tags.length === 1 ? (
                            <Badge
                                title={props.orderDraft.tags[0]}
                                variant="neutral"
                                icon={faTag}
                            />
                        ) : null
                    ) : null}
                    <section
                        style={{
                            color: "var(--text-color-light)",
                        }}
                    >
                        <span
                            className="order-number"
                            title={props.orderDraft.origin_order_number || ""}
                        >
                            {props.orderDraft.origin_order_number}
                        </span>
                        <span>
                            {props.orderDraft.source === "ai"
                                ? props.orderDraft.source.toUpperCase()
                                : props.orderDraft.source}{" "}
                            <IconOLD type="arrow-bracket" />
                        </span>
                    </section>
                </div>
            )}
        </Draggable>
    );
}

export default OrderDraftCard;
