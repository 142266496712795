import {
    faClock,
    faListCheck,
    faSpinnerThird,
    faWeightHanging,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Droppable } from "@hello-pangea/dnd";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, isAxiosError } from "axios";
import { addMinutes, isAfter } from "date-fns";
import { motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { ZodError } from "zod";
import {
    consolidateTour,
    createTour,
    dispatchTour,
    updateTour,
} from "../../../api/tours";
import Spinner from "../../../components/UI/Spinner";
import Button from "../../../components/buttons/Button";
import Dropdown from "../../../components/inputs/Dropdown";
import Input from "../../../components/inputs/Input";
import useActiveTours from "../../../hooks/data/useActiveTours";
import useStopDrafts from "../../../hooks/data/useStopDrafts";
import useUserFeatures from "../../../hooks/functionality/useUserFeatures";
import {
    createTourForm,
    CreateTourForm,
    UpdateTourForm,
    updateTourForm,
} from "../../../schemas/form";
import analytics from "../../../shared/services/ga4";
import { Driver, StopDraft, StopDraftsTour } from "../../../shared/types/api";
import { KeyString } from "../../../shared/types/internal";
import { dateToString, stringToDate } from "../../../shared/utility/date";
import {
    convertToNumberWithSpaces,
    getDriverDisplayName,
} from "../../../shared/utility/misc";
import {
    cardHoverHandler,
    checkForGroupsAround,
    getRunningWeight,
    getStopListRealtiveEtaPerIdInMinutes,
    getStopOrderMap,
} from "../../../shared/utility/stop-draft";
import { API_ERROR, STOP_DRAFT_TYPE } from "../../../shared/values/enums";
import StopCardDraggable from "../../cards/StopCardDraggable";
import "./style.scss";

type Props = {
    id: string;
    tour?: StopDraftsTour;
    stops: StopDraft[];
    drivers: Driver[];
    filterDate: Date | null;
    isNewTourColumn?: boolean;
    isLoading?: boolean;
    stopIdsLoading: number[];
    tourColor?: string;
    legsDurationInMinutes?: number[];

    onClearStops?: () => void;
    onStopCardClick?: ({
        pickup,
        dropoff,
        groupedStops,
    }: {
        pickup: StopDraft;
        dropoff: StopDraft;
        groupedStops?: {
            pickup: StopDraft;
            dropoff: StopDraft;
        }[];
    }) => void;
    onSelectTourId?: (tourId: number) => void;
};

function TourColumn(props: Props) {
    const { t } = useTranslation();
    const { onStopCardClick } = props;

    const features = useUserFeatures();

    const { refetch: refetchTours } = useActiveTours(
        dateToString(props.filterDate) || undefined
    );

    const { refetch: refetchStops } = useStopDrafts();

    const tourColumnClasses = ["tour-column"];
    if (props.isLoading) {
        tourColumnClasses.push("loading");
    }

    const [selectedDriverId, setSelectedDriverId] = useState("");
    const [startTime, setStartTime] = useState("06:00");

    const iconStyle = {
        "--fa-animation-duration": "0.5s",
    };

    useEffect(() => {
        if (!props.tour) return;

        setSelectedDriverId(props.tour.preferred_driver_id || "");
        setStartTime(props.tour.time || "");
    }, [props.tour]);

    const { runningWeights, peakWeight } = useMemo(() => {
        return getRunningWeight(props.stops);
    }, [props.stops]);

    const stopsDurationsMap = useMemo(() => {
        return getStopListRealtiveEtaPerIdInMinutes(
            props.stops,
            props.legsDurationInMinutes
        );
    }, [props.legsDurationInMinutes, props.stops]);

    const { mutate: createTourHandler, isPending: isCreatingTour } =
        useMutation({
            mutationFn: async (data: CreateTourForm) => {
                createTourForm.parse(data);

                const res = await createTour({
                    date: data.date,
                    time: data.time,
                    preferred_driver_id: data.preferredDriverId,
                    stop_draft_ids: data.stopDraftIds,
                });

                if (!!res.data.excluded_stop_draft_ids.length) {
                    toast.error(
                        t("errorMessage.tourCreationSuccessExcludedStops")
                    );
                }

                if (data.preferredDriverId) {
                    await dispatchTour(res.data.tour_id);
                }

                return res.data.tour_id;
            },
            onSuccess: async (tourId: number) => {
                await Promise.all([refetchTours(), refetchStops()]);
                analytics.tourCreated("big-volume");
                toast.success(t("successMessage.tourCreated"));
                setSelectedDriverId("");
                setStartTime("06:00");
                props.onClearStops?.();
                props.onSelectTourId?.(tourId);
            },
            onError: (error) => {
                if (error instanceof ZodError) {
                    toast.error(t(error.issues[0].message));
                    return;
                }

                if (
                    isAxiosError<{ detail: string; error_token: string }>(error)
                ) {
                    if (
                        error.response?.data.detail === API_ERROR.InvalidStops
                    ) {
                        toast.error(t("errorMessage.tourCreationInvalidStop"));
                        return;
                    }

                    if (error.response?.data.error_token) {
                        toast.error(t(error.response?.data.error_token));
                        return;
                    }
                }

                toast.error(t("errorMessage.tourCreationFailed"));
            },
        });

    const {
        mutate: onConsolidateAndCreateTour,
        isPending: isConsolidatingAndCreatingTour,
    } = useMutation({
        mutationFn: async (data: CreateTourForm) => {
            createTourForm.parse(data);

            const res = await createTour({
                date: data.date,
                time: data.time,
                preferred_driver_id: data.preferredDriverId || undefined,
                stop_draft_ids: data.stopDraftIds,
            });

            const { data: tours } = await refetchTours();

            if (data.preferredDriverId) {
                await dispatchTour(res.data.tour_id);
            }

            const createdTour = tours?.find(
                (tour) => tour.tour_id === res.data.tour_id
            );

            if (!createdTour) {
                throw new Error("Tour not found for consolidation");
            }

            await consolidateTour(createdTour);

            return createdTour.tour_id;
        },
        onSuccess: async (tourId: number) => {
            await Promise.all([refetchTours(), refetchStops()]);
            analytics.tourCreated("big-volume");
            analytics.tourConsolidated("big-volume");
            toast.success(t("successMessage.tourConsolidated"));
            setSelectedDriverId("");
            setStartTime("06:00");
            props.onClearStops?.();
            props.onSelectTourId?.(tourId);
        },
        onError: (error) => {
            if (error instanceof ZodError) {
                toast.error(t(error.issues[0].message));
                return;
            }

            if (isAxiosError<{ detail: string; error_token: string }>(error)) {
                if (error.response?.data.detail === API_ERROR.InvalidStops) {
                    toast.error(t("errorMessage.tourCreationInvalidStop"));
                    return;
                }

                if (error.response?.data.error_token) {
                    toast.error(t(error.response?.data.error_token));
                    return;
                }
            }

            toast.error(t("errorMessage.tourConsolidateError"));
        },
    });

    const { mutate: updateTourHandler, isPending: isUpdatingTour } =
        useMutation({
            mutationFn: async (data: UpdateTourForm) => {
                updateTourForm.parse(data);

                let hasDispatchedTour = false;
                const hasChangedDriver =
                    !!data.preferredDriverId &&
                    data.preferredDriverId !== props.tour?.preferred_driver_id;

                await updateTour({
                    tour_id: data.tourId,
                    date: data.date,
                    time: data.time,
                    preferred_driver_id: data.preferredDriverId || null,
                });

                if (hasChangedDriver) {
                    await dispatchTour(data.tourId);
                    hasDispatchedTour = true;
                }

                return hasDispatchedTour;
            },
            onSuccess: async (hasDispatchedTour) => {
                await Promise.all([refetchTours(), refetchStops()]);
                toast.success(t("successMessage.tourUpdated"));
                if (hasDispatchedTour) {
                    toast.success(t("successMessage.tourUpdateDispatched"));
                }
            },
            onError: (error) => {
                if (error instanceof ZodError) {
                    toast.error(t(error.issues[0].message));
                    return;
                }
                toast.error(t("errorMessage.tourUpdatedError"));
            },
        });

    const { mutate: dispatchTourHandler, isPending: isDispatchingTour } =
        useMutation({
            mutationFn: async (tourId: number) => {
                await dispatchTour(tourId);
            },
            onSuccess: async () => {
                await Promise.all([refetchTours(), refetchStops()]);
                toast.success(t("successMessage.tourUpdateDispatched"));
            },
            onError: (error: AxiosError<any>) => {
                if (error.response?.data.error_token) {
                    toast.error(t(error.response?.data.error_token));
                } else if (error.response?.data.new_order) {
                    toast.error(t("errorMessage.tourDispatchErrorNewOrder"));
                } else if (error.response?.data.new_tour_id) {
                    toast.error(t("errorMessage.tourDispatchErrorNewTour"));
                } else {
                    toast.error(t("errorMessage.tourUpdateDispatchError"));
                }
            },
        });

    const {
        mutate: updateAndDispatchTourHandler,
        isPending: isUpdatingAndDispatchingTour,
    } = useMutation({
        mutationFn: async (data: UpdateTourForm) => {
            updateTourForm.parse(data);

            await updateTour({
                tour_id: data.tourId,
                date: data.date,
                time: data.time,
                preferred_driver_id: data.preferredDriverId || null,
            });
            if (data.preferredDriverId) {
                await dispatchTour(data.tourId);
            }
        },
        onSuccess: async () => {
            await Promise.all([refetchTours(), refetchStops()]);
            toast.success(t("successMessage.tourUpdated"));
            toast.success(t("successMessage.tourUpdateDispatched"));
        },
        onError: (error: AxiosError<any> | ZodError) => {
            if (error instanceof ZodError) {
                toast.error(t(error.issues[0].message));
                return;
            }
            if (error.response?.data.error_token) {
                toast.error(t(error.response?.data.error_token));
            } else if (error.response?.data.new_order) {
                toast.error(t("errorMessage.tourDispatchErrorNewOrder"));
            } else if (error.response?.data.new_tour_id) {
                toast.error(t("errorMessage.tourDispatchErrorNewTour"));
            } else {
                toast.error(t("errorMessage.tourUpdateDispatchError"));
            }
        },
    });

    const hasUndispatchedChanges = useMemo(() => {
        if (!props.tour) return false;
        if (!props.tour.dispatched_at) return true;

        return props.tour.updated_at
            ? isAfter(
                  stringToDate(props.tour.updated_at)!,
                  stringToDate(props.tour.dispatched_at)!
              )
            : false;
    }, [props.tour]);

    const hasUnsavedUpdates = useMemo(() => {
        if (!props.tour) return false;

        return (
            props.tour.time !== startTime ||
            (props.tour.preferred_driver_id
                ? props.tour.preferred_driver_id !== selectedDriverId
                : selectedDriverId !== "")
        );
    }, [props.tour, selectedDriverId, startTime]);

    const hasUnsavedChanges = useMemo(() => {
        return hasUndispatchedChanges || hasUnsavedUpdates;
    }, [hasUnsavedUpdates, hasUndispatchedChanges]);

    const hasDuplicateStops = useMemo(() => {
        if (!props.stops) return false;
        const addresses = props.stops.map((stop) => stop.to_location);
        const addressesSet = new Set([...addresses]);
        return addresses.length !== addressesSet.size;
    }, [props.stops]);

    const groupedStopsMap = useMemo(() => {
        const groupedStopsMap: KeyString<StopDraft[]> = {};

        for (let i = 0; i < props.stops.length; i++) {
            const stop = props.stops[i];

            if (!stop.motion_tools_stop_group) continue;

            const groupId = stop.motion_tools_stop_group;

            if (!groupedStopsMap[groupId]) {
                groupedStopsMap[groupId] = [];
            }

            groupedStopsMap[groupId].push(stop);
        }

        return groupedStopsMap;
    }, [props.stops]);

    const onStopCardClickHandler = useCallback(
        (stop: StopDraft) => {
            if (!onStopCardClick) return;

            const pickup = props.stops.find(
                (s) =>
                    stop.group_id === s.group_id &&
                    s.stop_type_id === STOP_DRAFT_TYPE.Pickup
            );

            const dropoff = props.stops.find(
                (s) =>
                    stop.group_id === s.group_id &&
                    s.stop_type_id === STOP_DRAFT_TYPE.Dropoff
            );

            if (!pickup || !dropoff) return;

            if (
                !pickup.motion_tools_stop_group &&
                !dropoff.motion_tools_stop_group
            ) {
                onStopCardClick({ pickup, dropoff });
                return;
            }

            const groupedStops: {
                pickup: StopDraft;
                dropoff: StopDraft;
            }[] = [];

            if (stop.motion_tools_stop_group) {
                const groups = groupedStopsMap[stop.motion_tools_stop_group];

                if (groups) {
                    for (let i = 0; i < groups.length; i++) {
                        const groupedStop = groups[i];

                        const pickup = props.stops.find(
                            (s) =>
                                groupedStop.group_id === s.group_id &&
                                s.stop_type_id === STOP_DRAFT_TYPE.Pickup
                        );

                        const dropoff = props.stops.find(
                            (s) =>
                                groupedStop.group_id === s.group_id &&
                                s.stop_type_id === STOP_DRAFT_TYPE.Dropoff
                        );

                        if (!dropoff || !pickup) continue;

                        groupedStops.push({
                            pickup,
                            dropoff,
                        });
                    }
                }
            }

            onStopCardClick({ pickup, dropoff, groupedStops });
        },
        [groupedStopsMap, onStopCardClick, props.stops]
    );

    const getEtaStringForStop = useCallback(
        (stop: StopDraft) => {
            const stopEta =
                stop.completed_at || stop.arrived_at || stop.eta_internal;
            if (stopEta) return undefined;

            const tourStartDate = stringToDate(
                (props.tour?.date || "2020-01-01") + "T" + startTime,
                {
                    localTimezone: true,
                }
            );

            if (!tourStartDate) return "";

            return dateToString(
                addMinutes(tourStartDate, stopsDurationsMap[stop.id]),
                {
                    onlyTime: true,
                }
            );
        },
        [props.tour?.date, startTime, stopsDurationsMap]
    );

    const isLoading = useMemo(() => {
        return isCreatingTour || isConsolidatingAndCreatingTour;
    }, [isCreatingTour, isConsolidatingAndCreatingTour]);

    const stopOrderMap = useMemo(
        () => getStopOrderMap(props.stops),
        [props.stops]
    );

    const motionParentVariants = {
        initial: {},
        enter: {
            transition: {
                duration: 0.4,
                delayChildren: 0.02,
                staggerChildren: 0.02,
            },
        },
        exit: {
            transition: {
                duration: 0.4,
                delayChildren: 0.02,
                staggerChildren: 0.02,
            },
        },
    };

    const motionVariants = {
        initial: { opacity: 0, x: -30 },
        enter: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: -30 },
    };

    return (
        <div className={tourColumnClasses.join(" ")} data-column-id={props.id}>
            <motion.div
                className="top"
                variants={motionParentVariants}
                initial="initial"
                animate="enter"
                exit="exit"
            >
                <motion.div variants={motionVariants}>
                    <Dropdown
                        value={selectedDriverId}
                        placeholder={t("bigVolume.chooseDriver")}
                        onSelect={({ value }) => setSelectedDriverId(value)}
                        options={[
                            {
                                label: t("bigVolume.noDriver"),
                                value: "",
                            },
                            ...props.drivers.map((driver) => ({
                                label: getDriverDisplayName(driver),
                                value: driver.mt_driver_id || "",
                            })),
                        ]}
                        isSearchable
                    />
                </motion.div>

                <motion.div
                    variants={motionVariants}
                    className="time-weight-group"
                >
                    <Input
                        type="time"
                        value={startTime}
                        onChange={setStartTime}
                        trailingIcon={faClock}
                        placeholder={t("fleetPlanner.startTimePlaceholder")}
                        style={{ width: "100%" }}
                    />

                    <Input
                        type="text"
                        value={convertToNumberWithSpaces(peakWeight, "kg")}
                        onChange={() => {}}
                        trailingIcon={faWeightHanging}
                        disabled
                        style={{ width: "100%" }}
                    />
                </motion.div>
            </motion.div>

            <Droppable droppableId={props.id}>
                {(provided) => (
                    <motion.div
                        className="content"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        variants={motionParentVariants}
                        initial="initial"
                        animate="enter"
                        exit="exit"
                    >
                        {props.isLoading && (
                            <div className="loading-wrapper">
                                <Spinner />
                            </div>
                        )}
                        {!isLoading &&
                            props.stops.map((stop, i) => {
                                const hasGroupsAround = checkForGroupsAround({
                                    stop,
                                    stopAbove: props.stops[i - 1],
                                    stopBelow: props.stops[i + 1],
                                });

                                const stopsInGroup =
                                    stop.motion_tools_stop_group
                                        ? groupedStopsMap[
                                              stop.motion_tools_stop_group
                                          ]
                                        : [];

                                const groupedWeight = stopsInGroup.reduce(
                                    (acc, stop) => acc + (stop.weight_kg || 0),
                                    0
                                );

                                return (
                                    <motion.div
                                        key={stop.id}
                                        variants={motionVariants}
                                    >
                                        <StopCardDraggable
                                            key={stop.id}
                                            id={stop.id.toString()}
                                            index={i}
                                            stop={stop}
                                            order={
                                                stopOrderMap[
                                                    stop.motion_tools_stop_group ||
                                                        stop.id.toString()
                                                ] + 1
                                            }
                                            hideBecauseOfGroup={
                                                hasGroupsAround.above
                                            }
                                            runningWeight={
                                                runningWeights[
                                                    stopsInGroup.length > 1
                                                        ? i +
                                                          (stopsInGroup.length -
                                                              1)
                                                        : i
                                                ]
                                            }
                                            isDragDisabled={!!stop.completed_at}
                                            eta={getEtaStringForStop(stop)}
                                            isLoading={props.stopIdsLoading.includes(
                                                stop.id
                                            )}
                                            onHover={(groupId) =>
                                                cardHoverHandler({
                                                    groupId,
                                                    columnId: props.id,
                                                })
                                            }
                                            color={props.tourColor}
                                            onClick={() =>
                                                onStopCardClickHandler(stop)
                                            }
                                            groupedAmount={stopsInGroup.length}
                                            groupedWeight={groupedWeight}
                                        />
                                    </motion.div>
                                );
                            })}
                        {props.stops.length === 0 && (
                            <div className="empty-placeholder">
                                <FontAwesomeIcon
                                    icon={faListCheck}
                                    size="xl"
                                    color="var(--color-neutral-400)"
                                    fixedWidth
                                />
                                <p className="text-xs">
                                    {t("fleetPlanner.emptyPlaceholderText")}
                                </p>
                            </div>
                        )}
                        {isLoading && (
                            <div className="empty-placeholder">
                                <FontAwesomeIcon
                                    icon={faSpinnerThird}
                                    size="3x"
                                    color="var(--color-primary-400)"
                                    fixedWidth
                                    spin
                                    style={iconStyle as React.CSSProperties}
                                />
                                <motion.p
                                    className="text-xs"
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    exit={{ opacity: 0, scale: 0.5 }}
                                    transition={{
                                        duration: 0.2,
                                        ease: "easeInOut",
                                    }}
                                >
                                    {isCreatingTour
                                        ? t("fleetPlanner.creatingTourLoading")
                                        : t(
                                              "fleetPlanner.consolidatingTourLoading"
                                          )}
                                </motion.p>
                            </div>
                        )}
                        {provided.placeholder}
                    </motion.div>
                )}
            </Droppable>

            <div className="button-group">
                {props.isNewTourColumn ? (
                    <>
                        <Button
                            disabled={
                                props.stops.length === 0 ||
                                isLoading ||
                                !features?.manage_tours_fleet_planner
                            }
                            style={{ width: "100%" }}
                            variant="primary"
                            label={t("createTour.createTourLabel")}
                            onClick={() =>
                                createTourHandler({
                                    preferredDriverId: selectedDriverId,
                                    date: dateToString(props.filterDate),
                                    time: startTime,
                                    stopDraftIds: props.stops.map((s) => s.id),
                                })
                            }
                        />
                        <Button
                            disabled={
                                props.stops.length === 0 ||
                                !hasDuplicateStops ||
                                isLoading ||
                                !features?.manage_tours_fleet_planner
                            }
                            style={{ width: "100%" }}
                            variant="secondary"
                            label={t("createTour.consolidateTourLabel")}
                            onClick={() =>
                                onConsolidateAndCreateTour({
                                    preferredDriverId: selectedDriverId,
                                    date: dateToString(props.filterDate),
                                    time: startTime,
                                    stopDraftIds: props.stops.map((s) => s.id),
                                })
                            }
                        />
                    </>
                ) : hasUnsavedChanges ? (
                    <>
                        <Button
                            style={{ width: "100%" }}
                            disabled={
                                !hasUnsavedUpdates ||
                                !features?.manage_tours_fleet_planner
                            }
                            variant={"primary"}
                            label={t("createTour.saveTourLabel")}
                            isLoading={isUpdatingTour}
                            onClick={() => {
                                if (!props.tour) return;
                                updateTourHandler({
                                    tourId: props.tour.tour_id,
                                    date: props.tour.date || "",
                                    time: startTime,
                                    preferredDriverId: selectedDriverId,
                                });
                            }}
                        />
                        {hasUndispatchedChanges && !hasUnsavedUpdates ? (
                            <Button
                                style={{ width: "100%" }}
                                variant="secondary"
                                label={t("createTour.dispatchLabel")}
                                isLoading={isDispatchingTour}
                                onClick={() => {
                                    if (!props.tour) return;
                                    dispatchTourHandler(props.tour.tour_id);
                                }}
                                disabled={
                                    !selectedDriverId ||
                                    !features?.manage_tours_fleet_planner
                                }
                            />
                        ) : (
                            <Button
                                style={{ width: "100%" }}
                                variant="secondary"
                                label={t("createTour.saveAndDispatchLabel")}
                                isLoading={isUpdatingAndDispatchingTour}
                                onClick={() => {
                                    if (!props.tour) return;
                                    updateAndDispatchTourHandler({
                                        tourId: props.tour.tour_id,
                                        date: props.tour.date || "",
                                        time: startTime,
                                        preferredDriverId: selectedDriverId,
                                    });
                                }}
                                disabled={
                                    !selectedDriverId ||
                                    !features?.manage_tours_fleet_planner
                                }
                            />
                        )}
                    </>
                ) : null}
            </div>
        </div>
    );
}

export default TourColumn;
