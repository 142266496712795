import axios from "../shared/services/axios";
import {
    CreateStopDraftData,
    Order,
    OrderDraft,
    StopDraft,
    UpdateStopDraftData,
} from "../shared/types/api";

export function createStopDrafts(stops: CreateStopDraftData[]) {
    return axios.post<StopDraft[]>("/stop_drafts", {
        stops,
    });
}

export function getStopDrafts({
    date,
    time,
}: {
    date?: string;
    time?: string;
} = {}) {
    return axios.get<{
        stop_drafts: StopDraft[];
    }>("/v2/stop_drafts", {
        params: {
            date_tooltip: date,
            time_tooltip: time,
        },
    });
}

export function updateStopDrafts(drafts: UpdateStopDraftData[]) {
    return axios.put<string>("/stop_drafts", {
        drafts,
    });
}

export function updateStopColor(stopId: number, color: string | null) {
    return axios.put<string>(`/stop_draft/change_color`, {
        stop_draft_id: stopId,
        color,
    });
}

export function deleteStopDraft(id: number) {
    return axios.delete<string>(`/stop_draft/${id}`);
}

export function setStopDraftsAsExternal(data: {
    pickup: StopDraft;
    dropoff: StopDraft;
    date?: string | null;
    time?: string | null;
}) {
    return axios.post<Order>("/stop_drafts/external", data);
}

export function fulfillStopDraft(stopDraftId: number) {
    return axios.post<string>("/stop_draft/fulfill", {
        stop_draft_id: stopDraftId,
    });
}

export function getOrderDrafts() {
    return axios.get<OrderDraft[]>("/order/drafts");
}

export function updateOrderDraft({
    id,
    orderId,
    stopDraftIds,
}: {
    id: number;
    orderId?: number;
    stopDraftIds?: number[];
}) {
    return axios.put<string>("/order/draft", {
        id,
        order_id: orderId,
        stop_draft_ids: stopDraftIds,
    });
}

export function deleteOrderDraft(id: number) {
    return axios.delete<string>(`/order/draft/${id}`);
}

export function getProjectReferences() {
    return axios.get<{ name: string }[]>("/project_references");
}

export function changeStopDraftLocation({
    stopIds,
    locationId,
    notifyCustomerEmail,
}: {
    stopIds: number[];
    locationId: number;
    notifyCustomerEmail?: string;
}) {
    return axios.post<string>("/stop_draft/move", {
        stop_draft_ids: stopIds,
        location_id: locationId,
        notify_customer_email: notifyCustomerEmail,
    });
}

export function undoStopDraftLocationChange(stopIds: number[]) {
    return axios.post<string>("/stop_draft/undo_move", {
        stop_draft_ids: stopIds,
    });
}

export function acceptStopDraftLocationChange(stopIds: number[]) {
    return axios.post<string>("/stop_draft/accept_move", {
        stop_draft_ids: stopIds,
    });
}

export function getMovedStopDrafts() {
    return axios.get<StopDraft[]>("/stop_drafts/moved");
}

export function bulkDeleteStopDrafts(stopDraftIds: number[]) {
    return axios.post<string>("/location/stop_drafts", {
        stop_draft_ids: stopDraftIds,
    });
}

export function bulkDeleteOrderDrafts(orderDraftIds: number[]) {
    return axios.post<string>("/location/order_drafts", {
        order_draft_ids: orderDraftIds,
    });
}
