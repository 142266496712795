import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { updateDriver } from "../../api/driver";
import Button from "../../components/buttons/Button";
import Dropdown from "../../components/inputs/Dropdown";
import Input from "../../components/inputs/Input";
import Popup from "../../hoc/Popup";
import useFuelTypes from "../../hooks/data/useFuleTypes";
import { driverForm, DriverForm } from "../../schemas/form";
import { Driver } from "../../shared/types/api";
import { getDriverDisplayName, onFormError } from "../../shared/utility/misc";
import "./style.scss";
import PhoneInput from "../../components/inputs/PhoneInput";

type Props = {
    showPopup: boolean;
    onClose: () => void;
    editDriver: Driver;
};

function EditDriverPopup(props: Props) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { fuleTypes, status: fuelTypesStatus } = useFuelTypes();

    const defaultValues = useMemo<DriverForm>(() => {
        return {
            phone: props.editDriver.phone || "",
            firstName: props.editDriver.first_name,
            lastName: props.editDriver.last_name,
            hourlyCost: props.editDriver.hourly_cost?.toString() || "",
            fuelTypeId: props.editDriver.fuel_type_id || null,
            enabled: props.editDriver.enabled,
            fuelConsumption:
                props.editDriver.fuel_consumption?.toString() || "",
        };
    }, [props.editDriver]);

    const { handleSubmit, control, reset } = useForm<DriverForm>({
        resolver: zodResolver(driverForm),
        defaultValues,
    });

    const { mutate: editDriverHandler, isPending: isEditingDriver } =
        useMutation({
            mutationFn: async ({
                driverId,
                data,
            }: {
                driverId: number;
                data: DriverForm;
            }) => {
                const formattedFuelConsumption =
                    data.fuelConsumption?.replace(",", ".") || "";
                const formattedHourlyCost =
                    data.hourlyCost?.replace(",", ".") || "";

                await updateDriver({
                    id: driverId,
                    data: {
                        ...data,
                        fuelConsumption: isNaN(+formattedFuelConsumption)
                            ? "0"
                            : formattedFuelConsumption,
                        hourlyCost: isNaN(+formattedHourlyCost)
                            ? "0"
                            : formattedHourlyCost,
                    },
                });
            },
            onSuccess: () => {
                toast.success(t("successMessages.driverUpdated"));
                queryClient.invalidateQueries({
                    queryKey: ["drivers"],
                    type: "all",
                });
                props.onClose();
            },
            onError: () => {
                toast.error(t("errorMessage.unknown"));
            },
        });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    return (
        <Popup
            showPopup={props.showPopup}
            onClose={props.onClose}
            title={
                t("popup.updateDriver.title") +
                ` ${getDriverDisplayName(props.editDriver)}`
            }
        >
            <div className="edit-driver-popup">
                <Controller
                    name="phone"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <PhoneInput
                            value={value}
                            onChange={onChange}
                            label={t("popup.driver.phoneLabel")}
                            disabled
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="firstName"
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="text"
                            value={value}
                            onChange={onChange}
                            label={t("popup.driver.firstNameLabel")}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name="lastName"
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="text"
                            value={value}
                            onChange={onChange}
                            label={t("popup.driver.lastNameLabel")}
                        />
                    )}
                />
                <Controller
                    name="hourlyCost"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="text"
                            value={value}
                            onChange={onChange}
                            label={t("popup.driver.hourlyCostLabel")}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="fuelTypeId"
                    render={({ field: { value, onChange } }) => (
                        <Dropdown
                            value={value?.toString() || null}
                            onSelect={(o) => onChange(+o.value)}
                            label={t("popup.driver.fuleTypeLabel")}
                            options={
                                fuleTypes?.map((f) => ({
                                    value: f.id.toString(),
                                    label: f.name,
                                })) || []
                            }
                            isLoading={fuelTypesStatus === "pending"}
                            isSearchable
                        />
                    )}
                />
                <Controller
                    name="fuelConsumption"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="text"
                            value={value}
                            onChange={onChange}
                            label={t("popup.driver.fuleConsumptionLabel")}
                        />
                    )}
                />
                <Button
                    label={t("popup.updateDriver.submitLabel")}
                    variant="primary"
                    onClick={handleSubmit(
                        (data) =>
                            editDriverHandler({
                                driverId: props.editDriver.id,
                                data,
                            }),
                        onFormError
                    )}
                    isLoading={isEditingDriver}
                />
            </div>
        </Popup>
    );
}

export default EditDriverPopup;
