import { faComment } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { shareOrderPublic } from "../../api/order";
import TextButton from "../../components/buttons/TextButton";
import StatusCircle from "../../components/UI/StatusCircle";
import Table from "../../containers/Table";
import { Order } from "../../shared/types/api";
import { formatDateString } from "../../shared/utility/date";
import { getOrderStatus } from "../../shared/utility/order";
import { QUERY_PARAM, ROUTE } from "../../shared/values/enums";
import "./style.scss";

type Props = {
    orders: Order[];
    onRowClick: (order: Order) => void;
    hideFulfilled?: boolean;
};

function OrdersTable(props: Props) {
    const { t } = useTranslation();
    const [orderIdNavigatingTo, setOrderIdNavigatingTo] = useState<
        number | null
    >(null);

    const getOrderStatusText = useCallback(
        (order: Order) => {
            const orderStatus = getOrderStatus(order);

            let statusText = "Status";
            switch (orderStatus) {
                case "created":
                    statusText = t("orderHistory.statusCreated");
                    break;
                case "verified":
                    statusText = t("orderHistory.statusVerified");
                    break;
                case "at-pickup":
                    statusText = t("orderHistory.statusAtPickup");
                    break;
                case "in-progress":
                    statusText = t("orderHistory.statusInProgress");
                    break;
                case "next-stop":
                    statusText = t("orderHistory.statusNextStop");
                    break;
                case "unloading":
                    statusText = t("orderHistory.statusUnloading");
                    break;
                case "fulfilled":
                    statusText = t("orderHistory.statusFulfilled");
                    break;
            }

            return statusText;
        },
        [t]
    );

    const { mutate: navigateToTrackingLink } = useMutation({
        mutationFn: async (orderId: number) => {
            setOrderIdNavigatingTo(orderId);
            const { data } = await shareOrderPublic(orderId);

            return data.public_id;
        },
        onSuccess: (publicId) => {
            window.open(
                `${window.location.origin}${ROUTE.Tracking}?${QUERY_PARAM.PublicOrderId}=${publicId}`,
                "_blank"
            );
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
        onSettled: () => {
            setOrderIdNavigatingTo(null);
        },
    });

    const trackClickHandler = useCallback(
        (order: Order) => {
            navigateToTrackingLink(order.id);
            return;
        },
        [navigateToTrackingLink]
    );

    return (
        <Table
            data={props.orders}
            onRowClick={props.onRowClick}
            rowHeight="40px"
            columns={[
                {
                    key: "status",
                    title: t("orderHistory.status"),
                    render: (order) => (
                        <div className="component-cell">
                            <StatusCircle status={getOrderStatus(order)} />
                            {getOrderStatusText(order)}
                        </div>
                    ),
                },
                {
                    key: "order-number",
                    title: t("orderHistory.orderNumber"),
                    getValue: (order) => order.origin_order_id || "-",
                },
                {
                    key: "created",
                    title: t("orderHistory.created"),
                    render: (order) => (
                        <div className="component-cell">
                            <div
                                className="created-by"
                                title={`${order.customer?.first_name} ${order.customer?.last_name}`}
                            >
                                {`${order.customer?.first_name.charAt(
                                    0
                                )}${order.customer?.last_name.charAt(
                                    0
                                )}`.toUpperCase()}
                            </div>
                            {formatDateString(order.created_at || "")}
                        </div>
                    ),
                },
                {
                    key: "pickup",
                    title: t("orderHistory.pickUp"),
                    render: (order) => (
                        <div
                            className="component-cell"
                            title={order.pickup_address}
                        >
                            {order.pickup_driver_note && (
                                <FontAwesomeIcon icon={faComment} />
                            )}
                            <span>{order.pickup_address || "-"}</span>
                            {!order.fulfilled_at && (
                                <TextButton
                                    label={t("orderHistory.track")}
                                    variant="primary"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        trackClickHandler(order);
                                    }}
                                    isLoading={orderIdNavigatingTo === order.id}
                                    style={{
                                        marginLeft: "auto",
                                    }}
                                />
                            )}
                        </div>
                    ),
                },
                {
                    key: "dropoff",
                    title: t("orderHistory.dropOff"),
                    render: (order) => (
                        <div
                            className="component-cell"
                            title={order.dropoff_address}
                        >
                            {order.dropoff_driver_note && (
                                <FontAwesomeIcon icon={faComment} />
                            )}
                            <span>{order.dropoff_address || "-"}</span>
                            {!order.fulfilled_at && (
                                <TextButton
                                    label={t("orderHistory.track")}
                                    variant="primary"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        trackClickHandler(order);
                                    }}
                                    isLoading={orderIdNavigatingTo === order.id}
                                    style={{
                                        marginLeft: "auto",
                                    }}
                                />
                            )}
                        </div>
                    ),
                },
                {
                    key: "fulfilled-time",
                    title: t("orderHistory.fulfilledTime"),
                    getValue: (order) =>
                        order.fulfilled_at
                            ? formatDateString(order.fulfilled_at)
                            : "-",
                    hide: props.hideFulfilled,
                },
            ]}
        />
    );
}

export default OrdersTable;
