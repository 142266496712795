import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/buttons/Button";
import Input from "../../../components/inputs/Input";
import { AuthCodeForm, authCodeForm } from "../../../schemas/auth";
import { QUERY_PARAM, ROUTE } from "../../../shared/values/enums";
import "./style.scss";

type Props = {};

function AuthCode(props: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { control, handleSubmit } = useForm<AuthCodeForm>({
        resolver: zodResolver(authCodeForm),
        defaultValues: {
            activationKey: "",
        },
    });

    const { mutate: authCodeHandler, isPending } = useMutation({
        mutationFn: async ({ activationKey }: AuthCodeForm) => {
            await new Promise((resolve) => setTimeout(resolve, 1000));

            return activationKey;
        },
        onSuccess: (activationKey) => {
            navigate({
                pathname: ROUTE.Setup,
                search: `?${QUERY_PARAM.ActivationKey}=${activationKey}`,
            });
        },
        onError: () => {
            toast.error(t("errorMessage.authCodeError"));
        },
    });

    return (
        <div className="auth-code">
            <form onSubmit={handleSubmit((data) => authCodeHandler(data))}>
                <h1 className="text-sm">{t("authCode.title")}</h1>
                <hr />
                <Controller
                    name="activationKey"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="text"
                            value={value}
                            onChange={onChange}
                            label={t("authCode.authCodeLabel")}
                            placeholder={t("authCode.authCodePlaceholder")}
                            style={{ width: "100%" }}
                        />
                    )}
                />
                <Button
                    variant="secondary"
                    label={t("authCode.submitButtonLabel")}
                    style={{ width: "100%" }}
                    isLoading={isPending}
                />
            </form>
        </div>
    );
}

export default AuthCode;
