import {
    CSSProperties,
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
import TextButton from "../../buttons/TextButton";
import "./style.scss";

type OrderSection = {
    title: string;
    value?: string | number | null;
    titleStyle?: CSSProperties;
    valueStyle?: CSSProperties;
    valueComponent?: ReactNode;
    hide?: boolean;
};

type Props<T> = {
    order: T;
    getSections: (order: T) => OrderSection[][];
    getShowMoreSections?: (order: T) => OrderSection[][];
};

function OrderDetails<T>(props: Props<T>) {
    const { t } = useTranslation();

    const { getSections, getShowMoreSections } = props;

    const [isShowingMore, setIsShowingMore] = useState<Boolean>(false);

    const shouldShowValue = useCallback(
        (section: OrderSection) =>
            (!!section.value || !!section.valueComponent) && !section.hide,
        []
    );

    const sections = useMemo(() => {
        return getSections(props.order).filter((section) =>
            section.some(shouldShowValue)
        );
    }, [getSections, props.order, shouldShowValue]);

    const showMoreSections = useMemo(() => {
        return (
            getShowMoreSections?.(props.order).filter((section) =>
                section.some(shouldShowValue)
            ) || []
        );
    }, [getShowMoreSections, props.order, shouldShowValue]);

    return (
        <div className="order-details">
            {sections.map((section, i) => (
                <section key={i}>
                    {section
                        .filter(shouldShowValue)
                        .map(
                            ({
                                title,
                                value,
                                titleStyle,
                                valueStyle,
                                valueComponent,
                            }) => (
                                <div className="info" key={title + value}>
                                    <p
                                        className="title text-xs"
                                        style={titleStyle}
                                    >
                                        {title}
                                    </p>
                                    {valueComponent ? (
                                        <div className="value">
                                            {valueComponent}
                                        </div>
                                    ) : (
                                        <p
                                            className="value text-sm"
                                            style={valueStyle}
                                        >
                                            {value}
                                        </p>
                                    )}
                                </div>
                            )
                        )}
                </section>
            ))}
            {showMoreSections.length > 0 && (
                <div className="show-more-wrapper">
                    <TextButton
                        label={
                            isShowingMore
                                ? t("popup.confirmOrder.hideMoreLabel")
                                : t("popup.confirmOrder.showMoreLabel")
                        }
                        variant="secondary"
                        onClick={() => setIsShowingMore((state) => !state)}
                    />
                </div>
            )}
            {showMoreSections.length > 0 &&
                isShowingMore &&
                showMoreSections.map((section, i) => (
                    <section key={"more-" + i}>
                        {section
                            .filter(shouldShowValue)
                            .map(
                                ({
                                    title,
                                    value,
                                    titleStyle,
                                    valueStyle,
                                    valueComponent,
                                }) => (
                                    <div className="info" key={title + value}>
                                        <p
                                            className="title text-xs"
                                            style={titleStyle}
                                        >
                                            {title}
                                        </p>
                                        {valueComponent ? (
                                            <div className="value">
                                                {valueComponent}
                                            </div>
                                        ) : (
                                            <p
                                                className="value text-sm"
                                                style={valueStyle}
                                            >
                                                {value}
                                            </p>
                                        )}
                                    </div>
                                )
                            )}
                    </section>
                ))}
        </div>
    );
}

export default OrderDetails;
