import {
    faComment,
    faImage,
    faTruck,
} from "@fortawesome/pro-regular-svg-icons";
import { faFlagCheckered } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import StatusCircle from "../../components/UI/StatusCircle";
import Table from "../../containers/Table";
import { Order } from "../../shared/types/api";
import { formatDateString } from "../../shared/utility/date";
import { getOrderStatus } from "../../shared/utility/order";
import { STOP_DRAFT_TYPE } from "../../shared/values/enums";
import "./style.scss";

type Props = {
    orders: Order[];
    onRowClick: (order: Order) => void;
};

function FleetOrdersTable(props: Props) {
    const { t } = useTranslation();

    const getOrderStatusText = useCallback(
        (order: Order) => {
            const orderStatus = getOrderStatus(order);

            let statusText = "Status";
            switch (orderStatus) {
                case "created":
                    statusText = t("orderHistory.statusCreated");
                    break;
                case "verified":
                    statusText = t("orderHistory.statusVerified");
                    break;
                case "at-pickup":
                    statusText = t("orderHistory.statusAtPickup");
                    break;
                case "in-progress":
                    statusText = t("orderHistory.statusInProgress");
                    break;
                case "next-stop":
                    statusText = t("orderHistory.statusNextStop");
                    break;
                case "unloading":
                    statusText = t("orderHistory.statusUnloading");
                    break;
                case "fulfilled":
                    statusText = t("orderHistory.statusFulfilled");
                    break;
            }

            if (order.archived_at && !order.fulfilled_at) {
                statusText = t("orderHistory.statusArchived");
            }

            return statusText;
        },
        [t]
    );

    return (
        <Table
            data={props.orders}
            onRowClick={props.onRowClick}
            rowHeight="50px"
            columns={[
                {
                    key: "status",
                    title: t("orderHistory.status"),
                    render: (order) => (
                        <div className="component-cell">
                            <StatusCircle
                                status={getOrderStatus(order)}
                                isArchived={
                                    !!order.archived_at && !order.fulfilled_at
                                }
                            />
                            {getOrderStatusText(order)}
                        </div>
                    ),
                },
                {
                    key: "created",
                    title: t("orderHistory.created"),
                    render: (order) => (
                        <div className="component-cell">
                            <div
                                className="created-by"
                                title={`${order.customer?.first_name} ${order.customer?.last_name}`}
                            >
                                {`${order.customer?.first_name.charAt(
                                    0
                                )}${order.customer?.last_name.charAt(
                                    0
                                )}`.toUpperCase()}
                            </div>
                            {formatDateString(order.created_at || "")}
                        </div>
                    ),
                },
                {
                    key: "order-number",
                    title: t("orderHistory.orderNumber"),
                    getValue: (order) => order.origin_order_id || "-",
                },
                {
                    key: "contact-name",
                    title: t("orderHistory.contactName"),
                    render: (order) => (
                        <div
                            className="component-cell"
                            style={{
                                flexDirection: "column",
                                alignItems: "flex-start",
                            }}
                        >
                            {order.pickup_contact && (
                                <div
                                    className="contact-name"
                                    title={order.pickup_contact.name}
                                >
                                    <FontAwesomeIcon icon={faTruck} />
                                    {order.pickup_contact.name}
                                </div>
                            )}
                            {order.dropoff_contact && (
                                <div className="contact-name">
                                    <FontAwesomeIcon icon={faFlagCheckered} />
                                    {order.dropoff_contact.name}
                                </div>
                            )}
                        </div>
                    ),
                },
                {
                    key: "driver",
                    title: t("orderHistory.driver"),
                    render: (order) => (
                        <div className="component-cell">
                            {order.carrier_delivery_requests?.[0]
                                ?.external_carrier && (
                                <FontAwesomeIcon icon={faTruck} />
                            )}
                            {order.preferred_driver ||
                                order.carrier_delivery_requests?.[0]
                                    ?.external_carrier?.name ||
                                "-"}
                        </div>
                    ),
                    width: "180px",
                },
                {
                    key: "pickup",
                    title: t("orderHistory.pickedUp"),
                    render: (order) => (
                        <div className="component-cell">
                            {order.pickup_driver_note && (
                                <FontAwesomeIcon icon={faComment} />
                            )}
                            {order.proof_of_deliveries?.some(
                                (pod) =>
                                    pod.stop_type_id === STOP_DRAFT_TYPE.Pickup
                            ) && <FontAwesomeIcon icon={faImage} />}
                            <span>{order.pickup_address}</span>
                        </div>
                    ),
                },
                {
                    key: "dropoff",
                    title: t("orderHistory.droppedOff"),
                    render: (order) => (
                        <div className="component-cell">
                            {order.dropoff_driver_note && (
                                <FontAwesomeIcon icon={faComment} />
                            )}
                            {order.proof_of_deliveries?.some(
                                (pod) =>
                                    pod.stop_type_id === STOP_DRAFT_TYPE.Dropoff
                            ) && <FontAwesomeIcon icon={faImage} />}
                            <span>{order.dropoff_address}</span>
                        </div>
                    ),
                },
                {
                    key: "fulfilled-time",
                    title: t("orderHistory.fulfilledTime"),
                    getValue: (order) =>
                        order.fulfilled_at
                            ? formatDateString(order.fulfilled_at, {
                                  showTime: true,
                              })
                            : "-",
                    width: "140px",
                },
            ]}
        />
    );
}

export default FleetOrdersTable;
