import {
    IconDefinition,
    faCopy,
    faDownToBracket,
    faPen,
    faShare,
    faSquareXmark,
    faTag,
    faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import axios from "axios";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../../components/buttons/Button";
import Tabs from "../../components/buttons/Tabs";
import Badge from "../../components/UI/Badge";
import OrderFile from "../../containers/OrderFile";
import Popup from "../../hoc/Popup";
import useUserFeatures from "../../hooks/functionality/useUserFeatures";
import { StopDraft } from "../../shared/types/api";
import { ReduxState } from "../../shared/types/redux";
import { formatDateString, formatDuration } from "../../shared/utility/date";
import { convertToNumberWithSpaces } from "../../shared/utility/misc";
import { getOrderDurations } from "../../shared/utility/order";
import { STOP_DRAFT_TYPE } from "../../shared/values/enums";
import { motion } from "framer-motion";
import "./style.scss";

type StopsInfoPopupSections = "info" | "status";

type Props = {
    showPopup: boolean;
    onClose: () => void;
    stops: {
        pickup: StopDraft;
        dropoff: StopDraft;
    };
    groupedStops?: {
        pickup: StopDraft;
        dropoff: StopDraft;
    }[];
    onEditStops?: (data: { pickup: StopDraft; dropoff: StopDraft }) => void;
    onDetachStops?: (data: { pickup: StopDraft; dropoff: StopDraft }) => void;
    onAssignOutsideFleet?: (data: {
        pickup: StopDraft;
        dropoff: StopDraft;
    }) => void;
    onChangedLocation?: (data: {
        pickup: StopDraft;
        dropoff: StopDraft;
    }) => void;
    onDeselectStops?: (data: { pickup: StopDraft; dropoff: StopDraft }) => void;

    onRespondToStopRequest?: (data: {
        pickup: StopDraft;
        dropoff: StopDraft;
        response: "accept" | "reject";
    }) => void;

    onSendBackToOrginLocation?: (data: {
        pickup: StopDraft;
        dropoff: StopDraft;
    }) => void;

    onFulfillExternalStop?: (data: {
        pickup: StopDraft;
        dropoff: StopDraft;
    }) => void;
    onDetachExternalStop?: (data: {
        pickup: StopDraft;
        dropoff: StopDraft;
    }) => void;
};

function StopsInfoPopup(props: Props) {
    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);
    const features = useUserFeatures();

    const [selectedGroupedStops, setSelectedGroupedStops] = useState<{
        pickup: StopDraft;
        dropoff: StopDraft;
    } | null>(null);

    const [selectedSection, setSelectedSection] =
        useState<StopsInfoPopupSections>("info");

    const stopsToDisplay = useMemo(() => {
        if (selectedGroupedStops) return selectedGroupedStops;

        return props.stops;
    }, [props.stops, selectedGroupedStops]);

    const orderDurations = useMemo(() => {
        return getOrderDurations(stopsToDisplay.pickup.order || null);
    }, [stopsToDisplay.pickup.order]);

    // Assigned from other location START
    const isAssignedFromOtherLocation = useMemo(() => {
        if (!stopsToDisplay.pickup.origin_location) return false;

        return user?.location_id !== stopsToDisplay.pickup.origin_location_id;
    }, [
        stopsToDisplay.pickup.origin_location,
        stopsToDisplay.pickup.origin_location_id,
        user?.location_id,
    ]);

    const isAssignedToOtherLocation = useMemo(() => {
        if (!stopsToDisplay.pickup.origin_location) return false;

        return user?.location_id === stopsToDisplay.pickup.origin_location_id;
    }, [
        stopsToDisplay.pickup.origin_location,
        stopsToDisplay.pickup.origin_location_id,
        user?.location_id,
    ]);

    const isStopRequestAccepted = useMemo(() => {
        if (!stopsToDisplay.pickup.origin_location) return false;

        return !!stopsToDisplay.pickup.move_accepted_at;
    }, [
        stopsToDisplay.pickup.move_accepted_at,
        stopsToDisplay.pickup.origin_location,
    ]);
    // Assigned from other location END

    // Stop status START
    const isStopPlannedInTour = useMemo(() => {
        return !!stopsToDisplay.pickup.tour_id;
    }, [stopsToDisplay.pickup.tour_id]);

    const isOrderCompleted = useMemo(() => {
        return (
            !!stopsToDisplay.pickup.completed_at &&
            !!stopsToDisplay.dropoff.completed_at
        );
    }, [
        stopsToDisplay.dropoff.completed_at,
        stopsToDisplay.pickup.completed_at,
    ]);
    // Stop status END

    // Assigned to external START
    const isAssignedToExternal = useMemo(() => {
        return !!stopsToDisplay.pickup.order?.carrier_delivery_requests?.[0];
    }, [stopsToDisplay.pickup.order]);
    // Assigned to external END

    const isStopDetachable = useMemo(() => {
        if (isStopPlannedInTour) {
            if (isOrderCompleted) {
                return false;
            }

            if (isAssignedToOtherLocation) {
                return false;
            }

            return true;
        }

        return isAssignedToExternal;
    }, [
        isAssignedToExternal,
        isAssignedToOtherLocation,
        isOrderCompleted,
        isStopPlannedInTour,
    ]);

    const isStopEditable = useMemo(() => {
        return (
            !isAssignedFromOtherLocation &&
            !isAssignedToOtherLocation &&
            !isAssignedToExternal &&
            !stopsToDisplay.pickup.order?.assigned_location_id
        );
    }, [
        isAssignedFromOtherLocation,
        isAssignedToExternal,
        isAssignedToOtherLocation,
        stopsToDisplay.pickup.order?.assigned_location_id,
    ]);

    const canStopBeAssignedToOutsideFleet = useMemo(() => {
        if (isStopPlannedInTour) return false;

        if (isAssignedToOtherLocation) return false;

        if (isAssignedToExternal) return false;

        if (isAssignedFromOtherLocation && !isStopRequestAccepted) return false;

        return true;
    }, [
        isAssignedFromOtherLocation,
        isAssignedToExternal,
        isAssignedToOtherLocation,
        isStopPlannedInTour,
        isStopRequestAccepted,
    ]);

    const canStopBeSentBackToOriginLocation = useMemo(() => {
        if (isAssignedFromOtherLocation && isStopRequestAccepted) {
            if (isStopPlannedInTour) return false;
            if (isAssignedToExternal) return false;
            return true;
        }
        return false;
    }, [
        isAssignedFromOtherLocation,
        isAssignedToExternal,
        isStopPlannedInTour,
        isStopRequestAccepted,
    ]);

    const stopTabs = useMemo(() => {
        return (
            props.groupedStops?.flatMap(({ pickup }) => [
                {
                    value: pickup.id.toString(),
                    label: pickup.order_number,
                },
            ]) || []
        );
    }, [props.groupedStops]);

    const proofOfDeliveries = useMemo<{
        pickup: string[];
        dropoff: string[];
    }>(() => {
        const pickup: string[] = [];
        const dropoff: string[] = [];

        if (!stopsToDisplay.pickup.order?.proof_of_deliveries)
            return { pickup, dropoff };

        for (
            let i = 0;
            i < stopsToDisplay.pickup.order.proof_of_deliveries.length;
            i++
        ) {
            const pod = stopsToDisplay.pickup.order.proof_of_deliveries[i];
            if (pod.stop_type_id === STOP_DRAFT_TYPE.Pickup)
                pickup.push(pod.url);
            else if (pod.stop_type_id === STOP_DRAFT_TYPE.Dropoff)
                dropoff.push(pod.url);
        }

        return {
            pickup,
            dropoff,
        };
    }, [stopsToDisplay.pickup.order]);

    const downloadProofOfDeliveries = useCallback(
        async (podUrls: string[], type: "pickup" | "dropoff") => {
            for (let i = 0; i < podUrls.length; i++) {
                const res = await axios.get(podUrls[i], {
                    responseType: "blob",
                });

                const url = window.URL.createObjectURL(new Blob([res.data]));

                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    `proof-of-delivery-${
                        stopsToDisplay.pickup.order?.origin_order_id
                    }-${type}-${i + 1}.jpg`
                );
                link.click();
            }
        },
        [stopsToDisplay.pickup.order]
    );

    const sections = useMemo<
        {
            label: string;
            value: string | ReactElement;
            icon?: IconDefinition;
        }[][]
    >(() => {
        return [
            [
                {
                    label: t("popup.stopsInfo.date"),
                    value: stopsToDisplay.pickup.date_tooltip || "-",
                },
            ],
            [
                {
                    label: t("popup.stopsInfo.pickupAddress"),
                    value: stopsToDisplay.pickup.to_location,
                    icon: faCopy,
                },
                {
                    label: t("popup.stopsInfo.pickupContact"),
                    value: stopsToDisplay.pickup.contact?.name || "-",
                },
                {
                    label: t("popup.stopsInfo.pickupTime"),
                    value: stopsToDisplay.pickup.time_tooltip || "-",
                },
            ],
            [
                {
                    label: t("popup.stopsInfo.dropoffAddress"),
                    value: stopsToDisplay.dropoff.to_location,
                    icon: faCopy,
                },
                {
                    label: t("popup.stopsInfo.dropoffContact"),
                    value: stopsToDisplay.dropoff.contact?.name || "-",
                },
                {
                    label: t("popup.stopsInfo.dropoffTime"),
                    value: stopsToDisplay.dropoff.time_tooltip || "-",
                },
            ],
            [
                {
                    label: t("popup.stopsInfo.estimatedOffloadTime"),
                    value: convertToNumberWithSpaces(
                        stopsToDisplay.pickup.estimated_offload_time,
                        "min"
                    ),
                },
                {
                    label: t("popup.stopsInfo.weight"),
                    value: stopsToDisplay.pickup.weight_kg
                        ? convertToNumberWithSpaces(
                              stopsToDisplay.pickup.weight_kg,
                              "kg"
                          )
                        : "-",
                },
            ],
            [
                {
                    label: t("popup.stopsInfo.cargoContent"),
                    value: stopsToDisplay.pickup.cargo_content,
                },
            ],
            [
                {
                    label: t("popup.stopsInfo.driverInstructions"),
                    value: stopsToDisplay.pickup.driver_instructions || "-",
                },
            ],
        ];
    }, [
        t,
        stopsToDisplay.pickup.date_tooltip,
        stopsToDisplay.pickup.to_location,
        stopsToDisplay.pickup.contact?.name,
        stopsToDisplay.pickup.time_tooltip,
        stopsToDisplay.pickup.estimated_offload_time,
        stopsToDisplay.pickup.weight_kg,
        stopsToDisplay.pickup.cargo_content,
        stopsToDisplay.pickup.driver_instructions,
        stopsToDisplay.dropoff.to_location,
        stopsToDisplay.dropoff.contact?.name,
        stopsToDisplay.dropoff.time_tooltip,
    ]);

    const statusSections = useMemo<
        {
            label: string;
            value: string | ReactElement;
        }[][]
    >(() => {
        return [
            [
                {
                    label: t("popup.stopsInfo.loadingTimeLabel"),
                    value: orderDurations.loadingTime
                        ? formatDuration(orderDurations.loadingTime)
                        : "-",
                },
                {
                    label: t("popup.stopsInfo.drivingTimeLabel"),
                    value: orderDurations.drivingTime
                        ? formatDuration(orderDurations.drivingTime)
                        : "-",
                },
                {
                    label: t("popup.stopsInfo.offloadingTimeLabel"),
                    value: orderDurations.offloading
                        ? formatDuration(orderDurations.offloading)
                        : "-",
                },
            ],
            [
                {
                    label: t("popup.stopsInfo.driverCommentPickupLabel"),
                    value: stopsToDisplay.pickup.driver_note || "-",
                },
                {
                    label: t("popup.stopsInfo.driverCommentDropoffLabel"),
                    value: stopsToDisplay.dropoff.driver_note || "-",
                },
            ],
            [
                {
                    label: t("popup.stopsInfo.pickupPodLabel"),
                    value: (
                        <div className="proof-of-deliveries">
                            {!proofOfDeliveries.pickup.length && "-"}

                            {proofOfDeliveries.pickup.map((prodUrl) => (
                                <a
                                    key={prodUrl}
                                    href={prodUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="pod-link"
                                >
                                    <img
                                        src={prodUrl}
                                        alt={t(
                                            "popup.orderInfo.proofOfDeliveryLabel"
                                        )}
                                    />
                                </a>
                            ))}
                        </div>
                    ),
                },
                {
                    label: "",
                    value: !!proofOfDeliveries.pickup.length ? (
                        <Button
                            label={t("popup.stopsInfo.downloadPodsLabel")}
                            variant={"secondary"}
                            onClick={() => {
                                downloadProofOfDeliveries(
                                    proofOfDeliveries.pickup,
                                    "pickup"
                                );
                            }}
                            leadingIcon={faDownToBracket}
                        />
                    ) : (
                        ""
                    ),
                },
            ],
            [
                {
                    label: t("popup.stopsInfo.dropoffPodLabel"),
                    value: (
                        <div className="proof-of-deliveries">
                            {!proofOfDeliveries.dropoff.length && "-"}

                            {proofOfDeliveries.dropoff.map((prodUrl) => (
                                <a
                                    key={prodUrl}
                                    href={prodUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="pod-link"
                                >
                                    <img
                                        src={prodUrl}
                                        alt={t(
                                            "popup.orderInfo.proofOfDeliveryLabel"
                                        )}
                                    />
                                </a>
                            ))}
                        </div>
                    ),
                },
                {
                    label: "",
                    value: !!proofOfDeliveries.dropoff.length ? (
                        <Button
                            label={t("popup.stopsInfo.downloadPodsLabel")}
                            variant={"secondary"}
                            onClick={() => {
                                downloadProofOfDeliveries(
                                    proofOfDeliveries.dropoff,
                                    "dropoff"
                                );
                            }}
                            leadingIcon={faDownToBracket}
                        />
                    ) : (
                        ""
                    ),
                },
            ],
        ];
    }, [
        t,
        orderDurations.loadingTime,
        orderDurations.drivingTime,
        orderDurations.offloading,
        stopsToDisplay.pickup.driver_note,
        stopsToDisplay.dropoff.driver_note,
        proofOfDeliveries.pickup,
        proofOfDeliveries.dropoff,
        downloadProofOfDeliveries,
    ]);

    const motionVariants = {
        initial: {
            opacity: 0,
            transition: { duration: 0.4, ease: [0, 0, 0.2, 1] },
        },
        enter: {
            opacity: 1,
            transition: { duration: 0.4, ease: [0, 0, 0.2, 1] },
        },
        exit: {
            opacity: 0,
            transition: { duration: 0.4, ease: [0, 0, 0.2, 1] },
        },
    };

    const stopPopupTabs = useMemo(() => {
        return [
            {
                label: t("popup.stopsInfo.infoTab"),
                value: "info",
            },
            {
                label: t("popup.stopsInfo.statusTab"),
                value: "status",
            },
        ];
    }, [t]);

    return (
        <Popup
            showPopup={props.showPopup}
            onClose={props.onClose}
            overlayComponent={
                stopsToDisplay.pickup.file_name ? (
                    <OrderFile filename={stopsToDisplay.pickup.file_name} />
                ) : undefined
            }
        >
            <div className="stops-info-popup">
                <div className="top">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <p className="text-xl">
                            {stopsToDisplay.pickup.order_number}
                        </p>
                        <div style={{ display: "flex", gap: 12 }}>
                            {props.onDeselectStops && (
                                <Button
                                    variant={"secondary"}
                                    leadingIcon={faSquareXmark}
                                    label={t("popup.stopsInfo.deselectStops")}
                                    onClick={() =>
                                        props.onDeselectStops?.(stopsToDisplay)
                                    }
                                />
                            )}
                            {isStopDetachable && (
                                <>
                                    {isAssignedToExternal &&
                                    props.onDetachExternalStop ? (
                                        <Button
                                            variant={"secondary"}
                                            leadingIcon={faSquareXmark}
                                            label={t(
                                                "popup.stopsInfo.detachExternalStops"
                                            )}
                                            onClick={() =>
                                                props.onDetachExternalStop?.(
                                                    stopsToDisplay
                                                )
                                            }
                                            disabled={
                                                !features?.manage_tours_fleet_planner
                                            }
                                        />
                                    ) : props.onDetachStops ? (
                                        <Button
                                            variant={"secondary"}
                                            leadingIcon={faSquareXmark}
                                            label={t(
                                                "popup.stopsInfo.detachStops"
                                            )}
                                            onClick={() =>
                                                props.onDetachStops?.(
                                                    stopsToDisplay
                                                )
                                            }
                                            disabled={
                                                !features?.manage_tours_fleet_planner
                                            }
                                        />
                                    ) : null}
                                </>
                            )}
                            {props.onEditStops && (
                                <Button
                                    style={{
                                        boxShadow:
                                            !features?.manage_tours_fleet_planner &&
                                            isStopEditable
                                                ? "none"
                                                : "var(--shadow)",
                                    }}
                                    variant={
                                        !features?.manage_tours_fleet_planner &&
                                        isStopEditable
                                            ? "disabled"
                                            : "secondary"
                                    }
                                    leadingIcon={faPen}
                                    label={t("popup.stopsInfo.editStops")}
                                    onClick={() =>
                                        props.onEditStops?.(stopsToDisplay)
                                    }
                                    disabled={
                                        !features?.manage_tours_fleet_planner &&
                                        isStopEditable &&
                                        isStopRequestAccepted
                                    }
                                />
                            )}
                        </div>
                    </div>
                    <p className="text-xs">
                        {t("popup.stopsInfo.createdAt")}
                        {": "}
                        {formatDateString(stopsToDisplay.pickup.created_at, {
                            showTime: true,
                        })}
                    </p>
                </div>

                {!!stopTabs.length && (
                    <Tabs
                        tabs={stopTabs}
                        activeTabValue={stopsToDisplay.pickup.id.toString()}
                        onTabChange={({ value }) => {
                            const foundStops = props.groupedStops?.find(
                                ({ pickup }) => pickup.id.toString() === value
                            );
                            if (foundStops) {
                                setSelectedGroupedStops(foundStops);
                            }
                        }}
                        style={{ width: "100%" }}
                        buttonStyle={{ width: "100%" }}
                    />
                )}
                <div className="tags">
                    {isAssignedFromOtherLocation && (
                        <Badge
                            title={t("popup.stopsInfo.assignedFrom", {
                                orginLocation:
                                    stopsToDisplay.pickup.origin_location?.name,
                            })}
                            variant="success"
                            icon={faShare}
                        />
                    )}
                    {stopsToDisplay.pickup.tags
                        ? stopsToDisplay.pickup.tags.map((tag) => (
                              <Badge
                                  key={tag}
                                  title={tag}
                                  variant="neutral"
                                  icon={faTag}
                              />
                          ))
                        : null}
                </div>

                <div className="content">
                    <Tabs
                        tabs={stopPopupTabs}
                        activeTabValue={selectedSection}
                        onTabChange={({ value }) =>
                            setSelectedSection(value as StopsInfoPopupSections)
                        }
                        style={{ width: "100%" }}
                        buttonStyle={{ width: "100%" }}
                    />
                    {selectedSection === "info" &&
                        sections.map((section, i) => (
                            <motion.div
                                key={i}
                                className="section"
                                variants={motionVariants}
                                initial="initial"
                                animate="enter"
                            >
                                {section.map((row) => (
                                    <div
                                        className="row"
                                        key={row.label + row.value.toString()}
                                    >
                                        <p className="label text-xs">
                                            {row.label}
                                        </p>
                                        {typeof row.value === "string" ? (
                                            <p className="value text-xs">
                                                {row.value}
                                            </p>
                                        ) : (
                                            row.value
                                        )}
                                    </div>
                                ))}
                            </motion.div>
                        ))}

                    {/* STATUS SECTION */}
                    {selectedSection === "status" &&
                        statusSections.map((section, i) => (
                            <motion.div
                                key={i}
                                className="section"
                                variants={motionVariants}
                                initial="initial"
                                animate="enter"
                            >
                                {section.map((row) => (
                                    <motion.div
                                        variants={motionVariants}
                                        className="row"
                                        key={row.label + row.value.toString()}
                                    >
                                        <p className="label text-xs">
                                            {row.label}
                                        </p>
                                        {typeof row.value === "string" ? (
                                            <p className="value text-md">
                                                {row.value}
                                            </p>
                                        ) : (
                                            row.value
                                        )}
                                    </motion.div>
                                ))}
                            </motion.div>
                        ))}
                </div>

                <div className="bottom">
                    {isAssignedFromOtherLocation && !isStopRequestAccepted && (
                        <div
                            style={{
                                display: "flex",
                                gap: 12,
                                width: "fit-content",
                            }}
                        >
                            <Button
                                variant={"secondary"}
                                label={t("popup.stopsInfo.acceptStopRequest")}
                                onClick={() =>
                                    props.onRespondToStopRequest?.({
                                        ...stopsToDisplay,
                                        response: "accept",
                                    })
                                }
                                leadingIcon={faCheck}
                                leadingIconColor="var(--color-primary-400)"
                            />
                            <Button
                                variant={"secondary"}
                                label={t("popup.stopsInfo.rejectStopRequest")}
                                onClick={() =>
                                    props.onRespondToStopRequest?.({
                                        ...stopsToDisplay,
                                        response: "reject",
                                    })
                                }
                                leadingIcon={faXmark}
                                leadingIconColor={"var(--color-red-500)"}
                            />
                        </div>
                    )}
                    {props.onSendBackToOrginLocation &&
                        canStopBeSentBackToOriginLocation && (
                            <Button
                                variant={"secondary"}
                                label={t(
                                    "fleetPlanner.sendBackToOriginLocation"
                                )}
                                style={{
                                    width: "100%",
                                }}
                                onClick={() =>
                                    props.onSendBackToOrginLocation?.(
                                        stopsToDisplay
                                    )
                                }
                            />
                        )}
                    {props.onAssignOutsideFleet &&
                        canStopBeAssignedToOutsideFleet && (
                            <Button
                                variant={"secondary"}
                                label={t("popup.stopsInfo.assignOutsideFleet")}
                                style={{
                                    width: "100%",
                                }}
                                onClick={() =>
                                    props.onAssignOutsideFleet?.(stopsToDisplay)
                                }
                                disabled={!features?.manage_tours_fleet_planner}
                            />
                        )}
                    {props.onChangedLocation &&
                        !isStopPlannedInTour &&
                        !isAssignedToOtherLocation &&
                        !isAssignedToExternal &&
                        !isAssignedFromOtherLocation && (
                            <Button
                                variant={"secondary"}
                                label={t("popup.stopsInfo.changedLocation")}
                                style={{
                                    width: "100%",
                                }}
                                onClick={() =>
                                    props.onChangedLocation?.(stopsToDisplay)
                                }
                                disabled={
                                    !features?.relocate_stop_fleet_planner
                                }
                            />
                        )}
                    {props.onFulfillExternalStop && isAssignedToExternal && (
                        <Button
                            variant={"secondary"}
                            label={t("popup.stopsInfo.fulfillExternalStop")}
                            style={{
                                width: "100%",
                            }}
                            onClick={() =>
                                props.onFulfillExternalStop?.(stopsToDisplay)
                            }
                            disabled={!features?.manage_tours_fleet_planner}
                        />
                    )}
                </div>
            </div>
        </Popup>
    );
}

export default StopsInfoPopup;
