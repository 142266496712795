import useDefaultMapCenter from "../../hooks/functionality/useDefaultMapCenter";
import useMapFitBounds from "../../hooks/functionality/useMapFitBounds";
import { StopDraftsTour } from "../../shared/types/api";
import Map from "../Map";
import TourMarkers from "../markers/TourMarkers";
import "./style.scss";

type Props = {
    tour: StopDraftsTour;
    mapId: string;
    alwaysShowMarkerPositionNumber?: boolean;
};

function TourMap(props: Props) {
    const defaultCenter = useDefaultMapCenter();

    useMapFitBounds({
        path: props.tour.stops.map((stop) => ({
            lat: +stop.lat!,
            lng: +stop.lng!,
        })),
        boundsPadding: 100,
    });

    return (
        <div className="tour-map">
            <Map
                mapId={props.mapId}
                defaultCenter={defaultCenter}
                defaultZoom={12}
                showTrafficToggle
            >
                <TourMarkers
                    tour={props.tour}
                    tourColor={{
                        primary: "var(--color-neutral-900)",
                        accent: "var(--color-neutral-50)",
                    }}
                    alwaysShowPositionNumber={
                        props.alwaysShowMarkerPositionNumber
                    }
                />
            </Map>
        </div>
    );
}

export default TourMap;
