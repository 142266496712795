import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    AdvancedMarker,
    useMap,
    useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { motion } from "framer-motion";
import { CSSProperties, useEffect, useMemo } from "react";
import MapInfoWindow from "../../../components/UI/MapInfoWindow";
import { MapPoint } from "../../../shared/types/internal";
import "./style.scss";

type Props = {
    location: google.maps.LatLngLiteral;
    linePoint?: MapPoint["linePoint"];
    onClick?: (event: google.maps.MapMouseEvent) => void;
    zIndex?: number;
    label?: string;
    icon?: IconDefinition;
    isInfoWindowOpen?: boolean;
    infoWindowData?: {
        title: string;
        description?: string;
    };
    style?: CSSProperties;
};

function MapMarker(props: Props) {
    const map = useMap();
    const maps = useMapsLibrary("maps");

    const motionVariants = {
        hidden: { opacity: 0, scale: 0.5 },
        visible: { opacity: 1, scale: 1 },
    };

    const polylinePath = useMemo(() => {
        if (!props.linePoint) return [];

        return [
            {
                lat: +props.location.lat!,
                lng: +props.location.lng!,
            },
            {
                lat: +props.linePoint.lat!,
                lng: +props.linePoint.lng!,
            },
        ];
    }, [props.linePoint, props.location.lat, props.location.lng]);

    useEffect(() => {
        if (!map || !maps) return;
        const polyline = new maps.Polyline({
            path: polylinePath,
            map,
        });

        return () => polyline.setMap(null);
    }, [map, maps, polylinePath]);

    return (
        <>
            <AdvancedMarker
                position={props.location}
                onClick={props.onClick}
                zIndex={props.isInfoWindowOpen ? 1000000000 : props.zIndex}
            >
                <motion.div
                    className="map-marker"
                    variants={motionVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    style={props.style}
                >
                    {props.icon && (
                        <FontAwesomeIcon
                            icon={props.icon}
                            color="var(--color-pure-white)"
                        />
                    )}
                    {props.label && (
                        <span className="text-xs">{props.label}</span>
                    )}
                </motion.div>
                {props.infoWindowData && props.isInfoWindowOpen && (
                    <MapInfoWindow
                        title={props.infoWindowData.title}
                        description={props.infoWindowData.description}
                    />
                )}
            </AdvancedMarker>
            {props.linePoint && (
                <AdvancedMarker
                    position={{
                        lat: +props.linePoint.lat,
                        lng: +props.linePoint.lng,
                    }}
                    zIndex={props.linePoint.zIndex}
                >
                    <motion.div
                        className="map-marker"
                        variants={motionVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                    >
                        {props.linePoint.icon && (
                            <FontAwesomeIcon
                                icon={props.linePoint.icon}
                                color="var(--color-pure-white)"
                            />
                        )}
                    </motion.div>
                </AdvancedMarker>
            )}
        </>
    );
}

export default MapMarker;
