import { AdvancedMarker } from "@vis.gl/react-google-maps";
import IconOLD from "../../../components/UI/IconOLD";
import { StopDraft } from "../../../shared/types/api";
import { STOP_DRAFT_TYPE } from "../../../shared/values/enums";
import "./style.scss";

type Props = {
    stopDraft: StopDraft;
    color?: string;
    onClick?: (id: number) => void;
    zIndex?: number;
    amountAtSameLocation?: number;
    size: "small" | "medium" | "large";
    isSelected?: boolean;
    label?: string;
};

function StopDraftMarker(props: Props) {
    if (!props.stopDraft.lat && !props.stopDraft.lng) return null;

    const stopDraftMarkerClasses = ["stop-draft-marker", props.size];

    return (
        <AdvancedMarker
            key={props.stopDraft.id}
            position={{
                lat: +props.stopDraft.lat!,
                lng: +props.stopDraft.lng!,
            }}
            onClick={() => props.onClick?.(props.stopDraft.id)}
            zIndex={props.zIndex}
        >
            <div
                className={stopDraftMarkerClasses.join(" ")}
                style={{
                    backgroundColor: props.color || undefined,
                }}
            >
                <div className="content">
                    {props.amountAtSameLocation &&
                    props.amountAtSameLocation > 1 ? (
                        <span className="amount-at-location text-sm">
                            {props.amountAtSameLocation}
                        </span>
                    ) : null}
                    {props.isSelected ? (
                        <span className="selected-check">
                            <IconOLD fontSize="16px" type="check" />
                        </span>
                    ) : null}
                    <IconOLD
                        fontSize={
                            !!props.label
                                ? "12px"
                                : props.size === "large"
                                ? "20px"
                                : "18px"
                        }
                        type={
                            props.stopDraft.stop_type_id ===
                            STOP_DRAFT_TYPE.Pickup
                                ? "plane-departure"
                                : "race-flag"
                        }
                    />
                    {props.label && (
                        <span className="text-sm">{props.label}</span>
                    )}
                </div>
            </div>
        </AdvancedMarker>
    );
}

export default StopDraftMarker;
