import StopDraftCard from "../../fleet-planner/cards/StopDraftCard";
import Popup from "../../hoc/Popup";
import { CalendarTour, StopDraft } from "../../shared/types/api";
import {
    cardHoverHandler,
    checkForGroupsAround,
} from "../../shared/utility/stop-draft";
import { GOOGLE_MAP_IDS } from "../../shared/values/google-map-ids";
import TourMap from "../TourMap";
import "./style.scss";

type Props = {
    showPopup: boolean;
    onClose: () => void;
    tour: CalendarTour;
    onStopClick: (stop: StopDraft) => void;
};

function CalendarTourMap(props: Props) {
    return (
        <Popup
            showPopup={props.showPopup}
            onClose={props.onClose}
            overlayComponent={
                <TourMap
                    tour={{
                        ...props.tour,
                        tour_id: props.tour.id,
                        orders: [],
                    }}
                    mapId={GOOGLE_MAP_IDS.CalendarTourMap}
                    alwaysShowMarkerPositionNumber
                />
            }
            dontCloseOnOutsideClick
        >
            <div className="calendar-tour-map">
                {props.tour.stops.map((stop, i) => (
                    <StopDraftCard
                        key={stop.id}
                        stopDraft={stop}
                        index={i}
                        showPosition
                        onHover={(gId) =>
                            cardHoverHandler({
                                groupId: gId,
                                columnSelector: ".calendar-tour-map",
                            })
                        }
                        hasGroupsAround={checkForGroupsAround({
                            stop,
                            stopAbove: props.tour.stops[i - 1],
                            stopBelow: props.tour.stops[i + 1],
                        })}
                        onClick={() => props.onStopClick(stop)}
                    />
                ))}
            </div>
        </Popup>
    );
}

export default CalendarTourMap;
