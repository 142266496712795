import { getLocale } from "../shared/utility/misc";
import * as Sentry from "@sentry/react";

export async function getGoogleAutoComplete(
    searchText: string,
    country?: string
) {
    return new Promise<google.maps.places.AutocompletePrediction[] | null>(
        async (resolve) => {
            try {
                const addressPromise =
                    new google.maps.places.AutocompleteService().getPlacePredictions(
                        {
                            input: searchText,
                            componentRestrictions: { country: country || "se" },
                            types: ["address"],
                            language: getLocale() || "en",
                        }
                    );

                const establishmentPromise =
                    new google.maps.places.AutocompleteService().getPlacePredictions(
                        {
                            input: searchText,
                            componentRestrictions: { country: country || "se" },
                            types: ["establishment"],
                            language: getLocale() || "en",
                        }
                    );

                const [adresses, establishments] = await Promise.all([
                    addressPromise,
                    establishmentPromise,
                ]);

                resolve([
                    ...adresses.predictions,
                    ...establishments.predictions,
                ]);
            } catch (error) {
                Sentry.captureException(error);
                resolve(null);
            }
        }
    );
}

export async function getLocationFromAddress(address: string) {
    try {
        const result = await new google.maps.Geocoder().geocode({
            address: address,
            language: getLocale() || "en",
        });

        return {
            location: result.results[0].geometry.location,
            locationType: result.results[0].geometry.location_type,
        };
    } catch (error) {
        return {
            location: null,
            locationType: null,
        };
    }
}

export async function getAddressFromLocation(
    location: google.maps.LatLngLiteral
) {
    try {
        const result = await new google.maps.Geocoder().geocode({
            location: location,
            language: getLocale() || "en",
        });

        return {
            address: result.results[0].formatted_address,
            locationType: result.results[0].geometry.location_type,
        };
    } catch (error) {
        return {
            address: null,
            locationType: null,
        };
    }
}
