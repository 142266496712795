import { addMinutes } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import IconOLD from "../../components/UI/IconOLD";
import { CalendarStopDraft } from "../../shared/types/api";
import {
    dateToString,
    formatMinutes,
    stringToDate,
} from "../../shared/utility/date";
import { getStopListDurationInMinutes } from "../../shared/utility/stop-draft";
import { STOP_DRAFT_TYPE } from "../../shared/values/enums";
import "./style.scss";
import TextButton from "../../components/buttons/TextButton";

type Props = {
    title: string;
    stops: CalendarStopDraft[];
    tourId?: number;
    theme?: "unhandled" | "outside-fleet";
    hideEta?: boolean;
    onStopClick?: (stops: {
        pickup: CalendarStopDraft;
        dropoff: CalendarStopDraft;
    }) => void;
    onTourIdClick?: (tourId: number) => void;
};

function CalendarTable(props: Props) {
    const { t } = useTranslation();

    const calendarTableClasses = ["calendar-table"];
    if (props.theme) {
        calendarTableClasses.push(props.theme);
    }

    const stopList = useMemo(() => {
        const result: {
            pickup: CalendarStopDraft;
            dropoff: CalendarStopDraft;
        }[] = [];

        const dropoffs = props.stops.filter(
            (stop) => stop.stop_type_id === STOP_DRAFT_TYPE.Dropoff
        );

        for (let i = 0; i < dropoffs.length; i++) {
            const dropoff = dropoffs[i];

            const pickup = props.stops.find(
                (stop) =>
                    stop.stop_type_id === STOP_DRAFT_TYPE.Pickup &&
                    stop.group_id === dropoff.group_id
            );

            if (!pickup) continue;

            result.push({ pickup, dropoff });
        }

        return result;
    }, [props.stops]);

    const isTourCompleted = useMemo(() => {
        return props.stops.every((stop) => stop.status === "done");
    }, [props.stops]);

    const isTourArchived = useMemo(() => {
        return props.stops.every((stop) => stop.status === "archived");
    }, [props.stops]);

    const hasTourBeenStarted = useMemo(() => {
        return props.stops.some(
            (stop) => stop.status !== "unhandled" && stop.status !== "assigned"
        );
    }, [props.stops]);

    const endTime = useMemo(() => {
        const lastStop = props.stops[props.stops.length - 1];

        if (!lastStop || !lastStop.eta) return;

        const lastStopDate = stringToDate(lastStop.eta);

        if (!lastStopDate) return;

        return dateToString(
            addMinutes(lastStopDate, lastStop.estimated_offload_time),
            {
                onlyTime: true,
            }
        );
    }, [props.stops]);

    const tourDuration = useMemo(
        () => getStopListDurationInMinutes(props.stops),
        [props.stops]
    );

    const tourStatusText = useMemo(() => {
        if (isTourCompleted) {
            return t("fleetPlanner.tourCompleted");
        }

        if (isTourArchived) {
            return t("fleetPlanner.tourArchived");
        }

        if (hasTourBeenStarted) {
            return t("fleetPlanner.tourOngoing");
        }

        return t("fleetPlanner.tourNotStarted");
    }, [hasTourBeenStarted, isTourArchived, isTourCompleted, t]);

    return (
        <div className={calendarTableClasses.join(" ")}>
            <div
                className="top"
                style={{
                    backgroundColor: isTourCompleted
                        ? "var(--text-color-alt)"
                        : isTourArchived
                        ? "var(--error-color)"
                        : hasTourBeenStarted
                        ? "var(--color-violet-900)"
                        : undefined,

                    color:
                        hasTourBeenStarted && !isTourCompleted
                            ? "var(--text-color-lighter)"
                            : undefined,
                }}
            >
                <section>
                    <p className="text-sm">{props.title} </p>
                    {props.tourId &&
                        (props.onTourIdClick ? (
                            <TextButton
                                variant="link"
                                label={`(${t("fleetPlanner.tourId")} ${
                                    props.tourId
                                })`}
                                onClick={() => {
                                    if (!props.onTourIdClick || !props.tourId)
                                        return;
                                    props.onTourIdClick(props.tourId);
                                }}
                                labelStyle={{
                                    textDecoration: "underline",
                                }}
                            />
                        ) : (
                            <p>{`(${t("fleetPlanner.tourId")} ${
                                props.tourId
                            })`}</p>
                        ))}
                    <p>
                        {stopList.length}{" "}
                        {t("fleetPlanner.delivery", {
                            count: stopList.length,
                        })}
                    </p>
                    {props.theme !== "unhandled" &&
                        props.theme !== "outside-fleet" && (
                            <p className="text-sm">
                                {t("fleetPlanner.tourStatus")}
                                {": "}
                                {tourStatusText}
                            </p>
                        )}
                </section>
                <section>
                    <p>
                        {t("fleetPlanner.estimatedTime")}{" "}
                        {formatMinutes(tourDuration)}
                    </p>
                    {!props.hideEta &&
                        hasTourBeenStarted &&
                        !isTourArchived &&
                        !isTourCompleted && (
                            <p>
                                {t("fleetPlanner.endTime")} {endTime}
                            </p>
                        )}
                </section>
            </div>
            <table>
                <thead>
                    <tr className="text-sm">
                        <th>{t("orderHistory.orderNumber")}</th>
                        <th>{t("createTour.contact")}</th>
                        <th>{t("orderHistory.dropOff")}</th>
                    </tr>
                </thead>
                <tbody>
                    {stopList.map((stops) => (
                        <tr key={stops.pickup.id}>
                            <td>
                                <div className="order-number">
                                    {stops.pickup.status === "done" &&
                                        stops.dropoff.status === "done" && (
                                            <IconOLD
                                                type="check"
                                                style={{
                                                    color: "var(--text-color-alt)",
                                                }}
                                            />
                                        )}
                                    <button
                                        type="button"
                                        title={stops.pickup.order_number}
                                        onClick={() =>
                                            props.onStopClick?.(stops)
                                        }
                                    >
                                        {stops.pickup.order_number}
                                    </button>
                                    {stops.pickup.color && (
                                        <div
                                            className="circle"
                                            title={t(
                                                "fleetPlanner.colorPickup"
                                            )}
                                            style={{
                                                backgroundColor:
                                                    stops.pickup.color,
                                            }}
                                        />
                                    )}
                                    {stops.dropoff.color && (
                                        <div
                                            className="circle"
                                            title={t(
                                                "fleetPlanner.colorDropoff"
                                            )}
                                            style={{
                                                backgroundColor:
                                                    stops.dropoff.color,
                                            }}
                                        />
                                    )}
                                </div>
                            </td>
                            <td>
                                <div
                                    className="contact"
                                    title={
                                        stops.dropoff.contact?.name ||
                                        stops.pickup.contact?.name ||
                                        undefined
                                    }
                                >
                                    {stops.dropoff.contact?.name ||
                                        stops.pickup.contact?.name ||
                                        "-"}
                                </div>
                            </td>
                            <td>
                                <div
                                    className="address"
                                    title={stops.dropoff.to_location}
                                >
                                    {stops.dropoff.to_location}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default CalendarTable;
