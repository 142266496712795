import { faCog } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/buttons/Button";
import Switch from "../../../components/inputs/Switch";
import Modal from "../../../hoc/Modal";
import useUserFeatures from "../../../hooks/functionality/useUserFeatures";
import { Driver } from "../../../shared/types/api";
import { FleetPlannerSettings } from "../../../shared/types/redux";
import { getDriverDisplayName } from "../../../shared/utility/misc";
import "./style.scss";

type Props = {
    state: FleetPlannerSettings;
    onChange: (key: keyof Props["state"], value: boolean) => void;
    onChangeDrivers: (driverId: string, value: boolean) => void;
    drivers?: Driver[];
    onBulkDelete: (type: "stop" | "order-draft") => void;
    isBulkDeletingStops: boolean;
    isBulkDeletingOrderDrafts: boolean;
};

function BigVolumeSettingsModal(props: Props) {
    const { t } = useTranslation();
    const features = useUserFeatures();
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <Modal
            buttonElement={(ref) => (
                <Button
                    ref={ref}
                    variant={"secondary"}
                    label={t("bigVolume.settings")}
                    leadingIcon={faCog}
                    onClick={() => setIsModalOpen(true)}
                />
            )}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            align={"right"}
            width="300px"
        >
            <div className="big-volume-settings-modal">
                <section>
                    <span className="text-lg">
                        {t("activeFleet.settingsTitle")}
                    </span>
                </section>
                <section>
                    <span>
                        {t("fleetPlanner.filterUnhandledByDateSettingsTitle")}
                    </span>
                    <Switch
                        isActive={!!props.state.filterUnhandledByDate}
                        onChange={(value) =>
                            props.onChange("filterUnhandledByDate", value)
                        }
                    />
                </section>
                <section>
                    <span className="text-lg">
                        {t("activeFleet.bulkDeleteTitle")}
                    </span>
                </section>
                <section>
                    <Button
                        variant={"secondary"}
                        label={t("activeFleet.bulkDeleteStops")}
                        onClick={() => props.onBulkDelete("stop")}
                        isLoading={props.isBulkDeletingStops}
                        style={{ width: "100%" }}
                        disabled={!features?.delete_stops_fleet_planner}
                    />
                </section>
                <section>
                    <Button
                        variant={"secondary"}
                        label={t("activeFleet.bulkDeleteOrderDrafts")}
                        onClick={() => props.onBulkDelete("order-draft")}
                        isLoading={props.isBulkDeletingOrderDrafts}
                        style={{ width: "100%" }}
                    />
                </section>
                <section>
                    <span className="text-lg">
                        {t("activeFleet.hideDriversTitle")}
                    </span>
                </section>
                {props.drivers?.map((driver) => (
                    <section key={driver.id}>
                        <span>{getDriverDisplayName(driver)}</span>
                        <Switch
                            isActive={
                                !props.state.hiddenDriverIds?.[
                                    driver.mt_driver_id || ""
                                ]
                            }
                            onChange={(value) =>
                                props.onChangeDrivers(
                                    driver.mt_driver_id || "",
                                    value
                                )
                            }
                        />
                    </section>
                ))}
            </div>
        </Modal>
    );
}

export default BigVolumeSettingsModal;
