import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HTMLInputAutoCompleteAttribute } from "react";
import "./style.scss";
import Spinner from "../../UI/Spinner";

type Props = {
    type: "text" | "email" | "password" | "tel" | "number" | "time";
    value: string;
    id?: string;
    name?: string;
    onChange: (value: string) => void;
    onClick?: () => void;
    placeholder?: string | null;
    label?: string;
    leadingIcon?: IconDefinition;
    trailingIcon?: IconDefinition;
    style?: React.CSSProperties;
    wrapperStyle?: React.CSSProperties;
    disabled?: boolean;
    invalid?: boolean;
    completed?: boolean;
    isLoading?: boolean;
    autoComplete?: HTMLInputAutoCompleteAttribute;
    labelColor?: string;
};

function Input(props: Props) {
    const inputClasses = ["input"];

    if (props.disabled) {
        inputClasses.push("disabled");
    }

    if (props.invalid) {
        inputClasses.push("invalid");
    }

    if (props.completed) {
        inputClasses.push("completed");
    }

    return (
        <label
            className={inputClasses.join(" ")}
            id={props.id}
            style={props.style}
        >
            {/* Label above the input */}
            {props.label && (
                <p
                    className="label text-xs"
                    style={{ color: props.labelColor }}
                >
                    {props.label}
                </p>
            )}

            {/* Wrapper around the input */}
            <div className="input-wrapper" style={props.wrapperStyle}>
                {props.leadingIcon && (
                    <FontAwesomeIcon
                        icon={props.leadingIcon}
                        size="lg"
                        color="var(--color-neutral-400)"
                    />
                )}
                <input
                    type={props.type}
                    value={props.value}
                    onChange={(event) => props.onChange(event.target.value)}
                    onClick={props.onClick}
                    placeholder={props.placeholder || undefined}
                    className="text-xs"
                    disabled={props.disabled}
                    autoComplete={props.autoComplete}
                    name={props.name}
                />
                {(props.trailingIcon || props.isLoading) &&
                    (props.isLoading ? (
                        <Spinner width="15px" />
                    ) : props.trailingIcon ? (
                        <FontAwesomeIcon
                            icon={props.trailingIcon}
                            size="lg"
                            color="var(--color-neutral-400)"
                        />
                    ) : null)}
            </div>
        </label>
    );
}

export default Input;
