import { faFlagCheckered, faTruck } from "@fortawesome/pro-solid-svg-icons";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Button from "../../components/buttons/Button";
import TextButton from "../../components/buttons/TextButton";
import usePanToMapCenter from "../../hooks/functionality/usePanToMapCenter";
import { Driver } from "../../shared/types/api";
import { MapPoint } from "../../shared/types/internal";
import { getDriverDisplayName } from "../../shared/utility/misc";
import { GOOGLE_MAP_IDS } from "../../shared/values/google-map-ids";
import PointsMap from "../PointsMap";
import "./style.scss";

type Props = {
    drivers: Driver[];
    onUpdateDrivers: () => void;
    isUpdatingDrivers: boolean;
};

function DriversMap(props: Props) {
    const { t } = useTranslation();
    const [mapCenter, setMapCenter] = useState<google.maps.LatLng | undefined>(
        undefined
    );
    const [focusedPointId, setFocusedPointId] = useState<string | null>(null);

    usePanToMapCenter(mapCenter || undefined);

    const activeDrivers = useMemo(
        () =>
            props.drivers.filter(
                (driver) => driver.current_lat && driver.current_lng
            ),
        [props.drivers]
    );
    const inactiveDrivers = useMemo(
        () =>
            props.drivers.filter(
                (driver) => !driver.current_lat || !driver.current_lng
            ),
        [props.drivers]
    );

    const driverPoints = useMemo(() => {
        const points: MapPoint[] = [];

        for (let i = 0; i < activeDrivers.length; i++) {
            const driver = activeDrivers[i];

            points.push({
                id: driver.id.toString(),
                location: {
                    lat: +driver.current_lat!,
                    lng: +driver.current_lng!,
                },
                linePoint:
                    driver.next_lat && driver.next_lng
                        ? {
                              lat: +driver.next_lat,
                              lng: +driver.next_lng,
                              icon: faFlagCheckered,
                              zIndex: i,
                          }
                        : undefined,
                name: t("bigVolume.driverInfoTitle"),
                description: getDriverDisplayName(driver),
                icon: faTruck,
                zIndex: i * 2,
            });
        }

        return points;
    }, [activeDrivers, t]);

    return (
        <div className="drivers-map">
            <PointsMap
                mapId={GOOGLE_MAP_IDS.DriversMap}
                points={driverPoints}
                showDirections={false}
                style={{
                    width: "100%",
                }}
                center={mapCenter}
                onPointClick={(point) => {
                    setFocusedPointId(point ? point.id : null);
                }}
                focusedPointId={focusedPointId || undefined}
            />
            <div className="drivers-list">
                <div className="controls">
                    <Button
                        label={t("drivers.updateMap")}
                        variant="secondary"
                        onClick={props.onUpdateDrivers}
                        isLoading={props.isUpdatingDrivers}
                    />
                </div>
                {!!activeDrivers.length && (
                    <section>
                        <p>{t("drivers.activeDrivers")}</p>
                        {activeDrivers.map((driver) => (
                            <TextButton
                                key={driver.id}
                                label={getDriverDisplayName(driver)}
                                variant="secondary"
                                onClick={() =>
                                    setMapCenter(
                                        new google.maps.LatLng(
                                            +driver.current_lat!,
                                            +driver.current_lng!
                                        )
                                    )
                                }
                            />
                        ))}
                    </section>
                )}
                {!!inactiveDrivers.length && (
                    <section>
                        <p>{t("drivers.inactiveDrivers")}</p>
                        {inactiveDrivers.map((driver) => (
                            <TextButton
                                key={driver.id}
                                label={getDriverDisplayName(driver)}
                                variant="secondary"
                                onClick={() =>
                                    toast.error(
                                        t("errorMessage.inactiveDriver")
                                    )
                                }
                            />
                        ))}
                    </section>
                )}
            </div>
        </div>
    );
}

export default DriversMap;
