import { AxiosRequestConfig } from "axios";
import axios from "../shared/services/axios";
import {
    CreateOrderData,
    ExternalCarrier,
    ExternalCarrierOrder,
    MultiPriceData,
    Order,
    OrderOptions,
    PricingData,
    PublicSharedOrder,
} from "../shared/types/api";
import { API_MAX_PER_PAGE } from "../shared/values/enums";

export function getActiveOrders({
    page,
    size = API_MAX_PER_PAGE.Orders,
    created_from_fleet_planner,
}: {
    page: number;
    size?: number;
    created_from_fleet_planner?: boolean;
}) {
    return axios.get<{
        items: Order[];
        total: number;
        page: number;
        size: number;
        pages: number;
    }>("/v3/orders/active", {
        params: {
            page,
            size,
            created_from_fleet_planner,
        },
    });
}

export function getFulfilledOrders({
    searchType,
    searchString,
    page,
    size = API_MAX_PER_PAGE.Orders,
}: {
    searchType?: string;
    searchString?: string;
    page: number;
    size?: number;
}) {
    const params: AxiosRequestConfig["params"] = { page, size };

    if (searchType && searchString) {
        params[searchType] = searchString;
    }

    return axios.get<{
        items: Order[];
        total: number;
        page: number;
        size: number;
        pages: number;
    }>("/v3/orders/fulfilled", {
        params: params,
    });
}

export function getOrderOptions() {
    return axios.get<OrderOptions>("/options");
}

export function getPricings({
    fromAddress,
    toAddress,
    weightKg,
    numberOfPallets,
    earliestPickup,
    latestDelivery,
}: {
    fromAddress: string;
    toAddress: string;
    weightKg: number;
    numberOfPallets?: number;
    earliestPickup?: string;
    latestDelivery?: string;
}) {
    return axios.get<MultiPriceData>("/v4/pricings", {
        params: {
            from_address: fromAddress,
            to_address: toAddress,
            weight_kg: weightKg,
            number_of_pallets: numberOfPallets,
            earliest_pickup: earliestPickup,
            latest_delivery: latestDelivery,
        },
    });
}

export function getOrderPrice({
    vehicle,
    fromAddress,
    toAddress,
    weightKg,
    numberOfPallets,
    earliestPickup,
    latestDelivery,
    pricingHash,
    calculateFrom,
}: {
    vehicle: string;
    fromAddress: string;
    toAddress: string;
    weightKg: number;
    numberOfPallets?: number;
    earliestPickup: string;
    latestDelivery: string;
    pricingHash: string;
    calculateFrom: string;
}) {
    return axios.get<PricingData>("/v4/pricing", {
        params: {
            vehicle: vehicle || undefined,
            from_address: fromAddress,
            to_address: toAddress,
            weight_kg: weightKg,
            number_of_pallets: numberOfPallets,
            earliest_pickup: earliestPickup,
            latest_delivery: latestDelivery,
            pricing_hash: pricingHash,
            calculate_from: calculateFrom,
        },
    });
}

export function createOrder(data: CreateOrderData) {
    return axios.post<{ status: string; id: number }>("/v3/order", data);
}

export function shareOrderEmail({
    orderId,
    email,
}: {
    orderId: number;
    email: string;
}) {
    return axios.post<{
        status: string;
        public_id: string;
    }>("/order/share/public", {
        order_id: orderId,
        email,
    });
}

export function shareOrderPublic(orderId: number) {
    return axios.post<{
        status: string;
        public_id: string;
    }>("/order/share/public_id", {
        order_id: orderId,
    });
}

export function getPublicOrder(publicId: string) {
    return axios.get<PublicSharedOrder>("/order/public", {
        params: {
            public_id: publicId,
        },
    });
}

export function validateAddress(address: string) {
    return axios.get<{
        origin_address: string;
        lat: number;
        lng: number;
        mt_response_code: number;
        formatted_address: string;
        street_name: string;
        street_number?: null;
        zip_code?: null;
        city: string;
    }>("/validate_address", {
        params: {
            incoming_address: address,
        },
    });
}

export function getExternalCarrierOrder(key: string) {
    return axios.get<{
        customer_phone: string | null;
        accepted_at: string | null;
        declined_at: string | null;
        reference: string | null;
        comment: string | null;
        external_carrier: ExternalCarrier;
        order: ExternalCarrierOrder;
    }>("/order/external/request", {
        params: {
            key: key,
        },
    });
}

export function updateExternalCarrierOrder(
    key: string,
    accepted: boolean,
    comment?: string
) {
    return axios.put<string>("/order/external/request", {
        key: key,
        accepted: accepted,
        comment: comment,
    });
}

export function setExternalCarrierForOrder({
    orderId,
    externalCarrierId,
    reference,
    date,
    time,
}: {
    orderId: number;
    externalCarrierId: number;
    reference?: string;
    date: string;
    time: string;
}) {
    return axios.post<string>("/order/external/request", {
        order_id: orderId,
        external_carrier_id: externalCarrierId,
        reference: reference,
        date: date,
        time: time,
    });
}
