import { motion } from "framer-motion";
import "./style.scss";

type Props = {
    title: string;
    description?: string;
    actions?: {
        onClick: (event: React.MouseEvent) => void;
        label: string;
        hide?: boolean;
    }[];
};

function MapInfoWindow(props: Props) {
    const motionVariants = {
        initial: { opacity: 0, y: 10 },
        enter: { opacity: 1, y: 0 },
    };
    return (
        <motion.div
            className="map-info-window"
            variants={motionVariants}
            initial="initial"
            animate="enter"
        >
            <div className="top">
                <p
                    className="text-2xs"
                    style={{
                        color: "var(--color-neutral-600)",
                    }}
                >
                    {props.title}
                </p>
                {props.description && (
                    <p className="text-xs">{props.description}</p>
                )}
            </div>
            {props.actions &&
                props.actions
                    .filter((a) => !a.hide)
                    .map((action, i) => (
                        <button
                            key={action.label}
                            type="button"
                            className="subtle-button"
                            onClick={action.onClick}
                        >
                            {action.label}
                        </button>
                    ))}
        </motion.div>
    );
}

export default MapInfoWindow;
