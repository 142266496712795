import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { ZodError } from "zod";
import { shareOrderEmail, shareOrderPublic } from "../../api/order";
import IconOLD from "../../components/UI/IconOLD";
import Button from "../../components/buttons/Button";
import IconButton from "../../components/buttons/IconButton";
import TextButton from "../../components/buttons/TextButton";
import Input from "../../components/inputs/Input";
import OrderDetails from "../../components/widgets/OrderDetails";
import OrderStatusTimeline from "../../components/widgets/OrderStatusTimeline";
import OrderFile from "../../containers/OrderFile";
import Popup from "../../hoc/Popup";
import { ShareOrderForm, shareOrderForm } from "../../schemas/form";
import { Order } from "../../shared/types/api";
import { IconType } from "../../shared/types/internal";
import { formatDateString, formatDuration } from "../../shared/utility/date";
import {
    convertToNumberWithSpaces,
    onFormError,
} from "../../shared/utility/misc";
import {
    copyPublicOrderUrl,
    getOrderDurations,
    getOrderStatus,
} from "../../shared/utility/order";
import { STOP_DRAFT_TYPE } from "../../shared/values/enums";
import "./style.scss";

type Props = {
    order: Order | null;
    showPopup: boolean;
    onClose: () => void;
};

function OrderInfoPopup(props: Props) {
    const { t } = useTranslation();

    const {
        control,
        handleSubmit,
        reset: resetInputs,
    } = useForm<ShareOrderForm>({
        resolver: zodResolver(shareOrderForm),
        defaultValues: {
            email: "",
        },
    });

    const [isShowingMore, setIsShowingMore] = useState(false);
    const [hasCopiedShareLink, setHasCopiedShareLink] = useState(false);

    useEffect(() => {
        if (!props.showPopup) {
            setIsShowingMore(false);
            setHasCopiedShareLink(false);
        }
    }, [props.showPopup]);

    const { mutate: shareOrderHandler, isPending: isSharing } = useMutation({
        mutationFn: async ({
            orderId,
            data: { email },
        }: {
            orderId: number;
            data: ShareOrderForm;
        }) => {
            await shareOrderEmail({
                orderId,
                email,
            });
        },
        onSuccess: () => {
            toast.success(t("successMessage.orderShared"));
            resetInputs();
        },
        onError: () => {
            toast.error(t("errorMessage.shareOrderError"));
        },
    });

    const { mutate: copyOrderLinkHandler, isPending: isCopying } = useMutation({
        mutationFn: async (orderId: number) => {
            const { data } = await shareOrderPublic(orderId);

            await copyPublicOrderUrl(data.public_id);
        },
        onSuccess: () => {
            toast.success(t("successMessage.orderUrlCopy"));
            setHasCopiedShareLink(true);
        },
        onError: (error) => {
            if (error instanceof ZodError) {
                toast.error(t(error.issues[0].message));
                return;
            }
            toast.error(t("errorMessage.orderUrlCopy"));
        },
    });

    const proofOfDeliveries = useMemo<{
        pickup: string[];
        dropoff: string[];
    } | null>(() => {
        if (!props.order?.proof_of_deliveries) return null;
        const pickup: string[] = [];
        const dropoff: string[] = [];
        for (let i = 0; i < props.order.proof_of_deliveries.length; i++) {
            const pod = props.order.proof_of_deliveries[i];
            if (pod.stop_type_id === STOP_DRAFT_TYPE.Pickup)
                pickup.push(pod.url);
            else if (pod.stop_type_id === STOP_DRAFT_TYPE.Dropoff)
                dropoff.push(pod.url);
        }

        return {
            pickup,
            dropoff,
        };
    }, [props.order]);

    const downloadProofOfDeliveries = useCallback(
        async (podUrls: string[], type: "pickup" | "dropoff") => {
            for (let i = 0; i < podUrls.length; i++) {
                const res = await axios.get(podUrls[i], {
                    responseType: "blob",
                });

                const url = window.URL.createObjectURL(new Blob([res.data]));

                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    `proof-of-delivery-${
                        props.order?.origin_order_id
                    }-${type}-${i + 1}.jpg`
                );
                link.click();
            }
        },
        [props.order]
    );

    const orderStatus = props.order && getOrderStatus(props.order);

    const orderDurations = getOrderDurations(props.order);

    //TODO use OrderDetails component

    return (
        <Popup
            showPopup={props.showPopup}
            onClose={props.onClose}
            title={t("popup.orderInfo.title")}
            id="order-info-popup"
            dontCloseOnOutsideClick={!!props.order?.file_name}
            overlayComponent={
                props.order?.file_name ? (
                    <OrderFile filename={props.order.file_name} />
                ) : undefined
            }
        >
            {props.order && ![1].includes(1) && (
                <OrderDetails
                    order={props.order}
                    getSections={(order) => [
                        [
                            {
                                title: t("popup.orderInfo.weightLabel"),
                                value: order.weight_kg
                                    ? order.weight_kg + " kg"
                                    : undefined,
                            },
                        ],
                        [
                            {
                                title: t(
                                    "popup.confirmOrder.numberOfPackagesLabel"
                                ),
                                value: order.additional_info
                                    ?.number_of_eu_pallets,
                            },
                        ],
                        [
                            {
                                title: t(
                                    "popup.confirmOrder.weightOfHeaviestPackageLabel"
                                ),
                                value: order.weight_of_heaviest_package,
                            },
                        ],
                        [
                            {
                                title: t("popup.orderInfo.vehicleLabel"),
                                valueComponent: order.vehicle?.friendlyname ? (
                                    <>
                                        <IconOLD
                                            type={
                                                order.vehicle_type as IconType
                                            }
                                            style={{
                                                color: "var(--text-color-light)",
                                                marginRight: "5px",
                                            }}
                                        />{" "}
                                        {order.vehicle?.friendlyname}
                                    </>
                                ) : undefined,
                            },
                        ],
                        [
                            {
                                title: t(
                                    "popup.confirmOrder.earliestPickupLabel"
                                ),
                                value: order.earliest_pickup
                                    ? formatDateString(order.earliest_pickup, {
                                          showTime: true,
                                      })
                                    : undefined,
                            },
                        ],
                        [
                            {
                                title: t(
                                    "popup.confirmOrder.latestDeliveryLabel"
                                ),
                                value: order.latest_delivery
                                    ? formatDateString(order.latest_delivery, {
                                          showTime: true,
                                      })
                                    : undefined,
                            },
                        ],
                        [
                            {
                                title: t(
                                    "createOrder.additionalVehicleTypeLabel"
                                ),
                                value:
                                    order.vehicle?.car_name === "large-van"
                                        ? t(
                                              order.additional_info
                                                  ?.side_loading
                                                  ? "general.yes"
                                                  : "general.no"
                                          )
                                        : undefined,
                            },
                        ],
                        [
                            {
                                title: t("popup.orderInfo.deliveryLabel"),
                                value:
                                    order.delivery &&
                                    !order.earliest_pickup &&
                                    !order.latest_delivery
                                        ? order.delivery.friendlyname
                                        : undefined,
                            },
                        ],
                        [
                            {
                                title: t("popup.orderInfo.fromLabel"),
                                value: order.pickup_address,
                            },
                        ],
                        [
                            {
                                title: t("popup.orderInfo.toLabel"),
                                value: order.dropoff_address,
                            },
                        ],
                        [
                            {
                                title: t("popup.orderInfo.cargoContentLabel"),
                                value: order.cargo_content,
                            },
                        ],
                        [
                            {
                                title: t("popup.orderInfo.priceLabel"),
                                value: order.total_price
                                    ? convertToNumberWithSpaces(
                                          order.total_price,
                                          "SEK"
                                      )
                                    : undefined,
                            },
                        ],
                        [
                            {
                                title: t("popup.orderInfo.orderNumberLabel"),
                                value: order.origin_order_id,
                            },
                        ],
                        [
                            {
                                title: t("popup.orderInfo.contactPhoneLabel"),
                                value: order.dropoff_contact_phone,
                            },
                        ],
                        [
                            {
                                title: t(
                                    "popup.orderInfo.pickupDriverNoteLabel"
                                ),
                                value: order.pickup_driver_note,
                            },
                            {
                                title: t(
                                    "popup.orderInfo.dropoffDriverNoteLabel"
                                ),
                                value: order.dropoff_driver_note,
                            },
                        ],
                        [
                            {
                                title: t(
                                    "popup.orderInfo.pickupContactNameLabel"
                                ),
                                value: order.pickup_contact?.name,
                            },
                            {
                                title: t(
                                    "popup.orderInfo.dropoffContactNameLabel"
                                ),
                                value: order.dropoff_contact?.name,
                            },
                        ],
                        [
                            {
                                title: t("popup.orderInfo.loadingTimeLabel"),
                                value: orderDurations.loadingTime
                                    ? formatDuration(orderDurations.loadingTime)
                                    : undefined,
                            },
                            {
                                title: t("popup.orderInfo.drivingTimeLabel"),
                                value: orderDurations.drivingTime
                                    ? formatDuration(orderDurations.drivingTime)
                                    : undefined,
                            },
                            {
                                title: t("popup.orderInfo.offloadingTimeLabel"),
                                value: orderDurations.offloading
                                    ? formatDuration(orderDurations.offloading)
                                    : undefined,
                            },
                        ],
                        [
                            {
                                title: t("popup.orderInfo.totalCO2"),
                                value: t("popup.orderInfo.comingSoon"),
                                valueStyle: {
                                    color: "var(--text-color-alt)",
                                },
                            },
                        ],
                        [
                            {
                                title: t(
                                    "popup.orderInfo.proofOfDeliveryLabel"
                                ),
                                valueComponent:
                                    order.proof_of_delivery?.length &&
                                    !proofOfDeliveries ? (
                                        <>
                                            {order.proof_of_delivery.map(
                                                (pod) => (
                                                    <a
                                                        key={pod.url}
                                                        href={pod.url}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="pod-link"
                                                    >
                                                        <img
                                                            src={pod.url}
                                                            alt={t(
                                                                "popup.orderInfo.proofOfDeliveryLabel"
                                                            )}
                                                        />
                                                    </a>
                                                )
                                            )}
                                        </>
                                    ) : undefined,
                            },
                        ],
                        [
                            {
                                title: t(
                                    "popup.orderInfo.pickupProofOfDeliveryLabel"
                                ),
                                valueComponent: proofOfDeliveries?.pickup
                                    .length ? (
                                    <>
                                        {proofOfDeliveries.pickup.map(
                                            (prodUrl) => (
                                                <a
                                                    key={prodUrl}
                                                    href={prodUrl}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="pod-link"
                                                >
                                                    <img
                                                        src={prodUrl}
                                                        alt={t(
                                                            "popup.orderInfo.proofOfDeliveryLabel"
                                                        )}
                                                    />
                                                </a>
                                            )
                                        )}
                                        <IconButton
                                            icon="file-download"
                                            label={t(
                                                "popup.orderInfo.downloadPodsLabel"
                                            )}
                                            onClick={() => {
                                                downloadProofOfDeliveries(
                                                    proofOfDeliveries.pickup,
                                                    "pickup"
                                                );
                                            }}
                                        />
                                    </>
                                ) : undefined,
                            },
                        ],
                        [
                            {
                                title: t(
                                    "popup.orderInfo.dropoffProofOfDeliveryLabel"
                                ),
                                valueComponent: proofOfDeliveries?.dropoff
                                    .length ? (
                                    <>
                                        {proofOfDeliveries.dropoff.map(
                                            (prodUrl) => (
                                                <a
                                                    key={prodUrl}
                                                    href={prodUrl}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="pod-link"
                                                >
                                                    <img
                                                        src={prodUrl}
                                                        alt={t(
                                                            "popup.orderInfo.proofOfDeliveryLabel"
                                                        )}
                                                    />
                                                </a>
                                            )
                                        )}
                                        <IconButton
                                            icon="file-download"
                                            label={t(
                                                "popup.orderInfo.downloadPodsLabel"
                                            )}
                                            onClick={() => {
                                                downloadProofOfDeliveries(
                                                    proofOfDeliveries.dropoff,
                                                    "dropoff"
                                                );
                                            }}
                                        />
                                    </>
                                ) : undefined,
                            },
                        ],
                    ]}
                />
            )}
            {props.order && (
                <div className="order-info-popup">
                    <OrderStatusTimeline
                        activeStatus={orderStatus!}
                        order={props.order}
                    />
                    {props.order.weight_kg && (
                        <section>
                            <div className="info">
                                <p className="title text-xs">
                                    {t("popup.orderInfo.weightLabel")}
                                </p>
                                <p className="value text-sm">
                                    {props.order.weight_kg} kg
                                </p>
                            </div>
                        </section>
                    )}
                    {props.order.additional_info?.number_of_eu_pallets && (
                        <section>
                            <div className="info">
                                <p className="title text-xs">
                                    {t(
                                        "popup.confirmOrder.numberOfPackagesLabel"
                                    )}
                                </p>
                                <p className="value text-sm">
                                    {
                                        props.order.additional_info
                                            .number_of_eu_pallets
                                    }
                                </p>
                            </div>
                        </section>
                    )}
                    {props.order.weight_of_heaviest_package && (
                        <section>
                            <div className="info">
                                <p className="title text-xs">
                                    {t(
                                        "popup.confirmOrder.weightOfHeaviestPackageLabel"
                                    )}
                                </p>
                                <p className="value text-sm">
                                    {props.order.weight_of_heaviest_package} kg
                                </p>
                            </div>
                        </section>
                    )}
                    {props.order.vehicle && (
                        <section>
                            <div className="info">
                                <p className="title text-xs">
                                    {t("popup.orderInfo.vehicleLabel")}
                                </p>
                                <p className="value text-sm">
                                    <span>
                                        <IconOLD
                                            type={
                                                props.order
                                                    .vehicle_type as IconType
                                            }
                                            style={{
                                                color: "var(--text-color-light)",
                                            }}
                                        />{" "}
                                        {props.order.vehicle.friendlyname}
                                    </span>
                                </p>
                            </div>
                        </section>
                    )}
                    {props.order.earliest_pickup && (
                        <section>
                            <div className="info">
                                <p className="title text-xs">
                                    {t(
                                        "popup.confirmOrder.earliestPickupLabel"
                                    )}
                                </p>
                                <p className="value text-sm">
                                    {formatDateString(
                                        props.order.earliest_pickup,
                                        { showTime: true }
                                    )}
                                </p>
                            </div>
                        </section>
                    )}
                    {props.order.latest_delivery && (
                        <section>
                            <div className="info">
                                <p className="title text-xs">
                                    {t(
                                        "popup.confirmOrder.latestDeliveryLabel"
                                    )}
                                </p>
                                <p className="value text-sm">
                                    {formatDateString(
                                        props.order.latest_delivery,
                                        { showTime: true }
                                    )}
                                </p>
                            </div>
                        </section>
                    )}
                    {props.order.vehicle?.car_name === "large-van" && (
                        <section>
                            <div className="info">
                                <p className="title text-xs">
                                    {t(
                                        "createOrder.additionalVehicleTypeLabel"
                                    )}
                                </p>
                                <p className="value text-sm">
                                    {t(
                                        props.order.additional_info
                                            ?.side_loading
                                            ? "general.yes"
                                            : "general.no"
                                    )}
                                </p>
                            </div>
                        </section>
                    )}
                    {props.order.delivery &&
                        !props.order.earliest_pickup &&
                        !props.order.latest_delivery && (
                            <section>
                                <div className="info">
                                    <p className="title text-xs">
                                        {t("popup.orderInfo.deliveryLabel")}
                                    </p>
                                    <p className="value text-sm">
                                        {props.order.delivery.friendlyname}
                                    </p>
                                </div>
                            </section>
                        )}
                    <section>
                        <div className="info">
                            <p className="title text-xs">
                                {t("popup.orderInfo.fromLabel")}
                            </p>
                            <p className="value">
                                {props.order.pickup_address}
                            </p>
                        </div>
                    </section>
                    <section>
                        <div className="info">
                            <p className="title text-xs">
                                {t("popup.orderInfo.toLabel")}
                            </p>
                            <p className="value">
                                {props.order.dropoff_address}
                            </p>
                        </div>
                    </section>
                    {props.order.cargo_content && (
                        <section>
                            <div className="info">
                                <p className="title text-xs">
                                    {t("popup.orderInfo.cargoContentLabel")}
                                </p>
                                <p className="value text-sm">
                                    {props.order.cargo_content}
                                </p>
                            </div>
                        </section>
                    )}
                    {!!props.order.total_price && (
                        <section>
                            <div className="info">
                                <p className="title text-xs">
                                    {t("popup.orderInfo.priceLabel")}
                                </p>
                                <p className="value text-sm">
                                    {convertToNumberWithSpaces(
                                        props.order.total_price,
                                        "SEK"
                                    )}
                                </p>
                            </div>
                        </section>
                    )}
                    <section>
                        <div className="info">
                            <p className="title text-xs">
                                {t("popup.orderInfo.orderNumberLabel")}
                            </p>
                            <p className="value text-sm">
                                {props.order.origin_order_id}
                            </p>
                        </div>
                    </section>
                    {props.order.dropoff_contact_phone && (
                        <section>
                            <div className="info">
                                <p className="title text-xs">
                                    {t("popup.orderInfo.contactPhoneLabel")}
                                </p>
                                <p className="value text-sm">
                                    {props.order.dropoff_contact_phone}
                                </p>
                            </div>
                        </section>
                    )}
                    {(props.order.pickup_driver_note ||
                        props.order.dropoff_driver_note) && (
                        <section>
                            {props.order.pickup_driver_note && (
                                <div className="info">
                                    <p className="title text-xs">
                                        {t(
                                            "popup.orderInfo.pickupDriverNoteLabel"
                                        )}
                                    </p>
                                    <p className="value text-sm">
                                        {props.order.pickup_driver_note}
                                    </p>
                                </div>
                            )}
                            {props.order.dropoff_driver_note && (
                                <div className="info">
                                    <p className="title text-xs">
                                        {t(
                                            "popup.orderInfo.dropoffDriverNoteLabel"
                                        )}
                                    </p>
                                    <p className="value text-sm">
                                        {props.order.dropoff_driver_note}
                                    </p>
                                </div>
                            )}
                        </section>
                    )}
                    {(props.order.pickup_contact?.name ||
                        props.order.dropoff_contact_name) && (
                        <section>
                            {props.order.pickup_contact?.name && (
                                <div className="info">
                                    <p className="title text-xs">
                                        {t(
                                            "popup.orderInfo.pickupContactNameLabel"
                                        )}
                                    </p>
                                    <p className="value text-sm">
                                        {props.order.pickup_contact.name}
                                    </p>
                                </div>
                            )}
                            {props.order.dropoff_contact?.name && (
                                <div className="info">
                                    <p className="title text-xs">
                                        {t(
                                            "popup.orderInfo.dropoffContactNameLabel"
                                        )}
                                    </p>
                                    <p className="value text-sm">
                                        {props.order.dropoff_contact.name}
                                    </p>
                                </div>
                            )}
                        </section>
                    )}
                    {(orderDurations.loadingTime ||
                        orderDurations.drivingTime ||
                        orderDurations.offloading) && (
                        <section>
                            {orderDurations.loadingTime && (
                                <div className="info">
                                    <p className="title text-xs">
                                        {t("popup.orderInfo.loadingTimeLabel")}
                                    </p>
                                    <p className="value text-sm">
                                        {formatDuration(
                                            orderDurations.loadingTime
                                        )}
                                    </p>
                                </div>
                            )}
                            {orderDurations.drivingTime && (
                                <div className="info">
                                    <p className="title text-xs">
                                        {t("popup.orderInfo.drivingTimeLabel")}
                                    </p>
                                    <p className="value text-sm">
                                        {formatDuration(
                                            orderDurations.drivingTime
                                        )}
                                    </p>
                                </div>
                            )}

                            {orderDurations.offloading && (
                                <div className="info">
                                    <p className="title text-xs">
                                        {t(
                                            "popup.orderInfo.offloadingTimeLabel"
                                        )}
                                    </p>
                                    <p className="value text-sm">
                                        {formatDuration(
                                            orderDurations.offloading
                                        )}
                                    </p>
                                </div>
                            )}
                        </section>
                    )}
                    <section>
                        <div className="info">
                            <p className="title text-xs">
                                {t("popup.orderInfo.totalCO2")}
                            </p>
                            <p
                                className="value text-sm"
                                style={{
                                    color: "var(--text-color-alt)",
                                }}
                            >
                                {t("popup.orderInfo.comingSoon")}
                            </p>
                        </div>
                    </section>
                    {!!props.order.proof_of_delivery?.length &&
                        !proofOfDeliveries && (
                            <section>
                                <div className="info">
                                    <p className="title text-xs">
                                        {t(
                                            "popup.orderInfo.proofOfDeliveryLabel"
                                        )}
                                    </p>
                                    <div className="value proof-of-deliveries">
                                        {props.order.proof_of_delivery?.map(
                                            (pod) => (
                                                <a
                                                    key={pod.url}
                                                    href={pod.url}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="pod-link"
                                                >
                                                    <img
                                                        src={pod.url}
                                                        alt={t(
                                                            "popup.orderInfo.proofOfDeliveryLabel"
                                                        )}
                                                    />
                                                </a>
                                            )
                                        )}
                                    </div>
                                </div>
                            </section>
                        )}
                    {!!proofOfDeliveries?.pickup.length && (
                        <section>
                            <div className="info">
                                <p className="title text-xs">
                                    {t(
                                        "popup.orderInfo.pickupProofOfDeliveryLabel"
                                    )}
                                </p>
                                <div className="value proof-of-deliveries">
                                    {proofOfDeliveries.pickup.map((prodUrl) => (
                                        <a
                                            key={prodUrl}
                                            href={prodUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="pod-link"
                                        >
                                            <img
                                                src={prodUrl}
                                                alt={t(
                                                    "popup.orderInfo.proofOfDeliveryLabel"
                                                )}
                                            />
                                        </a>
                                    ))}
                                </div>
                                <IconButton
                                    icon="file-download"
                                    label={t(
                                        "popup.orderInfo.downloadPodsLabel"
                                    )}
                                    onClick={() => {
                                        downloadProofOfDeliveries(
                                            proofOfDeliveries.pickup,
                                            "pickup"
                                        );
                                    }}
                                />
                            </div>
                        </section>
                    )}
                    {!!proofOfDeliveries?.dropoff.length && (
                        <section>
                            <div className="info">
                                <p className="title text-xs">
                                    {t(
                                        "popup.orderInfo.dropoffProofOfDeliveryLabel"
                                    )}
                                </p>
                                <div className="value">
                                    {proofOfDeliveries.dropoff.map(
                                        (prodUrl) => (
                                            <a
                                                key={prodUrl}
                                                href={prodUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="pod-link"
                                            >
                                                <img
                                                    src={prodUrl}
                                                    alt={t(
                                                        "popup.orderInfo.proofOfDeliveryLabel"
                                                    )}
                                                />
                                            </a>
                                        )
                                    )}
                                </div>
                                <IconButton
                                    icon="file-download"
                                    label={t(
                                        "popup.orderInfo.downloadPodsLabel"
                                    )}
                                    onClick={() => {
                                        downloadProofOfDeliveries(
                                            proofOfDeliveries.dropoff,
                                            "dropoff"
                                        );
                                    }}
                                />
                            </div>
                        </section>
                    )}
                    <div className="text-button-wrapper">
                        <TextButton
                            label={
                                isShowingMore
                                    ? t("popup.confirmOrder.hideMoreLabel")
                                    : t("popup.confirmOrder.showMoreLabel")
                            }
                            variant="secondary"
                            onClick={() => setIsShowingMore((state) => !state)}
                        />
                    </div>
                    {isShowingMore && (
                        <>
                            <section>
                                <div className="info">
                                    <p className="title text-xs">
                                        {t("createOrder.additionalInfoCompany")}
                                    </p>
                                    <p className="value text-sm">
                                        {props.order.additional_info
                                            ?.consignee_company || "-"}
                                    </p>
                                </div>
                            </section>
                            <section>
                                <div className="info">
                                    <p className="title text-xs">
                                        {t(
                                            "createOrder.additionalInfoUnloading"
                                        )}
                                    </p>
                                    <p className="value text-sm">
                                        {props.order.additional_info
                                            ?.estimated_offload_time || "-"}
                                    </p>
                                </div>
                            </section>
                            <section>
                                <div className="info">
                                    <p className="title text-xs">
                                        {t(
                                            "createOrder.additionalInfoAdditionalContact"
                                        )}
                                    </p>
                                    {props.order.additional_info?.contacts?.map(
                                        (c) => (
                                            <p className="value text-sm">
                                                <span>{c.consignee_name}</span>
                                                <span>{c.consignee_phone}</span>
                                            </p>
                                        )
                                    ) || "-"}
                                </div>
                            </section>
                            <section>
                                <div className="info">
                                    <p className="title text-xs">
                                        {t(
                                            "createOrder.additionalInfoSpecificDropoff"
                                        )}
                                    </p>
                                    <p className="value text-sm">
                                        {props.order.additional_info
                                            ?.offload_instructions || "-"}
                                    </p>
                                </div>
                            </section>
                            <section>
                                <div className="info">
                                    <p className="title text-xs">
                                        {t(
                                            "createOrder.additionalInfoTotalCargo"
                                        )}
                                    </p>
                                    {props.order.additional_info?.cargo_dimensions?.map(
                                        (cd) => (
                                            <p className="value text-sm">
                                                <span>
                                                    {t(
                                                        "createOrder.additionalInfoDropdownLengthLabel"
                                                    )}
                                                    :{cd.length || "-"}
                                                </span>
                                                <span>
                                                    {t(
                                                        "createOrder.additionalInfoDropdownWidthLabel"
                                                    )}
                                                    :{cd.width || "-"}
                                                </span>
                                                <span>
                                                    {t(
                                                        "createOrder.additionalInfoDropdownHeightLabel"
                                                    )}
                                                    :{cd.height || "-"}
                                                </span>
                                                <span>
                                                    {t(
                                                        "createOrder.additionalInfoDropdownWeightLabel"
                                                    )}
                                                    : {cd.weight || "-"}
                                                </span>
                                            </p>
                                        )
                                    ) || "-"}
                                </div>
                            </section>
                            <section>
                                <div className="info">
                                    <p className="title text-xs">
                                        {t(
                                            "createOrder.additionalInfoBranchNumber"
                                        )}
                                    </p>
                                    <p className="value text-sm">
                                        {props.order.additional_info
                                            ?.branch_number || "-"}
                                    </p>
                                </div>
                            </section>
                            <section>
                                <div className="info">
                                    <p className="title text-xs">
                                        {t(
                                            "createOrder.additionalInfoMarkingInvoice"
                                        )}
                                    </p>
                                    <p className="value text-sm">
                                        {props.order.additional_info
                                            ?.invoice_number || "-"}
                                    </p>
                                </div>
                            </section>
                        </>
                    )}
                    <div className="copy-wrapper">
                        <IconButton
                            icon={hasCopiedShareLink ? "check" : "copy"}
                            style={
                                hasCopiedShareLink
                                    ? {
                                          color: "var(--text-color-alt)",
                                      }
                                    : undefined
                            }
                            label={t("popup.orderInfo.copyOrderLinkLabel")}
                            onClick={() =>
                                copyOrderLinkHandler(props.order!.id)
                            }
                            isLoading={isCopying}
                        />
                    </div>
                    <section>
                        <div className="share-wrapper">
                            <Controller
                                name="email"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Input
                                        type="email"
                                        label={t(
                                            "popup.orderInfo.shareEmailLabel"
                                        )}
                                        placeholder={t(
                                            "popup.orderInfo.shareEmailPlaceholder"
                                        )}
                                        style={{ width: "100%" }}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                            />
                            <Button
                                label={t("popup.orderInfo.shareButtonLabel")}
                                variant="secondary"
                                onClick={handleSubmit(
                                    (data) =>
                                        shareOrderHandler({
                                            data,
                                            orderId: props.order!.id,
                                        }),
                                    onFormError
                                )}
                                isLoading={isSharing}
                            />
                        </div>
                    </section>
                </div>
            )}
        </Popup>
    );
}

export default OrderInfoPopup;
