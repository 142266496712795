import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Button from "../../../components/buttons/Button";
import TextButton from "../../../components/buttons/TextButton";
import Input from "../../../components/inputs/Input";
import { LoginForm, loginForm } from "../../../schemas/auth";
import memberstack from "../../../shared/services/memberstack";
import { onFormError } from "../../../shared/utility/misc";
import { ROUTE } from "../../../shared/values/enums";
import "./style.scss";

type Props = {};
interface CustomError extends Error {
    code: string;
}

function Login(props: Props) {
    const { t } = useTranslation();

    const { control, handleSubmit, getValues } = useForm<LoginForm>({
        resolver: zodResolver(loginForm),
        defaultValues: {
            email: "",
            password: "",
        },
    });

    const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
    const [hasForgottenPassword, setHasForgottenPassword] = useState(false);

    const { mutate: loginHandler, isPending: isLoggingIn } = useMutation({
        mutationFn: async ({
            email,
            password,
        }: {
            email: string;
            password: string;
        }) => {
            await memberstack.loginMemberEmailPassword({
                email: email,
                password: password,
            });
        },
        onError: (error) => {
            console.error(error);

            if ((error as CustomError).code === "invalid-credentials") {
                toast.error((error as CustomError).message);
            } else {
                toast.error(t("errorMessage.loginFailed"));
            }
        },
    });

    const {
        mutate: forgotPasswordHandler,
        isPending: isForgotPasswordLoading,
    } = useMutation({
        mutationFn: async (email: string) => {
            await memberstack.sendMemberResetPasswordEmail({
                email,
            });
        },
        onSuccess: () => {
            toast.success(t("login.forgotPasswordSuccess"));
            setHasForgottenPassword(false);
        },
        onError: () => {
            toast.success(t("login.forgotPasswordSuccess"));
            setHasForgottenPassword(false);
        },
    });

    return (
        <div className="login">
            <form
                onSubmit={handleSubmit(
                    (data) => loginHandler(data),
                    onFormError
                )}
            >
                <h1 className="text-sm">{t("login.title")}</h1>
                <hr />
                <Controller
                    name="email"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="email"
                            value={value}
                            onChange={onChange}
                            label={t("login.emailLabel")}
                            placeholder={t("login.emailPlaceholder")}
                            autoComplete="email"
                            style={{ width: "100%" }}
                        />
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            type="password"
                            value={value}
                            onChange={onChange}
                            label={t("login.passwordLabel")}
                            placeholder={t("login.passwordPlaceholder")}
                            style={{ width: "100%" }}
                        />
                    )}
                />
                <Button
                    label={t("login.loginSubmitLabel")}
                    variant="primary"
                    isLoading={isLoggingIn}
                    style={{ width: "100%" }}
                />
            </form>
            {hasForgottenPassword && (
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        forgotPasswordHandler(forgotPasswordEmail);
                    }}
                >
                    <Input
                        type="email"
                        value={forgotPasswordEmail}
                        onChange={setForgotPasswordEmail}
                        label={t("login.emailLabel")}
                        placeholder={t("login.emailPlaceholder")}
                        style={{ width: "100%" }}
                    />
                    <Button
                        label={t("login.forgotPasswordSubmitLabel")}
                        variant="secondary"
                        style={{ width: "100%" }}
                        disabled={!forgotPasswordEmail.length}
                        isLoading={isForgotPasswordLoading}
                    />
                </form>
            )}
            <TextButton
                label={t("login.forgotPassword")}
                variant="primary"
                onClick={() => {
                    setHasForgottenPassword(true);
                    setForgotPasswordEmail(getValues("email"));
                }}
            />
            <div className="register-wrapper">
                <p className="text-xs">{t("login.noAccount")}</p>
                <NavLink to={ROUTE.Register}>
                    <TextButton label={t("login.register")} variant="primary" />
                </NavLink>
            </div>
            <a
                href="https://storage.googleapis.com/alrik-connect-images/terms-of-service.pdf"
                target="_blank"
                rel="noreferrer"
            >
                <TextButton
                    label={t("general.termsAndConditions")}
                    noSubmit
                    variant="primary"
                />
            </a>
        </div>
    );
}

export default Login;
