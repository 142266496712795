import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { isAfter } from "date-fns";
import { useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
    createStopDrafts,
    deleteStopDraft,
    updateStopDrafts,
} from "../../api/stop-draft";
import Button from "../../components/buttons/Button";
import AddressSearch from "../../components/inputs/AddressSearch";
import ColorPicker from "../../components/inputs/ColorPicker";
import ContactInput from "../../components/inputs/ContactInput";
import DateInput from "../../components/inputs/DateInput";
import Dropdown from "../../components/inputs/Dropdown";
import ExpoSlider from "../../components/inputs/ExpoSlider";
import Input from "../../components/inputs/Input";
import Textarea from "../../components/inputs/Textarea";
import OrderFile from "../../containers/OrderFile";
import Popup from "../../hoc/Popup";
import useProjectReferences from "../../hooks/data/useProjectReferences";
import useUserFeatures from "../../hooks/functionality/useUserFeatures";
import { StopForm, stopForm } from "../../schemas/form";
import {
    CreateStopDraftData,
    OrderDraft,
    StopDraft,
    UpdateStopDraftData,
} from "../../shared/types/api";
import { dateToString, stringToDate } from "../../shared/utility/date";
import { isEnvironment, onFormError } from "../../shared/utility/misc";
import { tourTimes } from "../../shared/values/dropdown";
import { STOP_DRAFT_TYPE } from "../../shared/values/enums";
import "./style.scss";
import MultiSelectText from "../../components/inputs/MultiSelectText";
import analytics from "../../shared/services/ga4";

type Props = {
    showPopup: boolean;
    onClose: () => void;
    onCreated: (createdIds: number[]) => void;
    onEdited: () => void;
    onDeleted: () => void;
    editStops: {
        pickup: StopDraft;
        dropoff: StopDraft;
    } | null;
    orderDraft?: OrderDraft | null;
};

function StopFormPopup(props: Props) {
    const { t } = useTranslation();

    const { onClose } = props;

    const features = useUserFeatures();

    const { projectReferences, refetch: refetchProjectReferences } =
        useProjectReferences();

    const defaultValues = useMemo<StopForm>(() => {
        const emptyValues: StopForm = {
            fromLocation: "",
            toLocation: "",
            pickupContactId: null,
            dropoffContactId: null,
            pickupPhone: "",
            dropoffPhone: "",
            orderNumber: "",
            estimatedOffloadTime: "15",
            date: null,
            pickupTime: null,
            dropoffTime: null,
            cargoContent: "",
            driverInstructions: "",
            dropoffColor: null,
            pickupColor: null,
            projectReference: "",
            weight: 0,
            tags: [],
        };

        if (props.editStops) {
            return {
                fromLocation: props.editStops.pickup.to_location,
                toLocation: props.editStops.dropoff.to_location,
                pickupContactId: props.editStops.pickup.contact?.id || null,
                dropoffContactId: props.editStops.dropoff.contact?.id || null,
                pickupPhone: props.editStops.pickup.phone || "",
                dropoffPhone: props.editStops.dropoff.phone || "",
                orderNumber: props.editStops.pickup.order_number,
                estimatedOffloadTime:
                    props.editStops.pickup.estimated_offload_time.toString(),
                date: props.editStops.pickup.date_tooltip
                    ? stringToDate(props.editStops.pickup.date_tooltip)
                    : null,
                pickupTime: props.editStops.pickup.time_tooltip || null,
                dropoffTime: props.editStops.dropoff.time_tooltip || null,
                cargoContent: props.editStops.pickup.cargo_content,
                dropoffColor: props.editStops.dropoff.color || null,
                pickupColor: props.editStops.pickup.color || null,
                projectReference:
                    props.editStops.pickup.project_reference || "",
                weight: props.editStops.pickup.weight_kg || 0,
                tags: props.editStops.pickup.tags || [],
                driverInstructions:
                    props.editStops.pickup.driver_instructions || "",
            };
        }

        if (props.orderDraft) {
            const earliestPickupHasTime =
                props.orderDraft?.earliest_pickup_utc?.includes("T") || false;
            const earliestPickupDate = stringToDate(
                props.orderDraft?.earliest_pickup_utc || ""
            );

            const latestDeliveryHasTime =
                props.orderDraft?.latest_delivery_utc?.includes("T") || false;
            const latestDeliveryDate = stringToDate(
                props.orderDraft?.latest_delivery_utc || ""
            );

            return {
                fromLocation: props.orderDraft.from_location || "",
                toLocation: props.orderDraft.to_location || "",
                pickupContactId: props.orderDraft?.pickup_contact_id || null,
                dropoffContactId: props.orderDraft?.dropoff_contact_id || null,
                pickupPhone: isEnvironment("staging")
                    ? ""
                    : props.orderDraft.pickup_phone || "",
                dropoffPhone: isEnvironment("staging")
                    ? ""
                    : props.orderDraft.dropoff_phone || "",
                orderNumber: props.orderDraft.origin_order_number || "",
                estimatedOffloadTime: props.orderDraft?.estimated_offload_time
                    ? props.orderDraft.estimated_offload_time.toString()
                    : "15",
                date: latestDeliveryDate || earliestPickupDate || null,
                pickupTime: earliestPickupHasTime
                    ? dateToString(earliestPickupDate, {
                          onlyTime: true,
                      })
                    : null,
                dropoffTime: latestDeliveryHasTime
                    ? dateToString(latestDeliveryDate, {
                          onlyTime: true,
                      })
                    : null,
                cargoContent: props.orderDraft.cargo_content || "",
                dropoffColor: null,
                pickupColor: null,
                projectReference: props.orderDraft.project_reference || "",
                weight: props.orderDraft.weight_kg || 0,
                tags: props.orderDraft.tags || [],
                driverInstructions: props.orderDraft.driver_instructions || "",
            };
        }

        return emptyValues;
    }, [props.editStops, props.orderDraft]);

    const { handleSubmit, control, reset, setValue, getValues } =
        useForm<StopForm>({
            resolver: zodResolver(stopForm),
            defaultValues,
        });

    const { mutate: createStopDraftHandler, isPending: isCreating } =
        useMutation({
            mutationFn: async (data: StopForm) => {
                const pickup: CreateStopDraftData = {
                    stop_type_id: STOP_DRAFT_TYPE.Pickup,
                    to_location: data.fromLocation,
                    contact_id: data.pickupContactId || undefined,
                    phone: data.pickupPhone,
                    order_number: data.orderNumber,
                    estimated_offload_time: data.estimatedOffloadTime,
                    cargo_content: data.cargoContent,
                    date_tooltip: data.date
                        ? dateToString(data.date)
                        : undefined,
                    time_tooltip: data.pickupTime || undefined,
                    color: data.pickupColor || undefined,
                    file_name: props.orderDraft?.file_name || undefined,
                    project_reference: data.projectReference || undefined,
                    weight_kg: data.weight || undefined,
                    tags: data.tags || [],
                    driver_instructions: data.driverInstructions || undefined,
                };

                const dropoff: CreateStopDraftData = {
                    stop_type_id: STOP_DRAFT_TYPE.Dropoff,
                    to_location: data.toLocation,
                    contact_id: data.dropoffContactId || undefined,
                    phone: data.dropoffPhone,
                    order_number: data.orderNumber,
                    estimated_offload_time: data.estimatedOffloadTime,
                    cargo_content: data.cargoContent,
                    date_tooltip: data.date
                        ? dateToString(data.date)
                        : undefined,
                    time_tooltip: data.dropoffTime || undefined,
                    color: data.dropoffColor || undefined,
                    file_name: props.orderDraft?.file_name || undefined,
                    project_reference: data.projectReference || undefined,
                    weight_kg: data.weight || undefined,
                    tags: data.tags || [],
                    driver_instructions: data.driverInstructions || undefined,
                };

                const res = await createStopDrafts([pickup, dropoff]);

                return res.data;
            },
            onSuccess: async (data) => {
                refetchProjectReferences();
                props.onCreated(data.map((item) => item.id));
            },
            onError: () => {
                toast.error(t("errorMessage.unknown"));
            },
        });

    const { mutate: editStopsHandler, isPending: isEditing } = useMutation({
        mutationFn: async ({
            data,
            editStops,
        }: {
            data: StopForm;
            editStops: { pickup: StopDraft; dropoff: StopDraft };
        }) => {
            const pickup: UpdateStopDraftData = {
                ...editStops.pickup,
                to_location: data.fromLocation,
                order_number: data.orderNumber,
                estimated_offload_time: +data.estimatedOffloadTime,
                weight_kg: data.weight || null,
                contact_id: data.pickupContactId,
                project_reference: data.projectReference,
                phone: data.pickupPhone,
                date_tooltip: data.date ? dateToString(data.date) : null,
                time_tooltip: data.pickupTime,
                cargo_content: data.cargoContent,
                color: data.pickupColor,
                driver_instructions: data.driverInstructions,
                tags: data.tags,
            };

            const dropoff: UpdateStopDraftData = {
                ...editStops.dropoff,
                to_location: data.toLocation,
                order_number: data.orderNumber,
                estimated_offload_time: +data.estimatedOffloadTime,
                weight_kg: data.weight || null,
                contact_id: data.dropoffContactId,
                project_reference: data.projectReference,
                phone: data.dropoffPhone,
                date_tooltip: data.date ? dateToString(data.date) : null,
                time_tooltip: data.dropoffTime,
                cargo_content: data.cargoContent,
                color: data.dropoffColor,
                driver_instructions: data.driverInstructions,
                tags: data.tags,
            };

            await updateStopDrafts([pickup, dropoff]);

            return !!pickup.tour_id || !!dropoff.tour_id;
        },
        onSuccess: (wasStopsInATour: boolean) => {
            if (wasStopsInATour) {
                analytics.updatedStopsInATour();
            }
            refetchProjectReferences();
            props.onEdited();
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });
    const { mutate: deleteStopsHandler, isPending: isDeleting } = useMutation({
        mutationFn: async (removeIds: { pickup: number; dropoff: number }) => {
            await Promise.all([
                deleteStopDraft(removeIds.pickup),
                deleteStopDraft(removeIds.dropoff),
            ]);
        },
        onSuccess: async () => {
            props.onDeleted();
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    const closeHandler = useCallback(() => {
        onClose();
    }, [onClose]);

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset, props.showPopup]);

    const isTimeDisabled = useCallback(
        ({
            time,
            compareTime,
            type,
        }: {
            time: string;
            compareTime: string;
            type: "pickup" | "dropoff";
        }) => {
            if (type === "pickup") {
                return isAfter(
                    new Date("2020-01-01T" + time),
                    new Date("2020-01-01T" + compareTime)
                );
            } else {
                return isAfter(
                    new Date("2020-01-01T" + compareTime),
                    new Date("2020-01-01T" + time)
                );
            }
        },
        []
    );

    return (
        <Popup
            showPopup={props.showPopup}
            onClose={closeHandler}
            title={
                props.editStops
                    ? t("popup.addNewLocation.editTitle")
                    : t("popup.addNewLocation.title")
            }
            dontCloseOnOutsideClick
            overlayComponent={
                props.orderDraft?.file_name ? (
                    <OrderFile filename={props.orderDraft.file_name} />
                ) : props.editStops?.pickup.file_name ? (
                    <OrderFile filename={props.editStops.pickup.file_name} />
                ) : undefined
            }
        >
            <form
                className="stop-form-popup"
                onSubmit={handleSubmit(
                    (data) =>
                        props.editStops
                            ? editStopsHandler({
                                  data,
                                  editStops: props.editStops,
                              })
                            : createStopDraftHandler(data),
                    onFormError
                )}
            >
                <section>
                    <Controller
                        control={control}
                        name="fromLocation"
                        render={({ field: { value, onChange } }) => (
                            <AddressSearch
                                value={value}
                                preselectedAddress={
                                    defaultValues.fromLocation || ""
                                }
                                onChange={onChange}
                                label={t("createOrder.fromAddressLabel")}
                                placeholder={t(
                                    "createOrder.fromAddressPlaceholder"
                                )}
                                width="100%"
                                type="pickup"
                            />
                        )}
                    />
                </section>
                <section>
                    <Controller
                        control={control}
                        name="toLocation"
                        render={({ field: { value, onChange } }) => (
                            <AddressSearch
                                value={value}
                                preselectedAddress={
                                    defaultValues.toLocation || ""
                                }
                                onChange={onChange}
                                label={t("createOrder.toAddressLabel")}
                                placeholder={t(
                                    "createOrder.toAddressPlaceholder"
                                )}
                                width="100%"
                                type="dropoff"
                            />
                        )}
                    />
                </section>
                <section className="divider">
                    <Controller
                        name="orderNumber"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <Input
                                type="text"
                                value={value}
                                onChange={onChange}
                                label={t("createTour.orderNumber") + "*"}
                                placeholder={t(
                                    "createTour.orderNumberPlaceholder"
                                )}
                                style={{ width: "100%" }}
                            />
                        )}
                    />
                    <Controller
                        name="estimatedOffloadTime"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <Input
                                type="text"
                                value={value}
                                onChange={onChange}
                                placeholder={t("createTour.approxPlaceholder")}
                                label={t("createTour.approxMinutes") + "*"}
                                style={{ width: "100%" }}
                            />
                        )}
                    />
                </section>
                <section>
                    <Controller
                        name="weight"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <ExpoSlider
                                value={value === 0 ? "" : value.toString()}
                                onChange={(v) => onChange(+v)}
                                steps={[5, 10, 50, 100]}
                                max={4500}
                                label={t("createTour.weight")}
                            />
                        )}
                    />
                </section>
                <section>
                    <Controller
                        control={control}
                        name="pickupContactId"
                        render={({ field: { value, onChange } }) => (
                            <ContactInput
                                value={value}
                                onSelect={(c) => {
                                    onChange(c?.id || null);
                                    setValue("pickupPhone", c?.phone || "");
                                }}
                                label={t("createTour.pickupContact")}
                                placeholder={t("createTour.contactPlaceholder")}
                                width="100%"
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="dropoffContactId"
                        render={({ field: { value, onChange } }) => (
                            <ContactInput
                                value={value}
                                onSelect={(c) => {
                                    onChange(c?.id || null);
                                    setValue("dropoffPhone", c?.phone || "");
                                }}
                                label={t("createTour.dropoffContact")}
                                placeholder={t("createTour.contactPlaceholder")}
                                width="100%"
                            />
                        )}
                    />
                </section>
                <section>
                    <Controller
                        control={control}
                        name="tags"
                        render={({ field: { value, onChange } }) => (
                            <MultiSelectText
                                value={value}
                                onChange={onChange}
                                label={t("createTour.tags")}
                                placeholder={t("createTour.tagPlaceholder")}
                                width="100%"
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="projectReference"
                        render={({ field: { value, onChange } }) => (
                            <Dropdown
                                value={value}
                                onSelect={({ value }) => onChange(value)}
                                options={
                                    projectReferences?.map((pr) => ({
                                        value: pr.name,
                                        label: pr.name,
                                    })) || []
                                }
                                label={t("createTour.projectReference")}
                                placeholder={t(
                                    "createTour.projectReferencePlaceholder"
                                )}
                                width="100%"
                                isCreatable
                                onCreate={onChange}
                            />
                        )}
                    />
                </section>
                <section>
                    <Controller
                        name="pickupPhone"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <Input
                                type="tel"
                                value={value}
                                onChange={onChange}
                                label={t("createTour.phone")}
                                placeholder={t("createTour.phonePlaceholder")}
                                name="phone"
                                style={{ width: "100%" }}
                            />
                        )}
                    />
                    <Controller
                        name="dropoffPhone"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <Input
                                type="tel"
                                value={value}
                                onChange={onChange}
                                label={t("createTour.phone")}
                                placeholder={t("createTour.phonePlaceholder")}
                                name="phone"
                                style={{ width: "100%" }}
                            />
                        )}
                    />
                </section>
                <section>
                    <Controller
                        control={control}
                        name="date"
                        render={({ field: { value, onChange } }) => (
                            <DateInput
                                label={t("createTour.date")}
                                value={value}
                                onChange={onChange}
                                width="100%"
                                showAllDates
                                ignoreUnselectableDates
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="pickupTime"
                        render={({ field: { value, onChange } }) => (
                            <Dropdown
                                label={t("createTour.pickupTime")}
                                options={[
                                    {
                                        label: t("createTour.deselectTime"),
                                        value: "",
                                    },
                                    ...tourTimes,
                                ]}
                                value={value}
                                onSelect={({ value }) => onChange(value)}
                                isSearchable
                                width="100%"
                                isOptionDisabled={(o) =>
                                    isTimeDisabled({
                                        time: o.value,
                                        compareTime:
                                            getValues("dropoffTime") || "",
                                        type: "pickup",
                                    })
                                }
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="dropoffTime"
                        render={({ field: { value, onChange } }) => (
                            <Dropdown
                                label={t("createTour.dropoffTime")}
                                options={[
                                    {
                                        label: t("createTour.deselectTime"),
                                        value: "",
                                    },
                                    ...tourTimes,
                                ]}
                                value={value}
                                onSelect={({ value }) => onChange(value)}
                                isSearchable
                                width="100%"
                                isOptionDisabled={(o) =>
                                    isTimeDisabled({
                                        time: o.value,
                                        compareTime:
                                            getValues("pickupTime") || "",
                                        type: "dropoff",
                                    })
                                }
                            />
                        )}
                    />
                </section>
                <section>
                    <Controller
                        name="cargoContent"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <Textarea
                                value={value}
                                onChange={onChange}
                                label={t("createTour.cargoContent")}
                                placeholder={t("createTour.cargoPlaceholder")}
                                width="100%"
                                textareaStyle={{
                                    minHeight: "160px",
                                }}
                            />
                        )}
                    />
                </section>
                <section>
                    <Controller
                        name="driverInstructions"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <Textarea
                                value={value}
                                onChange={onChange}
                                label={t("createTour.driverInstructions")}
                                placeholder={t(
                                    "createTour.driverInstructionsPlaceholder"
                                )}
                                width="100%"
                                textareaStyle={{
                                    minHeight: "160px",
                                }}
                            />
                        )}
                    />
                </section>
                <section>
                    <Controller
                        control={control}
                        name="pickupColor"
                        render={({ field: { value, onChange } }) => (
                            <ColorPicker
                                label={t("createTour.pickupColor")}
                                value={value}
                                onChange={onChange}
                                large
                                width="100%"
                                defaultColor="var(--color-pure-white)"
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="dropoffColor"
                        render={({ field: { value, onChange } }) => (
                            <ColorPicker
                                label={t("createTour.dropoffColor")}
                                value={value}
                                onChange={onChange}
                                large
                                width="100%"
                                defaultColor="var(--color-pure-white)"
                            />
                        )}
                    />
                </section>

                <section>
                    {props.editStops ? (
                        <>
                            {!props.editStops.pickup.tour_id && (
                                <Button
                                    label={t("popup.addNewLocation.remove")}
                                    variant="secondary"
                                    onClick={() =>
                                        props.editStops
                                            ? deleteStopsHandler({
                                                  pickup: props.editStops.pickup
                                                      .id,
                                                  dropoff:
                                                      props.editStops.dropoff
                                                          .id,
                                              })
                                            : undefined
                                    }
                                    isLoading={isDeleting}
                                    disabled={
                                        !features?.delete_stops_fleet_planner
                                    }
                                    style={{ width: "25%", minWidth: "25%" }}
                                />
                            )}
                            <Button
                                label={t("popup.addNewLocation.edit")}
                                variant="primary"
                                isLoading={isEditing}
                                disabled={!features?.manage_tours_fleet_planner}
                                style={{ width: "100%" }}
                            />
                        </>
                    ) : (
                        <Button
                            label={t("popup.addNewLocation.submit")}
                            variant="primary"
                            isLoading={isCreating}
                            disabled={!features?.create_stops_fleet_planner}
                            style={{ width: "100%" }}
                        />
                    )}
                </section>
            </form>
        </Popup>
    );
}

export default StopFormPopup;
