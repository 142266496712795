import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useMemo } from "react";
import Spinner from "../../UI/Spinner";
import "./style.scss";

type TextButtonColor = {
    color: string;
    iconColor: string;
};

type TextButtonVariants = {
    primary: TextButtonColor;
    secondary: TextButtonColor;
    link: TextButtonColor;
    disabled: TextButtonColor;
};

const variants: TextButtonVariants = {
    primary: {
        color: "var(--color-primary-600)",
        iconColor: "var(--color-primary-500)",
    },
    secondary: {
        color: "var(--color-neutral-900)",
        iconColor: "var(--color-neutral-600)",
    },
    link: {
        color: "var(--color-blue-500)",
        iconColor: "var(--color-blue-500)",
    },
    disabled: {
        color: "var(--color-neutral-400)",
        iconColor: "var(--color-neutral-400)",
    },
};

type Props = {
    label?: string;
    variant: keyof TextButtonVariants;
    leadingIcon?: IconDefinition;
    leadingIconColor?: string;
    trailingIcon?: IconDefinition;
    onClick?: (event: React.MouseEvent) => void;
    style?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
    className?: string;
    isLoading?: boolean;
    disabled?: boolean;
    noSubmit?: boolean;
};

const TextButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const buttonClasses = ["text-button"];

    if (props.className) {
        buttonClasses.push(props.className);
    }

    const { color, iconColor } = useMemo(() => {
        return props.disabled ? variants.disabled : variants[props.variant];
    }, [props.disabled, props.variant]);

    return (
        <button
            ref={ref}
            onClick={(e) => {
                if (props.isLoading || props.disabled) {
                    e.preventDefault();
                    return;
                }
                if (props.onClick) props.onClick(e);
            }}
            className={buttonClasses.join(" ")}
            disabled={props.disabled}
            style={{ ...props.style }}
            type={props.noSubmit || props.onClick ? "button" : undefined}
        >
            {/* Leading icon */}
            {props.isLoading && (
                <div className="button-loading">
                    <Spinner width="15px" />
                </div>
            )}
            {props.leadingIcon && !props.isLoading && (
                <FontAwesomeIcon
                    icon={props.leadingIcon}
                    size="lg"
                    color={props.leadingIconColor || iconColor}
                />
            )}

            {/* Label */}
            {props.label && (
                <p
                    className="text-xs"
                    style={{
                        color,
                        ...props.labelStyle,
                    }}
                >
                    {props.label}
                </p>
            )}

            {/* Trailing icon */}
            {props.trailingIcon &&
                (props.isLoading ? (
                    <div className="button-loading">
                        <Spinner width="15px" />
                    </div>
                ) : (
                    <FontAwesomeIcon
                        icon={props.trailingIcon}
                        size="lg"
                        color={iconColor}
                    />
                ))}
        </button>
    );
});

export default TextButton;
