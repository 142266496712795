import { CSSProperties, RefObject, useMemo, useRef } from "react";
import { useClickOutside } from "../../hooks/functionality/useClickOutside";
import "./style.scss";
import { AnimatePresence, motion } from "framer-motion";

type Props = {
    buttonElement: (ref: RefObject<HTMLButtonElement>) => React.ReactNode;
    children: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
    width?: string;
    fixedMenu?: boolean;
    modalClass?: string;
    align: "left" | "right";
    topAlign?: boolean;
    style?: CSSProperties;
    onHoverStart?: () => void;
};

function Modal(props: Props) {
    const modalClasses = ["modal"];
    if (props.fixedMenu) {
        modalClasses.push("fixed-menu");
    }
    if (props.modalClass) {
        modalClasses.push(props.modalClass);
    }

    const modalRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    useClickOutside([modalRef, buttonRef], () => {
        if (!props.isOpen) return;
        props.onClose();
    });

    const modalDistanceFromButton = useMemo(() => {
        if (props.onHoverStart) {
            return "0px";
        }

        return "5px";
    }, [props.onHoverStart]);

    const motionVariants = {
        initial: { opacity: 0 },
        enter: { opacity: 1 },
        exit: { opacity: 0 },
    };

    return (
        <div
            className="modal-wrapper"
            style={props.style}
            onMouseEnter={props.onHoverStart}
            onMouseLeave={props.onHoverStart ? props.onClose : undefined}
        >
            {props.buttonElement(buttonRef)}
            <AnimatePresence>
                {props.isOpen && (
                    <motion.div
                        key="modal"
                        variants={motionVariants}
                        initial="initial"
                        animate="enter"
                        exit="exit"
                        className={modalClasses.join(" ")}
                        ref={modalRef}
                        style={{
                            width: props.width,
                            left: props.align === "left" ? "0" : "auto",
                            right: props.align === "right" ? "0" : "auto",
                            top: !props.topAlign
                                ? `calc(100% + ${modalDistanceFromButton})`
                                : "auto",
                            bottom: props.topAlign
                                ? `calc(100% + ${modalDistanceFromButton})`
                                : "auto",
                        }}
                    >
                        {props.children}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}

export default Modal;
