import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PhoneInputWithCountrySelect, { Country } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./style.scss";

type Props = {
    value: string;
    onChange: (value: string) => void;

    label?: string;
    labelColor?: string;
    placeholder?: string;
    disabled?: boolean;
    width?: string;
};

const AVAILABLE_COUNTRY_CODES: Country[] = [
    "AD",
    "AL",
    "AT",
    "BA",
    "BE",
    "BG",
    "BY",
    "CH",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FR",
    "GB",
    "GR",
    "HR",
    "HU",
    "IE",
    "IS",
    "IT",
    "LI",
    "LT",
    "LU",
    "LV",
    "MC",
    "MD",
    "ME",
    "MK",
    "MT",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "RS",
    "RU",
    "SE",
    "SI",
    "SK",
    "SM",
    "UA",
    "US",
    "VA",
];

function PhoneInput(props: Props) {
    const { i18n } = useTranslation();

    const defaultCountryCode = useMemo<Country>(() => {
        switch (i18n.language) {
            case "en":
                return "GB";
            case "nb":
                return "NO";
            case "da":
                return "DK";
            case "sv":
                return "SE";
            default:
                return "GB";
        }
    }, [i18n.language]);

    return (
        <label className="phone-input-wrapper" style={{ width: props.width }}>
            {props.label && (
                <div
                    className="phone-input-label"
                    style={{
                        color: props.labelColor,
                    }}
                >
                    <span className="text-xs">{props.label}</span>
                </div>
            )}
            <PhoneInputWithCountrySelect
                value={props.value}
                onChange={(v) => props.onChange(v?.toString() || "")}
                placeholder={props.placeholder}
                countries={AVAILABLE_COUNTRY_CODES}
                country
                international={false}
                defaultCountry={defaultCountryCode}
                disabled={props.disabled}
            />
        </label>
    );
}

export default PhoneInput;
