import { faFileArrowDown } from "@fortawesome/pro-regular-svg-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { endOfMonth, startOfMonth } from "date-fns";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { getStatsCsv } from "../../api/tours";
import Button from "../../components/buttons/Button";
import DateInput from "../../components/inputs/DateInput";
import Switch from "../../components/inputs/Switch";
import Popup from "../../hoc/Popup";
import { DownloadStatsCsvForm, downloadStatsCsvForm } from "../../schemas/form";
import analytics from "../../shared/services/ga4";
import { dateToString } from "../../shared/utility/date";
import { onFormError } from "../../shared/utility/misc";
import "./style.scss";

type Props = {
    showPopup: boolean;
    onClose: () => void;
};

function DownloadDashboardPopup(props: Props) {
    const { t } = useTranslation();

    const defaultValues = useMemo<DownloadStatsCsvForm>(() => {
        return {
            startDate: startOfMonth(new Date()),
            endDate: endOfMonth(new Date()),
            files: {
                orders: true,
                activity_logs: true,
                billable_tours: true,
            },
        };
    }, []);

    const { handleSubmit, control } = useForm<DownloadStatsCsvForm>({
        resolver: zodResolver(downloadStatsCsvForm),
        defaultValues,
    });

    const { mutate: downloadStatsCsv, isPending: isLoading } = useMutation({
        mutationFn: async ({
            startDate,
            endDate,
            files,
        }: DownloadStatsCsvForm) => {
            const res = await getStatsCsv({
                startDate: dateToString(startDate),
                endDate: dateToString(endDate),
            });

            const csvs: {
                name: keyof DownloadStatsCsvForm["files"];
                blob: Blob;
            }[] = [];

            if (files.orders) {
                const blob = new Blob([res.data.orders], {
                    type: "text/csv",
                });
                csvs.push({ blob, name: "orders" });
            }

            if (files.activity_logs) {
                const blob = new Blob([res.data.activity_logs], {
                    type: "text/csv",
                });
                csvs.push({ blob, name: "activity_logs" });
            }

            if (files.billable_tours) {
                const blob = new Blob([res.data.billable_tours], {
                    type: "text/csv",
                });
                csvs.push({ blob, name: "billable_tours" });
            }

            for (let i = 0; i < csvs.length; i++) {
                const { name, blob } = csvs[i];

                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");

                link.href = url;
                link.setAttribute(
                    "download",
                    `alrik-export-${name}-${dateToString(
                        startDate
                    )}-${dateToString(endDate)}.csv`
                );
                // Needed because safari dosen't like it when we download all files at once
                await new Promise((resolve) => {
                    setTimeout(resolve, 100);
                });
                link.click();
            }
        },
        onSuccess: () => {
            toast.success(t("successMessage.downloadStatsCsv"));
            analytics.dashboardCsvDownloaded();
        },
        onError: () => {
            toast.error(t("errorMessage.unknown"));
        },
    });

    return (
        <Popup showPopup={props.showPopup} onClose={props.onClose}>
            <form
                className="download-dashbaord-popup"
                onSubmit={handleSubmit(
                    (data) => downloadStatsCsv(data),
                    onFormError
                )}
            >
                <section>
                    <Controller
                        name="startDate"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <DateInput
                                label={t(
                                    "popup.downloadDashboard.startDateLabel"
                                )}
                                value={value}
                                onChange={onChange}
                                showAllDates
                                ignoreUnselectableDates
                                width="100%"
                            />
                        )}
                    />
                    <Controller
                        name="endDate"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <DateInput
                                label={t(
                                    "popup.downloadDashboard.endDateLabel"
                                )}
                                value={value}
                                onChange={onChange}
                                showAllDates
                                ignoreUnselectableDates
                                width="100%"
                            />
                        )}
                    />
                </section>
                <section>
                    <p
                        style={{
                            color: "var(--text-color-light)",
                        }}
                    >
                        {t("popup.downloadDashboard.downloadInfo")}
                    </p>
                </section>
                <section style={{ flexWrap: "wrap" }}>
                    <Controller
                        name="files"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <>
                                <Switch
                                    label={t(
                                        "popup.downloadDashboard.ordersLabel"
                                    )}
                                    isActive={value.orders}
                                    onChange={(v) =>
                                        onChange({ ...value, orders: v })
                                    }
                                />
                                <Switch
                                    label={t(
                                        "popup.downloadDashboard.activityLogsLabel"
                                    )}
                                    isActive={value.activity_logs}
                                    onChange={(v) =>
                                        onChange({
                                            ...value,
                                            activity_logs: v,
                                        })
                                    }
                                />
                                <Switch
                                    label={t(
                                        "popup.downloadDashboard.billableToursLabel"
                                    )}
                                    isActive={value.billable_tours}
                                    onChange={(v) =>
                                        onChange({
                                            ...value,
                                            billable_tours: v,
                                        })
                                    }
                                />
                            </>
                        )}
                    />
                </section>
                <section>
                    <Button
                        label={t("popup.downloadDashboard.downloadButtonLabel")}
                        variant="primary"
                        style={{ width: "100%" }}
                        isLoading={isLoading}
                        leadingIcon={faFileArrowDown}
                    />
                </section>
            </form>
        </Popup>
    );
}

export default DownloadDashboardPopup;
