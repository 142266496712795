import { faEye } from "@fortawesome/pro-solid-svg-icons";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/buttons/Button";
import Switch from "../../../components/inputs/Switch";
import Modal from "../../../hoc/Modal";
import "./style.scss";

type Props = {
    states: {
        showDrivers: boolean;
        showUnhandledStops: boolean;
    };
    onChange: (key: keyof Props["states"], value: boolean) => void;
};

function BigVolumeMapModal(props: Props) {
    const { onChange } = props;
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const switchesSections = useMemo<
        {
            label: string;
            value: boolean;
            onChange: (value: boolean) => void;
        }[]
    >(() => {
        return [
            {
                label: t("bigVolume.showDrivers"),
                value: props.states.showDrivers,
                onChange: (value) => onChange("showDrivers", value),
            },
            {
                label: t("bigVolume.showUnhandledStops"),
                value: props.states.showUnhandledStops,
                onChange: (value) => onChange("showUnhandledStops", value),
            },
        ];
    }, [
        onChange,
        props.states.showDrivers,
        props.states.showUnhandledStops,
        t,
    ]);

    return (
        <Modal
            buttonElement={(ref) => (
                <Button
                    ref={ref}
                    variant={"secondary"}
                    label={t("bigVolume.showMarkers")}
                    leadingIcon={faEye}
                    onClick={() => setIsModalOpen(true)}
                />
            )}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            align={"left"}
            width="250px"
        >
            <div className="big-volume-map-modal">
                {switchesSections.map(({ label, value, onChange }) => (
                    <section key={label}>
                        <span>{label}</span>
                        <Switch isActive={!!value} onChange={onChange} />
                    </section>
                ))}
            </div>
        </Modal>
    );
}

export default BigVolumeMapModal;
