import { useQuery } from "@tanstack/react-query";
import { getDrivers } from "../../api/driver";
import { Driver } from "../../shared/types/api";

function useDrivers() {
    const {
        data: drivers,
        status,
        refetch,
        isFetching,
    } = useQuery({
        queryKey: ["drivers"],
        queryFn: async () => {
            try {
                const res = await getDrivers();
                return res.data;
            } catch (error) {
                return [] as Driver[];
            }
        },
    });

    switch (status) {
        case "success":
            return {
                drivers,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                drivers,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                drivers,
                status,
                refetch,
                isFetching,
            };
    }
}

export default useDrivers;
