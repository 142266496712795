import { useSelector } from "react-redux";
import { ReduxState } from "../../shared/types/redux";

function useUserFeatures() {
    const features = useSelector(
        (state: ReduxState) => state.auth.user?.features
    );

    return features || null;
}

export default useUserFeatures;
