import i18next, { t } from "i18next";
import { FieldErrors, FieldValues } from "react-hook-form";
import toast from "react-hot-toast";
import environment from "../../environment";
import { Driver } from "../types/api";
import { AppEnvironment, KeyString } from "../types/internal";
import { FleetPlannerSettings } from "../types/redux";
import { LOCAL_STORAGE_KEY } from "../values/enums";

/**
 * This function limits the execution of a function to once in every specified time interval
 * @param func Function to be throttled
 * @param delay Time interval in milliseconds
 * @returns Throttled function
 */
export function throttle<T extends (...args: any[]) => void>(
    func: T,
    delay: number
): T {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;
    let lastArgs: Parameters<T> | undefined;

    return ((...args: Parameters<T>) => {
        lastArgs = args;

        if (timeoutId) return;

        timeoutId = setTimeout(() => {
            func(...lastArgs!);
            timeoutId = undefined;
        }, delay);
    }) as T;
}

/**
 * This function delays the execution of a function until the function stops being called for a specified time interval
 * @param func Function to be debounced
 * @param delay Time interval in milliseconds
 * @returns Debounced function
 */
export function debounce<T extends Function>(func: T, delay: number): T {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;
    let callable = (...args: any) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), delay);
    };
    return callable as any as T;
}

export function isEnvironment(env: AppEnvironment) {
    return environment.environment === env;
}

export function getVersion() {
    const localVersion = localStorage.getItem("app-v");
    if (localVersion) return localVersion;
    return environment.appVersion;
}

export function convertToNumberWithSpaces(num: number, ending?: string) {
    return `${num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}${
        ending ? ` ${ending}` : ""
    }`;
}

export function generateUUID() {
    return crypto.randomUUID();
}

export function getLocale() {
    const userLocale = localStorage.getItem(LOCAL_STORAGE_KEY.UserLocale);
    if (userLocale) return userLocale;

    let locale = "en";
    if (i18next.language) return i18next.language.split("-")[0];

    if (navigator.languages && navigator.languages.length) {
        locale = navigator.languages[0];
    } else {
        locale =
            (navigator as any).userLanguage ||
            navigator.language ||
            (navigator as any).browserLanguage ||
            "en";
    }
    return locale.split("-")[0];
}

export function onFormError(errors: FieldErrors<FieldValues>) {
    console.log(errors);

    //only print the first error to not overwhelm the user
    const firstErrorKey = Object.keys(errors)[0] as keyof FieldValues;

    const firstError = errors[firstErrorKey];

    if (!firstError) return;

    if (firstError.message && typeof firstError.message === "string") {
        toast.error(t(firstError.message));
        return;
    }

    if (isEnvironment("staging")) {
        toast.error(
            "This error should not happen. Please tell Tech (ONLY ON STAGING)"
        );
    }
}

export function updateFleetPlannerSettingsLocalStorage(
    settings: FleetPlannerSettings
) {
    localStorage.setItem(
        LOCAL_STORAGE_KEY.FleetPlannerSettings,
        JSON.stringify(settings)
    );
}

export function getDriverDisplayName(driver: Driver) {
    let result = "";
    if (driver.first_name) {
        result += driver.first_name;
    }
    if (driver.last_name && driver.first_name) {
        result += " ";
    }
    if (driver.last_name) {
        result += driver.last_name;
    }

    return result;
}

export function calculatePercentageChange(current: number, prior: number) {
    if (Math.abs(prior) < 1) return 0;

    const difference = current - prior;

    if ((current < 0 && prior > 0) || (current > 0 && prior < 0))
        return (difference / Math.abs(prior)) * 100;

    return (difference / prior) * 100;
}

export function getLocationKey(
    location: { lat: number; lng: number },
    prefix?: string
) {
    let key = `${location.lat},${location.lng}`;
    if (prefix) {
        key = prefix + key;
    }
    return key;
}

export function getLatLngCountMap(
    locations: {
        lat: number;
        lng: number;
        prefix?: string;
    }[]
) {
    const latLngCounts: KeyString<number> = {};
    for (let i = 0; i < locations.length; i++) {
        const location = locations[i];

        let key = getLocationKey(location, location.prefix);
        latLngCounts[key] = (latLngCounts[key] || 0) + 1;
    }
    return latLngCounts;
}

export function getBoundForLocations(
    locations: {
        lat: number;
        lng: number;
    }[]
) {
    const bounds = new google.maps.LatLngBounds();

    for (let i = 0; i < locations.length; i++) {
        const location = locations[i];

        bounds.extend(new google.maps.LatLng(location.lat, location.lng));
    }

    return bounds;
}

export function getRandomNumber(max: number, min = 0): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
}
