import { useSelector } from "react-redux";
import { ReduxState } from "../../shared/types/redux";
import { ROUTE } from "../../shared/values/enums";
import useUserFeatures from "./useUserFeatures";
import { useCallback, useMemo } from "react";

function useRouteAccessible() {
    const features = useUserFeatures();
    const { user } = useSelector((state: ReduxState) => state.auth);

    const hasLocationFleetAccess = useMemo(
        () =>
            !!user?.location_entity?.mt_organization ||
            !!user?.location_entity?.use_alrik_driver_app,
        [
            user?.location_entity?.mt_organization,
            user?.location_entity?.use_alrik_driver_app,
        ]
    );

    const isRouteAccessible = useCallback(
        (route: ROUTE) => {
            switch (route) {
                case ROUTE.Settings:
                    return true;
                case ROUTE.Support:
                    return true;

                case ROUTE.Orders:
                    return !!features?.create_fod_order;
                case ROUTE.NewOrder:
                    return !!features?.create_fod_order;
                case ROUTE.ActiveOrders:
                    return !!features?.create_fod_order;
                case ROUTE.FulfilledOrders:
                    return !!features?.create_fod_order;

                case ROUTE.PublicOrder:
                    return true;
                case ROUTE.TrackingRedirect:
                    return true;
                case ROUTE.Tracking:
                    return true;
                case ROUTE.ExternalOrder:
                    return true;

                case ROUTE.Fleet:
                    const hasFeatureAccess =
                        !!features?.read_fleet_planner ||
                        !!features?.create_internal_order ||
                        !!features?.read_fleet_planner_calendar;

                    return hasFeatureAccess && hasLocationFleetAccess;
                case ROUTE.Cockpit:
                    return (
                        !!features?.read_fleet_planner && hasLocationFleetAccess
                    );
                case ROUTE.ActiveFleet:
                    return (
                        !!features?.read_fleet_planner && hasLocationFleetAccess
                    );
                case ROUTE.FulfilledFleetTours:
                    return (
                        !!features?.read_fleet_planner && hasLocationFleetAccess
                    );
                case ROUTE.Drivers:
                    return (
                        !!features?.read_fleet_planner && hasLocationFleetAccess
                    );
                case ROUTE.Dashboard:
                    return (
                        !!features?.read_fleet_planner && hasLocationFleetAccess
                    );
                case ROUTE.FleetCalendar:
                    return (
                        !!features?.read_fleet_planner_calendar &&
                        hasLocationFleetAccess
                    );
                case ROUTE.FleetCalendarFullPage:
                    return (
                        !!features?.read_fleet_planner_calendar &&
                        hasLocationFleetAccess
                    );
                case ROUTE.FleetSettings:
                    return (
                        !!features?.read_fleet_planner && hasLocationFleetAccess
                    );
                case ROUTE.FleetManualOrder:
                    return (
                        !!features?.create_internal_order &&
                        hasLocationFleetAccess
                    );

                case ROUTE.Login:
                    return true;
                case ROUTE.Register:
                    return true;
                case ROUTE.EmailSent:
                    return true;
                case ROUTE.AuthCode:
                    return true;
                case ROUTE.Setup:
                    return true;
                case ROUTE.ResetPassword:
                    return true;
            }
        },
        [
            features?.create_fod_order,
            features?.create_internal_order,
            features?.read_fleet_planner,
            features?.read_fleet_planner_calendar,
            hasLocationFleetAccess,
        ]
    );

    return {
        isRouteAccessible,
    };
}

export default useRouteAccessible;
