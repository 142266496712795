import { useCallback } from "react";
import useActiveTours from "./useActiveTours";
import useDrivers from "./useDrivers";
import useFleetPlannerOrders from "./useFleetPlannerOrders";
import useOrderDrafts from "./useOrderDrafts";
import useStopDrafts from "./useStopDrafts";
import { FleetPlannerReloadDataHandler } from "../../shared/types/internal";
import { useQueryClient } from "@tanstack/react-query";
import useMovedStops from "./useMovedStops";

function useFleetPlannerData({
    filterDate,
}: {
    filterDate: string | undefined;
}) {
    const queryClient = useQueryClient();

    const {
        drivers,
        status: driversStatus,
        isFetching: isFetchingDrivers,
        refetch: refetchDrivers,
    } = useDrivers();

    const {
        tours,
        refetch: refetchTours,
        status: toursStatus,
        isFetching: isFetchingTours,
    } = useActiveTours(filterDate);

    const {
        stopDrafts,
        status: stopDraftsStatus,
        refetch: refetchStopDrafts,
        isFetching: isFetchingStopDrafts,
    } = useStopDrafts();

    const {
        orders: fleetPlannerOrders,
        status: fleetPlannerOrdersStatus,
        refetch: refetchFleetPlannerOrders,
    } = useFleetPlannerOrders();

    const { orderDrafts, refetch: refetchOrderDrafts } = useOrderDrafts();

    const {
        movedStops,
        refetch: refetchMovedStops,
        status: movedStopsStatus,
        isFetching: isFetchingMovedStops,
    } = useMovedStops();

    const reloadData = useCallback<FleetPlannerReloadDataHandler>(
        async (...labels) => {
            const promises = [];

            if (labels.includes("tours")) {
                promises.push(refetchTours());
            }

            if (labels.includes("stop-drafts")) {
                promises.push(refetchStopDrafts());
            }

            if (labels.includes("fleet-planner-orders")) {
                promises.push(refetchFleetPlannerOrders());
            }

            if (labels.includes("order-drafts")) {
                promises.push(refetchOrderDrafts());
            }

            if (labels.includes("moved-stops")) {
                promises.push(refetchMovedStops());
            }

            if (labels.includes("drivers")) {
                promises.push(refetchDrivers());
            }

            if (labels.includes("tours")) {
                queryClient.removeQueries({
                    queryKey: ["active-fleet"],
                    type: "inactive",
                });
            }

            if (labels.includes("stop-drafts")) {
                queryClient.removeQueries({
                    queryKey: ["stop-drafts"],
                    type: "inactive",
                });
            }

            if (labels.includes("fleet-planner-orders")) {
                queryClient.removeQueries({
                    queryKey: ["fleet-planner-orders"],
                    type: "inactive",
                });
            }

            if (labels.includes("order-drafts")) {
                queryClient.removeQueries({
                    queryKey: ["order-drafts"],
                    type: "inactive",
                });
            }

            if (labels.includes("moved-stops")) {
                queryClient.removeQueries({
                    queryKey: ["moved-stops"],
                    type: "inactive",
                });
            }

            if (labels.includes("drivers")) {
                queryClient.removeQueries({
                    queryKey: ["drivers"],
                    type: "inactive",
                });
            }

            await Promise.all(promises);
        },
        [
            queryClient,
            refetchDrivers,
            refetchFleetPlannerOrders,
            refetchMovedStops,
            refetchOrderDrafts,
            refetchStopDrafts,
            refetchTours,
        ]
    );

    return {
        //Data
        tours,
        stopDrafts,
        fleetPlannerOrders,
        orderDrafts,
        drivers,
        movedStops,

        // Statuses
        driversStatus,
        toursStatus,
        stopDraftsStatus,
        fleetPlannerOrdersStatus,
        movedStopsStatus,

        // Fetching
        isFetchingTours,
        isFetchingStopDrafts,
        isFetchingMovedStops,
        isFetchingDrivers,

        reloadData,
    };
}

export default useFleetPlannerData;
