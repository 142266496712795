import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import ShowingAllMessage from "../../components/UI/ShowingAllMessage";
import Spinner from "../../components/UI/Spinner";
import NoOrders from "../../fleet-on-demand/NoOrders";
import OrdersTable from "../../fleet-on-demand/OrdersTable";
import useInfiniteOrders from "../../hooks/data/useInfiniteOrders";
import { useOrdersRoute } from "../../hooks/route/useOrdersRoute";
import { Order } from "../../shared/types/api";
import "./style.scss";

type Props = {};

function ActiveOrders(props: Props) {
    const { t } = useTranslation();
    const { setSelectedOrder } = useOrdersRoute();

    const { orders, fetchNextPage, hasNextPage, status } =
        useInfiniteOrders("active");

    let flatOrders: Order[] = [];
    if (status === "success") {
        flatOrders = orders.pages.flat();
    }

    return (
        <div className="active-orders">
            {status !== "success" && <Spinner padding="10px" />}
            {status === "success" && !flatOrders.length && (
                <NoOrders mode="active" />
            )}
            {status === "success" && !!flatOrders.length && (
                <InfiniteScroll
                    dataLength={flatOrders.length}
                    next={fetchNextPage}
                    hasMore={!!hasNextPage}
                    loader={<Spinner padding="10px" />}
                    endMessage={
                        <ShowingAllMessage
                            text={t("orderHistory.showingAllOrders")}
                        />
                    }
                    scrollThreshold={0.5}
                >
                    <OrdersTable
                        orders={flatOrders}
                        onRowClick={setSelectedOrder}
                        hideFulfilled
                    />
                </InfiniteScroll>
            )}
        </div>
    );
}

export default ActiveOrders;
