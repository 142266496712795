import { Draggable } from "@hello-pangea/dnd";
import useUserFeatures from "../../../hooks/functionality/useUserFeatures";
import { getOrderStatus } from "../../../shared/utility/order";
import { getStopDraftStatus } from "../../../shared/utility/stop-draft";
import StopDraftCard, { StopDraftCardProps } from "../StopDraftCard";
import "./style.scss";

type Props = {
    index: number;
    isDragDisabled?: boolean;
} & StopDraftCardProps;

function StopDraftDraggable(props: Props) {
    const features = useUserFeatures();

    const orderStatus = props.stopDraft.order
        ? getOrderStatus(props.stopDraft.order)
        : undefined;
    const { canStopBeMoved } = getStopDraftStatus({
        stopDraft: props.stopDraft,
        orderStatus,
    });

    return (
        <Draggable
            key={props.stopDraft.id}
            draggableId={props.stopDraft.id.toString()}
            index={props.index}
            isDragDisabled={
                props.isDragDisabled ||
                !canStopBeMoved ||
                !features?.manage_tours_fleet_planner
            }
        >
            {(provided) => <StopDraftCard provided={provided} {...props} />}
        </Draggable>
    );
}

export default StopDraftDraggable;
