import { Draggable } from "@hello-pangea/dnd";
import useUserFeatures from "../../../hooks/functionality/useUserFeatures";
import CombinedStopCard, { CombinedStopCardProps } from "../CombinedStopCard";
import "./style.scss";

type Props = {
    index: number;
    isDragDisabled?: boolean;
    draggableId?: string;
} & CombinedStopCardProps;

function CombinedStopCardDraggable(props: Props) {
    const features = useUserFeatures();

    return (
        <Draggable
            draggableId={props.draggableId || props.stops.pickup.id.toString()}
            index={props.index}
            isDragDisabled={
                props.isDragDisabled ||
                props.isLoading ||
                !features?.manage_tours_fleet_planner
            }
        >
            {(provided) => <CombinedStopCard {...props} provided={provided} />}
        </Draggable>
    );
}

export default CombinedStopCardDraggable;
