import { Draggable } from "@hello-pangea/dnd";
import useUserFeatures from "../../../hooks/functionality/useUserFeatures";
import StopCard, { StopCardProps } from "../StopCard";
import "./style.scss";

type Props = {
    id: string;
    isDragDisabled?: boolean;
} & StopCardProps;

function StopCardDraggable(props: Props) {
    const features = useUserFeatures();

    return (
        <Draggable
            draggableId={props.id}
            index={props.index}
            isDragDisabled={
                props.isDragDisabled || !features?.manage_tours_fleet_planner
            }
        >
            {(provided) => <StopCard provided={provided} {...props} />}
        </Draggable>
    );
}

export default StopCardDraggable;
