import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getFavourites } from "../../../api/favourite";
import { validateAddress } from "../../../api/order";
import useAddressAutoComplete from "../../../hooks/data/useAddressAutoComplete";
import { useClickOutside } from "../../../hooks/functionality/useClickOutside";
import FavouritePopup from "../../../popups/FavouritePopup";
import PinMapPopup from "../../../popups/PinMapPopup";
import { Favourite, FavouriteType } from "../../../shared/types/api";
import { ReduxState } from "../../../shared/types/redux";
import Spinner from "../../UI/Spinner";
import Tooltip from "../../UI/Tooltip";
import IconButton from "../../buttons/IconButton";
import Input from "../Input";
import AddressSearchRow from "./AddressSearchRow";
import "./style.scss";

type Props = {
    value: string;
    onChange: (value: string) => void;
    preselectedAddress?: string;
    placeholder?: string | null;
    label?: string | null;
    labelColor?: string;
    tooltip?: string | null;
    width?: string;
    type?: FavouriteType;
    noFavorites?: boolean;
    noMap?: boolean;
    isLoading?: boolean;
    isValid?: boolean;
    disabled?: boolean;
};

function AddressSearch(props: Props) {
    const { onChange } = props;

    const { t } = useTranslation();
    const { user } = useSelector((state: ReduxState) => state.auth);
    const addressSearchRef = useRef<HTMLLabelElement>(null);
    const pinMapRef = useRef<HTMLDivElement>(null);
    const pinMapButtonRef = useRef<HTMLButtonElement>(null);

    const [inputValue, setInputValue] = useState("");

    const [editFavourite, setEditFavourite] = useState<Favourite | undefined>();
    const [newFavouriteAddress, setNewFavouriteAddress] = useState("");

    const [focusedIndex, setFocusedIndex] = useState(-1);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [isMapPopupOpen, setIsMapPopupOpen] = useState(false);

    const [isValidatingAddress, setIsValidatingAddress] = useState(false);

    const [preselectedPinMapAddress, setPreselectedPinMapAddress] = useState<
        string | undefined
    >();

    const {
        data: favourites,
        refetch: refetchFavourites,
        isFetching: isFetchingFavourites,
    } = useQuery({
        queryKey: ["favourites"],
        queryFn: async () => {
            const res = await getFavourites();
            return res.data;
        },
    });

    const { predictions } = useAddressAutoComplete({
        searchText: inputValue,
        regionCode: user?.location_entity?.region?.region_code,
    });

    useEffect(() => {
        const scrollableEl = document.querySelector<HTMLElement>(
            "#predictions-wrapper"
        );
        if (!scrollableEl) return;

        const focusedEl = scrollableEl.querySelector<HTMLElement>(
            "[data-focused=true]"
        );
        if (!focusedEl) return;

        scrollableEl.scroll({
            top: focusedEl.offsetTop - scrollableEl.offsetTop - 10,
            behavior: "smooth",
        });
    }, [focusedIndex]);

    const filterFavourites = useCallback(
        (searchString: string, favourite: Favourite) => {
            return (
                (favourite.name
                    .toLowerCase()
                    .includes(searchString.toLowerCase()) ||
                    favourite.address
                        .toLowerCase()
                        .includes(searchString.toLowerCase())) &&
                (!props.type ? true : favourite.type === props.type)
            );
        },
        [props.type]
    );

    const filteredFavourites = useMemo(
        () => favourites?.filter((f) => filterFavourites(inputValue, f)) || [],
        [favourites, filterFavourites, inputValue]
    );

    const searchInputHandler = useCallback(
        async (value: string) => {
            setInputValue(value);
            if (!value) {
                onChange("");
            }

            if (!props.disabled) {
                setIsModalOpen(true);
            }
        },
        [onChange, props.disabled]
    );

    const validateAddressHandler = useCallback(
        async ({
            address,
            openMapOnInvalid,
        }: {
            address: string;
            openMapOnInvalid: boolean;
        }) => {
            setIsValidatingAddress(true);
            try {
                const res = await validateAddress(address);

                if (res.data.mt_response_code !== 200) {
                    throw Error("outside-service-area");
                }
                setIsInvalid(false);
                setIsValidatingAddress(false);
                return res.data.formatted_address;
            } catch (error: any) {
                if (error.message === "outside-service-area") {
                    toast.error(t("errorMessage.addressOutsideServiceArea"));
                } else {
                    toast.error(t("errorMessage.invalidAddress"));
                }
                setIsInvalid(true);
                if (openMapOnInvalid) {
                    setIsMapPopupOpen(true);
                    setPreselectedPinMapAddress(address);
                }
                setIsValidatingAddress(false);
            }
        },
        [t]
    );

    const selectAddressHandler = useCallback(
        async ({
            address,
            openMapOnInvalid,
        }: {
            address: string;
            openMapOnInvalid: boolean;
        }) => {
            const formattedAddress = await validateAddressHandler({
                address,
                openMapOnInvalid,
            });

            if (!formattedAddress) {
                setInputValue(address);
                onChange("");
                return;
            }

            onChange(formattedAddress);
            setInputValue(formattedAddress);
            setIsModalOpen(false);
        },
        [onChange, validateAddressHandler]
    );

    useClickOutside([addressSearchRef, pinMapRef, pinMapButtonRef], () => {
        if (!isModalOpen) return;
        setIsModalOpen(false);
        setInputValue(props.value);
    });

    useEffect(() => {
        if (!props.preselectedAddress) return;
        selectAddressHandler({
            address: props.preselectedAddress,
            openMapOnInvalid: false,
        });
    }, [props.preselectedAddress, selectAddressHandler]);

    useEffect(() => {
        if (props.value === "") {
            setInputValue("");
        }
    }, [props.value]);

    useHotkeys(
        "ArrowDown, ArrowUp, Enter",
        (e) => {
            e.preventDefault();
            const totalPredictions = predictions?.length || 0;
            const totalFavourites = filteredFavourites.length;
            const totalItems = totalPredictions + totalFavourites;

            switch (e.key) {
                case "ArrowUp":
                    if (focusedIndex > 0) {
                        setFocusedIndex((state) => state - 1);
                    } else {
                        setFocusedIndex(totalItems - 1);
                    }
                    break;

                case "ArrowDown":
                    if (focusedIndex < totalItems) {
                        setFocusedIndex((state) => state + 1);
                    } else {
                        setFocusedIndex(0);
                    }
                    break;

                case "Enter":
                    if (focusedIndex < 0 || focusedIndex >= totalItems) return;

                    if (focusedIndex < totalFavourites) {
                        selectAddressHandler({
                            address:
                                filteredFavourites[focusedIndex]?.address || "",
                            openMapOnInvalid: true,
                        });
                    } else if (focusedIndex < totalItems) {
                        selectAddressHandler({
                            address:
                                predictions?.[focusedIndex - totalFavourites]
                                    ?.description || "",
                            openMapOnInvalid: true,
                        });
                    }
                    break;
            }
        },
        {
            enabled: isModalOpen,
            enableOnFormTags: ["input"],
        }
    );

    return (
        <>
            <label
                className="address-search-wrapper"
                ref={addressSearchRef}
                style={{ width: props.width }}
                onFocus={
                    !props.disabled
                        ? () => searchInputHandler(inputValue)
                        : undefined
                }
                onClick={
                    !props.disabled
                        ? () => searchInputHandler(inputValue)
                        : undefined
                }
            >
                {(props.label || props.tooltip) && (
                    <div
                        className="address-search-label"
                        style={{
                            color: props.labelColor,
                        }}
                    >
                        {props.tooltip && <Tooltip content={props.tooltip} />}
                        {props.label && (
                            <span className="text-xs">{props.label}</span>
                        )}
                    </div>
                )}
                <Input
                    type="text"
                    leadingIcon={faSearch}
                    value={inputValue}
                    onChange={searchInputHandler}
                    placeholder={props.placeholder}
                    isLoading={isValidatingAddress || props.isLoading}
                    invalid={isInvalid}
                    autoComplete="new-password"
                    completed={props.isValid}
                    disabled={props.disabled}
                />
                {!props.noMap && !isValidatingAddress && (
                    <IconButton
                        ref={pinMapButtonRef}
                        icon="map"
                        style={{
                            position: "absolute",
                            right: "8px",
                            bottom: "5px",
                            backgroundColor: "transparent",
                            color: "var(--text-color)",
                        }}
                        onClick={() => {
                            setPreselectedPinMapAddress(inputValue);
                            setIsMapPopupOpen(true);
                        }}
                        disabled={props.disabled}
                        short
                    />
                )}
                {isModalOpen && (
                    <div className="address-search-modal">
                        {!props.noFavorites && (
                            <>
                                <h3 className="text-xs">
                                    {t("general.favourites")}
                                </h3>
                                <section>
                                    {isFetchingFavourites ? (
                                        <Spinner padding="10px" />
                                    ) : !!filteredFavourites.length ? (
                                        filteredFavourites.map((f, i) => (
                                            <AddressSearchRow
                                                key={f.id}
                                                label={f.name}
                                                onClick={() =>
                                                    selectAddressHandler({
                                                        address: f.address,
                                                        openMapOnInvalid: true,
                                                    })
                                                }
                                                onFavouriteClick={() =>
                                                    setEditFavourite(f)
                                                }
                                                isFavourite
                                                isFocused={i === focusedIndex}
                                            />
                                        ))
                                    ) : (
                                        <p className="text-xs no-results">
                                            {t("general.noFavouritesFound")}
                                        </p>
                                    )}
                                </section>
                            </>
                        )}
                        <h3 className="text-xs">{t("general.predictions")}</h3>
                        <section id="predictions-wrapper">
                            {!!predictions?.length ? (
                                predictions.map((p, i) => (
                                    <AddressSearchRow
                                        key={p.place_id}
                                        label={p.description}
                                        onClick={() =>
                                            selectAddressHandler({
                                                address: p.description,
                                                openMapOnInvalid: true,
                                            })
                                        }
                                        onFavouriteClick={() =>
                                            setNewFavouriteAddress(
                                                p.description
                                            )
                                        }
                                        isFocused={
                                            i + filteredFavourites.length ===
                                            focusedIndex
                                        }
                                        noFavourite={props.noFavorites}
                                    />
                                ))
                            ) : (
                                <p className="text-xs no-results">
                                    {t("general.noPredictionsFound")}
                                </p>
                            )}
                        </section>
                    </div>
                )}
            </label>
            {!props.noFavorites && (
                <FavouritePopup
                    key={props.type}
                    showPopup={!!newFavouriteAddress || !!editFavourite}
                    onClose={() => {
                        setNewFavouriteAddress("");
                        setEditFavourite(undefined);
                    }}
                    address={newFavouriteAddress}
                    editFavourite={editFavourite}
                    onSave={refetchFavourites}
                    favouriteType={props.type}
                />
            )}
            {!props.noMap && (
                <PinMapPopup
                    showPopup={isMapPopupOpen}
                    onClose={() => setIsMapPopupOpen(false)}
                    onSubmit={(address) => {
                        selectAddressHandler({
                            address,
                            openMapOnInvalid: false,
                        });
                        setIsMapPopupOpen(false);
                    }}
                    preSelectedAddress={preselectedPinMapAddress}
                    popupRef={pinMapRef}
                />
            )}
        </>
    );
}

export default AddressSearch;
