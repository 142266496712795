import {
    DragDropContext,
    DropResult,
    Droppable,
    ResponderProvided,
} from "@hello-pangea/dnd";
import { useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { groupStops } from "../../api/tours";
import Spinner from "../../components/UI/Spinner";
import Button from "../../components/buttons/Button";
import StopDraftDraggable from "../../fleet-planner/cards/StopDraftDraggable";
import Popup from "../../hoc/Popup";
import TourMap from "../../maps/TourMap";
import { StopDraft, StopDraftsTour } from "../../shared/types/api";
import { formatMinutes } from "../../shared/utility/date";
import { generateUUID } from "../../shared/utility/misc";
import {
    cardHoverHandler,
    checkForGroupsAround,
    getRunningWeight,
    getStopListDurationInMinutes,
} from "../../shared/utility/stop-draft";
import { GOOGLE_MAP_IDS } from "../../shared/values/google-map-ids";
import "./style.scss";

type Props = {
    showPopup: boolean;
    onClose: () => void;
    droppableId: string;
    onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
    onStopsGrouped: () => void;
    onSortClick?: () => void;
    isColumnLoading: boolean;
    tour: StopDraftsTour;
};

function GroupTourStopsPopup(props: Props) {
    const { t } = useTranslation();

    const { onStopsGrouped } = props;

    const [checkedIds, setCheckedIds] = useState<number[]>([]);
    const [groupingType, setGroupingType] = useState<1 | 2 | null>(null);
    const [degroupingIds, setDegroupingIds] = useState<number[]>([]);

    const areStopIdsConsecutive = useCallback(
        (stopIds: number[], tourOrder: number[]) => {
            const sortedIds = stopIds.toSorted(
                (a, b) => tourOrder.indexOf(a) - tourOrder.indexOf(b)
            );

            // Finding the index of the first element of stopIds in tourOrder
            const startIndex = tourOrder.indexOf(sortedIds[0]);

            // Check if the first element is not found or there isn't enough space left in mainArray
            if (
                startIndex === -1 ||
                startIndex + sortedIds.length > tourOrder.length
            ) {
                return false;
            }

            // Check the subsequent elements
            for (let i = 1; i < sortedIds.length; i++) {
                if (tourOrder[startIndex + i] !== sortedIds[i]) {
                    return false;
                }
            }

            return true;
        },
        []
    );

    const { mutate: groupStopsHandler, isPending: isGrouping } = useMutation({
        mutationFn: async (stopIds: number[]) => {
            if (stopIds.length < 2) {
                throw new Error("errorMessage.groupingAtLeastTwo");
            }

            if (
                !areStopIdsConsecutive(
                    stopIds,
                    props.tour.stops.map((s) => s.id)
                )
            ) {
                throw new Error(
                    "errorMessage.groupingStopsNeedToBeConsecutive"
                );
            }

            const groupId = generateUUID();

            await groupStops(
                stopIds.map((id) => ({
                    stop_draft_id: id,
                    group: groupId,
                }))
            );
        },
        onSuccess: () => {
            onStopsGrouped();
            setCheckedIds([]);
            setGroupingType(null);
            toast.success(t("successMessage.groupCreated"));
        },
        onError: (error: Error) => {
            if (!isAxiosError(error) && typeof error.message === "string") {
                toast.error(t(error.message));
                return;
            }
            toast.error(t("errorMessage.groupCreationFailed"));
            if (isAxiosError(error)) {
                toast.error(error.response?.data.message);
            }
        },
    });

    const degroupStopsHandler = useCallback(
        async (stopsToDegroup: number[]) => {
            setDegroupingIds((state) => [...state, ...stopsToDegroup]);

            try {
                await groupStops(
                    stopsToDegroup.map((id) => ({
                        stop_draft_id: id,
                        group: null,
                    }))
                );
                onStopsGrouped();
                toast.success(t("successMessage.groupDegrouped"));
            } catch (error) {
                toast.error(t("errorMessage.groupDegroupFailed"));
            } finally {
                setDegroupingIds((state) =>
                    state.filter((id) => !stopsToDegroup.includes(id))
                );
            }
        },
        [onStopsGrouped, t]
    );

    const checkClickHandler = useCallback((stop: StopDraft) => {
        setCheckedIds((state) => {
            let newState = [...state];

            if (newState.includes(stop.id)) {
                newState = newState.filter((id) => id !== stop.id);
            } else {
                newState = [...newState, stop.id];
            }
            if (!newState.length) {
                setGroupingType(null);
            } else {
                setGroupingType(stop.stop_type_id);
            }

            return newState;
        });
    }, []);

    const tourDuration = useMemo(() => {
        return formatMinutes(getStopListDurationInMinutes(props.tour.stops));
    }, [props.tour]);

    const { peakWeight, runningWeights } = useMemo(() => {
        return getRunningWeight(props.tour.stops);
    }, [props.tour.stops]);

    return (
        <Popup
            showPopup={props.showPopup}
            onClose={props.onClose}
            dontCloseOnOutsideClick
            overlayComponent={
                <TourMap
                    mapId={GOOGLE_MAP_IDS.GroupTourMap}
                    tour={props.tour}
                />
            }
        >
            <div className="group-tour-stops-popup-controls">
                <Button
                    variant="secondary"
                    label={t("createTour.sortByType")}
                    onClick={props.onSortClick}
                    style={{ width: "250px" }}
                />
            </div>
            <div className="group-tour-stops-popup-info">
                <span>
                    {t("fleetPlanner.estimatedTime")} {tourDuration}
                </span>
                <span>
                    {t("fleetPlanner.maxRunningWeight")} {peakWeight}
                    kg
                </span>
            </div>
            <DragDropContext onDragEnd={props.onDragEnd}>
                <Droppable droppableId={props.droppableId}>
                    {(provided) => (
                        <div
                            className="group-tour-stops-popup"
                            ref={provided.innerRef}
                        >
                            {props.tour.stops.map((stop, index) => {
                                const hasGroupsAround = checkForGroupsAround({
                                    stop,
                                    stopAbove: props.tour.stops[index - 1],
                                    stopBelow: props.tour.stops[index + 1],
                                });

                                const canCheck = groupingType
                                    ? stop.stop_type_id === groupingType
                                    : true;

                                const canDegroup =
                                    (!hasGroupsAround.above &&
                                        hasGroupsAround.below) ||
                                    (stop.motion_tools_stop_group &&
                                        !hasGroupsAround.above &&
                                        !hasGroupsAround.below);

                                return (
                                    <StopDraftDraggable
                                        key={stop.id}
                                        stopDraft={stop}
                                        index={index}
                                        showPosition
                                        runningWeight={runningWeights[index]}
                                        onHover={(gId) =>
                                            cardHoverHandler({
                                                groupId: gId,
                                                columnSelector:
                                                    ".group-tour-stops-popup",
                                            })
                                        }
                                        onCheck={
                                            canCheck
                                                ? () => checkClickHandler(stop)
                                                : undefined
                                        }
                                        checked={checkedIds.includes(stop.id)}
                                        onDegroup={
                                            canDegroup
                                                ? () =>
                                                      degroupStopsHandler(
                                                          props.tour.stops
                                                              .filter(
                                                                  (s) =>
                                                                      s.motion_tools_stop_group ===
                                                                      stop.motion_tools_stop_group
                                                              )
                                                              .map((s) => s.id)
                                                      )
                                                : undefined
                                        }
                                        isDegrouping={degroupingIds.includes(
                                            stop.id
                                        )}
                                        hasGroupsAround={hasGroupsAround}
                                    />
                                );
                            })}
                            {provided.placeholder}
                            {props.isColumnLoading && (
                                <div className="loading-wrapper">
                                    <Spinner />
                                </div>
                            )}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div className="group-tour-stops-popup-submit-wrapper">
                <Button
                    variant="primary"
                    label={t("popup.groupTourStops.submit")}
                    onClick={() => groupStopsHandler(checkedIds)}
                    isLoading={isGrouping}
                    style={{ width: "100%" }}
                />
            </div>
        </Popup>
    );
}

export default GroupTourStopsPopup;
