import { useTranslation } from "react-i18next";
import { DropdownOption } from "../../shared/types/internal";

function useManualOrderTransportOptions() {
    const { t } = useTranslation();

    const options: DropdownOption[] = [
        //{ label: t("fleetManualOrder.alrik"), value: "alrik" },
        { label: t("fleetManualOrder.ordinary"), value: "ordinary" },
        { label: t("fleetManualOrder.schenker"), value: "schenker" },
    ];

    return options;
}

export default useManualOrderTransportOptions;
