import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import "./style.scss";

type BadgeColor = {
    backgroundColor: string;
    color: string;
    iconColor: string;
};

type BadgeVariants = {
    neutral: BadgeColor;
    info: BadgeColor;
    success: BadgeColor;
    warning: BadgeColor;
    error: BadgeColor;
};

type Props = {
    title: string;
    variant: keyof BadgeVariants;
    icon?: IconDefinition;
    htmlTitle?: string;
};

const variants: BadgeVariants = {
    neutral: {
        backgroundColor: "var(--color-neutral-100)",
        color: "var(--color-neutral-700)",
        iconColor: "var(--color-neutral-400)",
    },
    info: {
        backgroundColor: "var(--color-blue-100)",
        color: "var(--color-blue-700)",
        iconColor: "var(--color-blue-500)",
    },
    success: {
        backgroundColor: "var(--color-primary-100)",
        color: "var(--color-primary-800)",
        iconColor: "var(--color-primary-500)",
    },
    warning: {
        backgroundColor: "var(--color-orange-100)",
        color: "var(--color-orange-700)",
        iconColor: "var(--color-orange-500)",
    },
    error: {
        backgroundColor: "var(--color-red-100)",
        color: "var(--color-red-700)",
        iconColor: "var(--color-red-500)",
    },
};

function Badge(props: Props) {
    const { backgroundColor, color, iconColor } = useMemo(
        () => variants[props.variant],
        [props.variant]
    );
    return (
        <div
            className="badge text-xs"
            style={{ backgroundColor, color }}
            title={props.htmlTitle || props.title}
        >
            {props.icon && (
                <FontAwesomeIcon icon={props.icon} color={iconColor} />
            )}
            <span>{props.title}</span>
        </div>
    );
}

export default Badge;
